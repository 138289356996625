import useApi from './useApi';
import { ExternalUser } from 'types/ExternalUserType';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';

export default () => {
    const { deleteApiData } = useApi();

    const deleteExternalUser = async (externalUser: ExternalUser, companyId?: number) => {
        deleteApiData({
            endpoint: ApiEndpoints.deleteExternalUser,
            queryParam: ApiQueryParams.deleteExternalUser(externalUser.externalUserId, companyId),
            data: externalUser,
        });
    };

    return { deleteExternalUser };
};
