export type FilterState = {
    cargoSearch: string;
    portSearch: string;
    vesselSearch: string;
    onlyActiveVoyages: boolean;
};

export type FilterAction = {
    field: string;
    value: string | boolean | Date | null;
};

export const filterReducer = (state: any, { field, value }: FilterAction) => {
    return { ...state, [field]: value, field };
};
