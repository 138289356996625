import React, { useReducer, useEffect, useState } from 'react';
import SearchInput from 'components/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { FilterWrapper, ViewModeWrapper, ViewModeButton, FilterHelperText, FilterInputsWrapper, FilterSelectionsWrapper } from './EtaFilters.style';
import ToggleInput from 'components/toggle-input/ToggleInput';
import DateInput from 'components/date-input/DateInput';
import moment from 'moment';
import { filterReducer } from 'reducers/FilterReducer';
import { useEtaStore } from 'context/EtaContext';
import { useAppStateStore } from 'context/AppStateContext';
import useGetCustomerVoyages from 'api/useGetCustomerVoyages';
import { useCompaniesStore } from 'context/CompaniesContext';

type Props = {
    viewMode: string;
    setViewMode: (viewMode: string) => void;
};

const initialFilterState = {
    cargoSearch: '',
    portSearch: '',
    vesselSearch: '',
    onlyActiveVoyages: true,
};

const EtaFiltersComponent: React.FC<Props> = ({ viewMode, setViewMode }) => {
    const [filterState, dispatch] = useReducer(filterReducer, initialFilterState);
    const [hasFilteredDates, setHasFilteredDates] = useState(false);
    const { getCustomerVoyages } = useGetCustomerVoyages();
    const { cargoSearch, portSearch, vesselSearch, onlyActiveVoyages } = filterState;
    const {
        customerVoyages,
        setFilteredCustomerVoyages,
        filterStartDate,
        setFilterStartDate,
        filterEndDate,
        setFilterEndDate,
        initialValues,
        setFilterState,
    } = useEtaStore();
    const { selectedCustomerVoyage, setSelectedPortCall, setSelectedCargoOperation, setSelectedCustomerVoyage, selectedVesselFilter } =
        useAppStateStore();
    const { selectedCompany } = useCompaniesStore();

    const onFilterChange = (e: any) => dispatch({ field: e.target.name, value: e.target.type === 'checkbox' ? e.target.checked : e.target.value });
    const clearFilter = (field: string) => dispatch({ field: field, value: '' });
    const onDateChange = (date: Date, fieldName: string) => {
        if (!hasFilteredDates) setHasFilteredDates(true);
        if (fieldName === 'filterStartDate') {
            setFilterStartDate(date);
        }
        if (fieldName === 'filterEndDate') {
            setFilterEndDate(date);
        }
    };
    const resetDatesFilter = () => {
        if (hasFilteredDates) setHasFilteredDates(false);
        setFilterStartDate(initialValues.filterStartDate);
        setFilterEndDate(initialValues.filterEndDate);
    };

    const isDatesBetweenFilterRange = (startDate: Date, endDate: Date) => {
        const startDateM = moment(startDate);
        const endDateM = moment(endDate);
        const rangeStartM = moment(filterStartDate);
        const rangeEndM = moment(filterEndDate);

        return (
            startDateM.isBetween(rangeStartM, rangeEndM) ||
            endDateM.isBetween(rangeStartM, rangeEndM) ||
            rangeStartM.isBetween(startDateM, endDateM) ||
            rangeEndM.isBetween(startDateM, endDateM)
        );
    };

    const resetSelection = () => {
        setSelectedCustomerVoyage(null);
        setSelectedPortCall(null);
        setSelectedCargoOperation(null);
    };

    useEffect(() => {
        getCustomerVoyages();
    }, [filterStartDate, filterEndDate, selectedCompany]);

    useEffect(() => {
        let filteredCustomerVoyages = customerVoyages ? _.cloneDeep(customerVoyages) : [];
        if (filterState) {
            if (filterState.cargoSearch) {
                filteredCustomerVoyages.forEach(
                    (customerVoyage) =>
                        (customerVoyage.portCalls = customerVoyage.portCalls.filter((portCall) => {
                            portCall.cargoOperations = portCall.cargoOperations.filter(
                                (co) => co.tradeName.toLowerCase().indexOf(cargoSearch.toLowerCase()) !== -1,
                            );

                            return portCall.cargoOperations.length > 0;
                        })),
                );
            }
            if (filterState.portSearch) {
                filteredCustomerVoyages.forEach(
                    (customerVoyage) =>
                        (customerVoyage.portCalls = customerVoyage.portCalls.filter(
                            (portCall) => portCall.portName.toLowerCase().indexOf(portSearch.toLowerCase()) !== -1,
                        )),
                );
            }
            if (filterState.vesselSearch) {
                filteredCustomerVoyages = filteredCustomerVoyages.filter(
                    (customerVoyage) => customerVoyage.vessel.vesselName.toLowerCase().indexOf(vesselSearch.toLowerCase()) !== -1,
                );

                if (
                    selectedCustomerVoyage &&
                    selectedCustomerVoyage.vessel.vesselName.toLowerCase().indexOf(filterState.vesselSearch.toLowerCase()) === -1
                ) {
                    resetSelection();
                }
            }
            if (filterState.field === 'onlyActiveVoyages' || filterState.onlyActiveVoyages) {
                if (filterState.onlyActiveVoyages) {
                    filteredCustomerVoyages = filteredCustomerVoyages.filter((customerVoyage) => customerVoyage.voyage.voyageStatus === 'Commenced');
                }
            }
        }

        filteredCustomerVoyages = filteredCustomerVoyages.filter((customerVoyage) => {
            const voyage = customerVoyage.voyage;

            customerVoyage.portCalls = customerVoyage.portCalls.filter((portCall) => {
                if (portCall.operatorEta) {
                    const operatorEta = portCall.operatorEta;

                    return (
                        isDatesBetweenFilterRange(operatorEta.etaFromDateUtc, operatorEta.etaToDateUtc) ||
                        isDatesBetweenFilterRange(operatorEta.etdFromDateUtc, operatorEta.etdToDateUtc)
                    );
                } else {
                    return isDatesBetweenFilterRange(portCall.portArrivalDateUTC, portCall.portDepartureDateUTC);
                }
            });
            return isDatesBetweenFilterRange(voyage.voyageStartingDateUtc, voyage.voyageEndingDateUtc);
        });

        filteredCustomerVoyages = filteredCustomerVoyages.filter((customerVoyage) => {
            return customerVoyage.portCalls.length > 0;
        });

        if (selectedCustomerVoyage) {
            const hasVessel = filteredCustomerVoyages.some(
                (customerVoyage) =>
                    customerVoyage.vessel.imoNumber === selectedCustomerVoyage.vessel.imoNumber &&
                    customerVoyage.voyage.idVoyage === selectedCustomerVoyage.voyage.idVoyage,
            );
            if (!hasVessel) {
                resetSelection();
            }
        }

        /*setFilteredEtaItems(filteredEtaItems.filter(etaItem => {
            vessel.voyages = vessel.voyages.filter(voyage => voyage.portCalls.length > 0)
            return vessel.voyages.length > 0 && vessel.voyages.length > 0;
        }));*/
        setFilteredCustomerVoyages([...filteredCustomerVoyages]);

        setFilterState(filterState);
    }, [customerVoyages, filterState, filterStartDate, filterEndDate]);

    useEffect(() => {
        if (!selectedVesselFilter) return;

        const ev = {
            target: {
                name: 'vesselSearch',
                value: selectedVesselFilter.vesselName,
            },
        };
        onFilterChange(ev);
    }, [selectedVesselFilter]);

    return (
        <>
            <FilterHelperText>
                <h1>ETA/Cargo</h1>
            </FilterHelperText>
            <FilterWrapper>
                <FilterInputsWrapper>
                    <SearchInput name={'portSearch'} value={portSearch} placeholder={'Search port'} onChange={onFilterChange} clear={clearFilter} />
                    <SearchInput
                        name={'cargoSearch'}
                        value={cargoSearch}
                        placeholder={'Search cargo'}
                        onChange={onFilterChange}
                        clear={clearFilter}
                    />
                    <SearchInput
                        name={'vesselSearch'}
                        value={vesselSearch}
                        placeholder={'Search vessel'}
                        onChange={onFilterChange}
                        clear={clearFilter}
                    />
                    <ViewModeWrapper>
                        <ViewModeButton
                            onClick={() => setViewMode('ship')}
                            className={`clickable-element ${viewMode === 'ship' ? 'active-view-mode' : ''}`}
                        >
                            <FontAwesomeIcon icon="ship" />
                            Ship view
                        </ViewModeButton>
                        <ViewModeButton
                            onClick={() => setViewMode('list')}
                            className={`clickable-element ${viewMode === 'list' ? 'active-view-mode' : ''}`}
                        >
                            <FontAwesomeIcon icon="list" />
                            Cargo view
                        </ViewModeButton>
                    </ViewModeWrapper>
                </FilterInputsWrapper>
                {true && (
                    <FilterSelectionsWrapper>
                        <div className="date-range-wrapper">
                            <div className="date-range-selection">
                                Between
                                <DateInput
                                    name={'filterStartDate'}
                                    date={filterStartDate}
                                    onChange={(date) => onDateChange(date, 'filterStartDate')}
                                ></DateInput>
                                and
                                <DateInput
                                    name={'filterEndDate'}
                                    date={filterEndDate}
                                    onChange={(date) => onDateChange(date, 'filterEndDate')}
                                ></DateInput>
                                {hasFilteredDates && <button onClick={resetDatesFilter}>Reset dates</button>}
                            </div>
                        </div>
                        <div className="active-voyages-selection">
                            <ToggleInput
                                id="activeVoyagesCheckbox"
                                label="Only show active voyages"
                                onChange={onFilterChange}
                                $isSelected={onlyActiveVoyages}
                                name="onlyActiveVoyages"
                            ></ToggleInput>
                        </div>
                    </FilterSelectionsWrapper>
                )}
            </FilterWrapper>
        </>
    );
};

export default EtaFiltersComponent;
