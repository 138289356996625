import styled from 'styled-components';

export const ModalWrapper = styled.div`
    position: absolute;
    top: 81px;
    right: 0;
    z-index: 999;
    background: #fff;
    padding: 1rem;
    width: 200px;
    box-shadow: 0 2px 7px -2px #ccc;
    button {
        background: #00447e;
        border: none;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        color: #fff;
    }
`;
