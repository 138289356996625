import styled from 'styled-components';

export const LoginWrapper = styled.div`
    position: absolute;
    z-index: 99;
    top: 3rem;
    background: #ffffffe0;
    left: 3rem;
    padding: 2rem 2rem 4rem;
    border-radius: 4px;
    width: 20rem;

    h2 {
        font-size: 2.5rem;
        margin-top: 0.3rem;
        margin-bottom: 0.5rem;
        text-align: center;
        font-weight: 200;
    }

    p {
        line-height: 1.8rem;
        font-size: 18px;
        margin: -5px 0;
    }
`;
