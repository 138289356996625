import React, { useContext, useState } from 'react';
import { VesselPosition, CustomerVoyage } from 'types/ModelTypes';
import { ContextProps, PortCallCargoOperationMap } from 'types/Types';
import moment from 'moment';

type InitialValues = {
    filterStartDate: Date;
    filterEndDate: Date;
};

type EtaContextType = {
    customerVoyages: CustomerVoyage[];
    filteredCustomerVoyages: CustomerVoyage[];
    cargoPositions: VesselPosition[];
    portCallVesselPositions: VesselPosition[];
    portCallCargoOperationMaps: PortCallCargoOperationMap[];
    filterStartDate: Date;
    filterEndDate: Date;
    initialValues: InitialValues;
    filterState: any;
    setCustomerVoyages: (customerVoyages: CustomerVoyage[]) => void;
    setFilteredCustomerVoyages: (customerVoyages: CustomerVoyage[]) => void;
    setCargoPositions: (cargoPositions: VesselPosition[]) => void;
    setPortCallVesselPositions: (portCallVesselPositions: VesselPosition[]) => void;
    setPortCallCargoOperationMaps: (portCallCargoOperationMaps: PortCallCargoOperationMap[]) => void;
    setFilterStartDate: (date: Date) => void;
    setFilterEndDate: (date: Date) => void;
    setFilterState: (state: any) => void;
};

const initalValues: InitialValues = {
    filterStartDate: moment.utc().add(-14, 'days').toDate(),
    filterEndDate: moment.utc().add(90, 'days').toDate(),
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const EtaContext = React.createContext<EtaContextType>(undefined!);

export const EtaProvider = ({ children }: ContextProps) => {
    const [customerVoyages, setCustomerVoyages] = useState<CustomerVoyage[]>([]);
    const [filteredCustomerVoyages, setFilteredCustomerVoyages] = useState<CustomerVoyage[]>([]);
    const [cargoPositions, setCargoPositions] = useState<VesselPosition[]>([]);
    const [portCallVesselPositions, setPortCallVesselPositions] = useState<VesselPosition[]>([]);
    const [portCallCargoOperationMaps, setPortCallCargoOperationMaps] = useState<PortCallCargoOperationMap[]>([]);
    const [filterStartDate, setFilterStartDate] = useState<Date>(initalValues.filterStartDate);
    const [filterEndDate, setFilterEndDate] = useState<Date>(initalValues.filterEndDate);
    const [filterState, setFilterState] = useState<any>();
    const [initialValues] = useState<InitialValues>(initalValues);

    return (
        <EtaContext.Provider
            value={{
                customerVoyages,
                setCustomerVoyages,
                filteredCustomerVoyages,
                setFilteredCustomerVoyages,
                cargoPositions,
                setCargoPositions,
                portCallVesselPositions,
                setPortCallVesselPositions,
                portCallCargoOperationMaps,
                setPortCallCargoOperationMaps,
                filterStartDate,
                setFilterStartDate,
                filterEndDate,
                setFilterEndDate,
                initialValues,
                filterState,
                setFilterState,
            }}
        >
            {children}
        </EtaContext.Provider>
    );
};

export const useEtaStore = () => useContext(EtaContext);
