// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Navigate } from 'react-router-dom';
import { AuthRole } from 'types/Types';
import { useAuthStateStore } from 'auth/AuthStateProvider';

export const PrivateRoute = ({ children, authRole }: any) => {
    const { userAccount, state } = useAuthStateStore();
    const hasRole = () => {
        if (!userAccount || userAccount.role === 'odfjell-admin') {
            return true;
        }
        const role = authRole as AuthRole;

        if (role === 'external-admin') {
            return userAccount.role === 'external-admin';
        }
        if (role === 'customer') {
            return userAccount.role === 'external-admin' || userAccount.role === 'customer';
        }

        return false;
    };

    if (state.isAuthorizing || (userAccount && (!authRole || (authRole && hasRole())))) {
        return children;
    }

    return <Navigate to={'/login'} />;
};
