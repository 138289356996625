import moment from 'moment';

export const GetRandomKey = (min?: number, max?: number) => {
    min = min === undefined ? 9999999 : min;
    max = max === undefined ? 999999999 : max;

    return Math.random() * (max - min) + min;
};

export const GetIsoDate = (date: Date) => moment(date).format('YYYY-MM-DD');
