import useDeleteExternalUser from 'api/useDeleteExternalUser';
import useGetExternalUsers from 'api/useGetExternalUsers';
import usePutExternalUser from 'api/usePutExternalUser';
import DateInput from 'components/date-input/DateInput';
import PaginatorComponent from 'components/paginator/Paginator';
import SearchInput from 'components/SearchInput';
import SelectInput, { SelectInputOption } from 'components/select-input/SelectInput';
import { Table } from 'components/Syles';
import { useCompaniesStore } from 'context/CompaniesContext';
import { useUserStore } from 'context/UserContext';
import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { filterReducer } from 'reducers/FilterReducer';
import { ExternalUser } from 'types/ExternalUserType';
import { prettifyDate } from 'utils/renderTextHelper';
import { FilterElement, FilterWrapper, TableWrapper } from '../AdminPage.style';

const initialFilterState = {
    filteredRole: 'All',
    filteredAssignedCompany: 'All',
    emailSearch: '',
    requestedCustomerSearch: '',
};

const UsersTab: React.FC = () => {
    const { getExternalUsers } = useGetExternalUsers();
    const { updateExternalUser } = usePutExternalUser();
    const { deleteExternalUser } = useDeleteExternalUser();
    const { externalUsers, setExternalUsers } = useUserStore();
    const { companies } = useCompaniesStore();
    const [modifiedUsers, setModifiedUsers] = useState<ExternalUser[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<ExternalUser[]>([]);
    const [paginatedUsers, setPaginatedUsers] = useState<ExternalUser[]>([]);
    const [roles] = useState<SelectInputOption[]>([
        { value: 'customer', label: 'customer' },
        { value: 'external-admin', label: 'customer-admin' },
        { value: 'odfjell-admin', label: 'odfjell-admin' },
    ]);
    const [filterRoles] = useState<SelectInputOption[]>([{ value: 'All', label: 'All' }, ...roles]);
    const [filterAssignedCompanies, setFilterAssignedCompanies] = useState<string[]>([]);
    const [filterState, dispatch] = useReducer(filterReducer, initialFilterState);
    const { filteredRole, emailSearch, filteredAssignedCompany, requestedCustomerSearch } = filterState;

    const onFilterChange = (e: any) => dispatch({ field: e.target.name, value: e.target.type === 'checkbox' ? e.target.checked : e.target.value });
    const clearFilter = (field: string) => dispatch({ field: field, value: '' });

    useEffect(() => {
        if (companies) {
            const filterOptions = companies.map((company) => company.companyDisplayName);
            setFilterAssignedCompanies(['All', 'Unassigned company', ...filterOptions]);
        }
    }, [companies]);

    useEffect(() => {
        getExternalUsers();
    }, []);

    useEffect(() => {
        if (!externalUsers || !filterState || externalUsers.length === 0) return;
        let filteredExternalUsers = _.cloneDeep(externalUsers);

        if (filterState.filteredAssignedCompany !== 'All') {
            filteredExternalUsers = filteredExternalUsers.filter((externalUser) => {
                const filteredAssignedCompany = filterState.filteredAssignedCompany;
                if (filteredAssignedCompany === 'Unassigned company') {
                    return !externalUser.assignedCompany;
                }
                return externalUser.assignedCompany && externalUser.assignedCompany.companyDisplayName === filteredAssignedCompany;
            });
        }
        if (filterState.filteredRole !== 'All') {
            filteredExternalUsers = filteredExternalUsers.filter((externalUser) => {
                return externalUser.assignedCompany && externalUser.authRole === filterState.filteredRole;
            });
        }
        if (filterState.emailSearch !== '') {
            filteredExternalUsers = filteredExternalUsers.filter((externalUser) => {
                return externalUser.email.toLowerCase().indexOf(filterState.emailSearch.toLowerCase()) !== -1;
            });
        }
        if (filterState.requestedCustomerSearch !== '') {
            filteredExternalUsers = filteredExternalUsers.filter((externalUser) => {
                return (
                    externalUser.requestedCustomer &&
                    externalUser.requestedCustomer.toLowerCase().indexOf(filterState.requestedCustomerSearch.toLowerCase()) !== -1
                );
            });
        }

        setFilteredUsers(filteredExternalUsers);
    }, [externalUsers, filterState]);

    const updateOrAddUserList = (externalUser: ExternalUser) => {
        const indexOfUser = modifiedUsers.findIndex((x) => x.externalUserId === externalUser.externalUserId);

        if (indexOfUser > -1) {
            modifiedUsers[indexOfUser] = externalUser;
        } else {
            modifiedUsers.push(externalUser);
        }
        setModifiedUsers(_.cloneDeep(modifiedUsers));
        setExternalUsers(_.cloneDeep(externalUsers));
        updateExternalUser(externalUser);
    };

    const onAssignedCompanyChange = (event: any, externalUser: ExternalUser) => {
        const company = event.target.value;
        const assignedCompany = companies.find((c) => c.companyDisplayName === company);
        externalUser.assignedCompany = assignedCompany ? assignedCompany : null;
        updateOrAddUserList(externalUser);
    };

    const onUserRoleChanged = (event: any, externalUser: ExternalUser) => {
        const role = event.target.value;
        externalUser.authRole = role;
        updateOrAddUserList(externalUser);
    };

    const onExpiryDateChange = (date: Date, externalUser: ExternalUser) => {
        externalUser.expiryDate = date;
        updateOrAddUserList(externalUser);
    };

    const onDeleteUserClick = (externalUser: ExternalUser) => {
        if (window.confirm(`You are about to delete ${externalUser.email}. Are you sure?`)) {
            const indexOfExternalUser = externalUsers.findIndex((x) => x.externalUserId === externalUser.externalUserId);

            if (indexOfExternalUser > -1) {
                externalUsers.splice(indexOfExternalUser, 1);
                setExternalUsers(_.cloneDeep(externalUsers));
                deleteExternalUser(externalUser);
            }
        }
    };

    return (
        <>
            <div>
                <FilterWrapper>
                    <FilterElement>
                        <SearchInput name={'emailSearch'} value={emailSearch} placeholder={'Email'} onChange={onFilterChange} clear={clearFilter} />
                    </FilterElement>
                    <FilterElement>
                        <SearchInput
                            name={'requestedCustomerSearch'}
                            value={requestedCustomerSearch}
                            placeholder={'Requested customer'}
                            onChange={onFilterChange}
                            clear={clearFilter}
                        />
                    </FilterElement>
                    <FilterElement>
                        <span>Assigned customer</span>
                        <SelectInput
                            name="filteredAssignedCompany"
                            options={filterAssignedCompanies.map((o) => ({ label: o, value: o }) as SelectInputOption)}
                            onChange={onFilterChange}
                            value={filteredAssignedCompany}
                        />
                    </FilterElement>
                    <FilterElement>
                        <span>Role</span>
                        <SelectInput
                            name="filteredRole"
                            options={filterRoles.map((o) => ({ label: o.label, value: o.value }) as SelectInputOption)}
                            onChange={onFilterChange}
                            value={filteredRole}
                        />
                    </FilterElement>
                </FilterWrapper>
                <TableWrapper>
                    <Table>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Requested Customer</th>
                                <th>Assigned Customer</th>
                                <th>Role</th>
                                <th>Expiry date</th>
                                <th>Created</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedUsers.map((externalUser) => (
                                <tr key={externalUser.externalUserId}>
                                    <td>{externalUser.email}</td>
                                    <td>{externalUser.requestedCustomer}</td>
                                    <td>
                                        <SelectInput
                                            name={`asssignedCompany${externalUser.externalUserId}`}
                                            options={companies.map(
                                                (o) =>
                                                    ({
                                                        label: o.companyDisplayName,
                                                        value: o.companyDisplayName,
                                                    }) as SelectInputOption,
                                            )}
                                            onChange={(event) => onAssignedCompanyChange(event, externalUser)}
                                            value={externalUser.assignedCompany ? `${externalUser.assignedCompany.companyDisplayName}` : ''}
                                            placeholder="Assign company"
                                        />
                                    </td>
                                    <td>
                                        {externalUser.assignedCompany && (
                                            <SelectInput
                                                name={`role${externalUser.externalUserId}`}
                                                options={roles.map((o) => ({ label: o.label, value: o.value }) as SelectInputOption)}
                                                onChange={(event) => onUserRoleChanged(event, externalUser)}
                                                value={externalUser.authRole ? `${externalUser.authRole}` : ''}
                                            />
                                        )}
                                    </td>
                                    <td>
                                        <DateInput
                                            name={`expiryDate${externalUser.externalUserId}`}
                                            date={externalUser.expiryDate}
                                            onChange={(date) => onExpiryDateChange(date, externalUser)}
                                        />
                                    </td>
                                    <td>{prettifyDate(externalUser.createdDate)}</td>
                                    <td>
                                        <button onClick={() => onDeleteUserClick(externalUser)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="paginator-wrapper">
                        <PaginatorComponent list={filteredUsers} setList={setPaginatedUsers}></PaginatorComponent>
                    </div>
                </TableWrapper>
            </div>
        </>
    );
};

export default UsersTab;
