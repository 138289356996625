// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import BackgroundImage from 'components/BackgroundImage';
import { LoginWrapper } from './LoginStyle';
import { useEffect } from 'react';

type MessageType = 'password_reset' | 'sign_up_requested' | null | undefined;

const LoginPage = () => {
    const [messageType, setMessageType] = useState<MessageType>();

    useEffect(() => {
        const b2cDetails = localStorage.getItem('b2cDetails');
        setMessageType(b2cDetails as MessageType);
        localStorage.removeItem('b2cDetails');
    }, []);

    return (
        <>
            <BackgroundImage isFulleHeight={true} />
            <LoginWrapper>
                {!messageType && <h2>Welcome</h2>}
                {messageType === 'password_reset' && <h2>Password reset</h2>}
                {messageType === 'sign_up_requested' && <h2>Thank you for signing up!</h2>}
                <div>
                    {!messageType && <p>Please login or sign up for an account to access the Odfjell Customer Portal</p>}
                    {messageType === 'password_reset' && <p>Your password has been successfully reset. Please sign in with your new password</p>}
                    {messageType === 'sign_up_requested' && (
                        <p>
                            Your sign up request will now be authorized by the Odfjell team. You will receive an email when your request has been
                            approved
                        </p>
                    )}
                </div>
            </LoginWrapper>
        </>
    );
};

export default LoginPage;
