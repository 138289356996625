import { useEffect } from 'react';
import { Company } from 'types/ModelTypes';
import useApi from './useApi';
import { useCompaniesStore } from 'context/CompaniesContext';
import { ApiEndpoints } from './apiEndpointUtils';
import { useQueryParamsHandler } from 'odfjell-query-manager';

export default () => {
    const { state, getApiData } = useApi();
    const { setCompanies } = useCompaniesStore();
    const { triggerQueryParamUpdate } = useQueryParamsHandler();

    const getCompanies = async () => {
        getApiData({ endpoint: ApiEndpoints.getCompanies });
    };

    useEffect(() => {
        if (state.status === 'success') {
            let companies = state.result as Company[];
            const allCompaniesOption: Company = {
                companyId: -1,
                companyDisplayName: 'All',
                linkedImosCompanyIds: [],
            };
            companies = companies.sort((a, b) => (a.companyDisplayName > b.companyDisplayName ? 1 : -1));
            companies.unshift(allCompaniesOption);
            setCompanies([...companies]);
            triggerQueryParamUpdate({ name: 'companyId', fallbackValue: companies[0]?.companyId.toString() ?? '-1' });
        }
    }, [state]);

    return { getCompanies };
};
