import { useInvoicesStore } from 'context/InvoicesContext';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

const Title = styled.div`
    font-weight: 500;
`;

const NegativeNumber = styled.div`
    color: red;
`;

const UnpaidInvoicesComponent: React.FC = () => {
    const { unpaidInvoices } = useInvoicesStore();
    const navigate = useNavigate();
    const [unpaidInvoicesAmount, setUnpaidInvoicesAmount] = useState(0);

    useEffect(() => {
        if (!unpaidInvoices) return;
        const amount = unpaidInvoices.reduce((amount, unpaidInvoice) => (amount += unpaidInvoice.amountInUsd), 0);
        setUnpaidInvoicesAmount(amount);
    }, [unpaidInvoices]);

    return (
        <>
            {unpaidInvoicesAmount > 0 && (
                <Wrapper title={'View invoices'} onClick={() => navigate('/invoices')}>
                    <Title>Unpaid invoices</Title>
                    <NegativeNumber>{Number(unpaidInvoicesAmount.toFixed(0)).toLocaleString()} USD</NegativeNumber>
                </Wrapper>
            )}
        </>
    );
};

export default UnpaidInvoicesComponent;
