import React, { useContext, useReducer, useState } from 'react';
import { PortCall, CargoOperation, Vessel, CustomerVoyage } from 'types/ModelTypes';
import { ContextProps, PortCallCargoOperationMap, SnackbarAction, SnackbarState } from 'types/Types';
import SnackbarReducer from './SnackbarReducer';

type AppStateContextType = {
    selectedCustomerVoyage: CustomerVoyage | null;
    selectedPortCall: PortCall | null;
    selectedCargoOperation: CargoOperation | null;
    selectedPortCallCargoOperationMaps: PortCallCargoOperationMap[];
    snackbarState: SnackbarState;
    hasInputFocus: boolean;
    selectedVesselFilter: Vessel | undefined;
    setHasInputFocus: (hasFocus: boolean) => void;
    dispatchSnackbarState: React.Dispatch<SnackbarAction>;
    setSelectedCustomerVoyage: (etaItem: CustomerVoyage | null) => void;
    setSelectedPortCall: (portCall: PortCall | null) => void;
    setSelectedCargoOperation: (cargoOperation: CargoOperation | null) => void;
    setSelectedPortCallCargoOperationMaps: (portCallCargoOperationMaps: PortCallCargoOperationMap[]) => void;
    setSelectedVesselFilter: (vessel: Vessel | undefined) => void;
    setEta: (customerVoyage: CustomerVoyage, portCall: PortCall, cargoOperation: CargoOperation) => any;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AppStateContext = React.createContext<AppStateContextType>(undefined!);

export const AppStateProvider = ({ children }: ContextProps) => {
    const [selectedCustomerVoyage, setSelectedCustomerVoyage] = useState<CustomerVoyage | null>(null);
    const [selectedPortCall, setSelectedPortCall] = useState<PortCall | null>(null);
    const [selectedCargoOperation, setSelectedCargoOperation] = useState<CargoOperation | null>(null);
    const [selectedVesselFilter, setSelectedVesselFilter] = useState<Vessel | undefined>();
    const [selectedPortCallCargoOperationMaps, setSelectedPortCallCargoOperationMaps] = useState<PortCallCargoOperationMap[]>([]);
    const [hasInputFocus, setHasInputFocus] = useState<boolean>(false);
    const [snackbarState, dispatchSnackbarState] = useReducer(SnackbarReducer, {
        type: 'hide',
        text: '',
        severity: undefined,
    });

    const setEta = (customerVoyage: CustomerVoyage, portCall: PortCall, cargoOperation: CargoOperation) => {
        setSelectedCustomerVoyage(customerVoyage);
        setSelectedPortCall(portCall);
        setSelectedCargoOperation(cargoOperation);
    };

    return (
        <AppStateContext.Provider
            value={{
                setEta,
                selectedCustomerVoyage,
                selectedPortCall,
                selectedCargoOperation,
                selectedPortCallCargoOperationMaps,
                setSelectedCustomerVoyage,
                setSelectedPortCall,
                setSelectedCargoOperation,
                setSelectedPortCallCargoOperationMaps,
                snackbarState,
                dispatchSnackbarState,
                hasInputFocus,
                setHasInputFocus,
                selectedVesselFilter,
                setSelectedVesselFilter,
            }}
        >
            {children}
        </AppStateContext.Provider>
    );
};

export const useAppStateStore = () => useContext(AppStateContext);
