import { AuthState, AuthAction } from './AuthTypes';

export default (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case 'logout':
            return {
                ...state,
                type: 'logout',
                status: 'unauthorized',
                isAuthorizing: false,
            };
        case 'login':
        case 'login-silent':
            return {
                ...state,
                type: action.type,
                status: 'authorizing',
                isAuthorizing: true,
                error: null,
            };
        case 'login_successful':
            return {
                ...state,
                type: 'login',
                status: 'authorized',
                result: action.result,
                isAuthorizing: false,
                error: null,
            };
        case 'login_silent_successful':
            return {
                ...state,
                type: 'login-silent',
                status: 'authorized',
                result: action.result,
                isAuthorizing: false,
                error: null,
            };
        case 'login_failed':
            return {
                ...state,
                type: 'login',
                status: 'unauthorized',
                isAuthorizing: false,
                error: action.error,
            };
        case 'login_silent_failed':
            return {
                ...state,
                type: 'login-silent',
                status: 'unauthorized',
                isAuthorizing: false,
                error: action.error,
            };

        default:
            return state;
    }
};
