import { QueryParamProps, useQueryParamsHandler } from 'odfjell-query-manager';
import { useHandleAppItems } from './item-select-handlers/useHandleAppItems';
import { useHandleEtaItems } from './item-select-handlers/useHandleEtaItems';
import { useHandleEmissionItems } from './item-select-handlers/useHandleEmissionItems';
import { useEffect } from 'react';

export const useQueryParamsManager = () => {
    const { queryParamUpdate, findQueryParamValue } = useQueryParamsHandler();
    const { selectCompany } = useHandleAppItems();
    const { handleEtaPage } = useHandleEtaItems();
    const { handleEmissionPage } = useHandleEmissionItems();

    useEffect(() => {
        if (!queryParamUpdate) return;

        onQueryParamChange(queryParamUpdate);
    }, [queryParamUpdate]);

    const onQueryParamChange = (queryParamUpdate: QueryParamProps) => {
        const pathName = location.pathname;

        const companyQueryParam = findQueryParamValue('companyId');

        if (companyQueryParam) selectCompany(companyQueryParam.newValue);
        else if (pathName === '/eta') handleEtaPage(queryParamUpdate);
        else if (pathName === '/emission') handleEmissionPage(queryParamUpdate);
    };

    return {
        onQueryParamChange,
    };
};
