import styled from 'styled-components';

export const FilterWrapper = styled.div`
    background: #f4f4f4;
    padding: 1rem;
    border: solid 1px #ddd;
    box-shadow: 0 2px 6px -1px #ddd;
    margin-bottom: 0.5rem;
`;

export const FilterInputsWrapper = styled.div`
    display: flex;
`;

export const FilterHelperText = styled.div`
    h1 {
        font-weight: 100;
        margin-top: 0;
    }
`;

export const FilterSelectionsWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    align-items: center;

    .active-voyages-selection {
    }

    .date-range-wrapper {
        display: inline-block;
    }

    .date-range-selection {
        display: flex;
        padding: 0.5rem;

        input {
            margin: 0 1rem;
        }
    }
`;

export const AdvancedButton = styled.div`
    cursor: pointer;
    display: flex;

    .sort-up {
        align-self: center;
    }

    svg {
        margin-left: 0.5rem;
    }
`;

export const ViewModeWrapper = styled.div`
    display: flex;
    position: absolute;
    right: 1rem;
    z-index: 10;
    top: 0;

    .active-view-mode {
        border-bottom: solid 1px #12427d !important;
        border: 1px solid #eee;

        &:hover {
            box-shadow: 0 1px 3px #009fdf57;
            border: solid 1px #009fdf8c;
        }
    }
`;

export const ViewModeButton = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 0.5rem;
    background: #ffffff;
    margin: 0 0.2rem;
    font-size: 0.6rem;

    svg {
        margin: 0 auto;
        margin-bottom: 0.5rem;
        font-size: 0.8rem;
    }

    &:hover {
        border-color: #cccccc;
        box-shadow:
            0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
`;
