import React from 'react';
import { Select } from './SelectInput.style';

export type SelectInputOption = {
    label: string;
    value: string;
};

type Props = {
    className?: string;
    name: string;
    value: string;
    placeholder?: string;
    options: SelectInputOption[];
    onChange: (e: any) => void;
};

const SelectInput: React.FC<Props> = (props) => {
    return (
        <>
            <Select className={props.className} onChange={props.onChange} value={props.value} name={props.name}>
                {props.placeholder && <option value={0}>{props.placeholder}</option>}
                {props.options.map((selectOption, index) => (
                    <option key={index} value={selectOption.value}>
                        {selectOption.label}
                    </option>
                ))}
            </Select>
        </>
    );
};

export default SelectInput;
