import React, { useContext, useState } from 'react';
import { ImosCompany } from 'types/ImosCompanyType';
import { Company } from 'types/ModelTypes';
import { ContextProps } from 'types/Types';

type CompaniesContextType = {
    companies: Company[];
    imosCompanies: ImosCompany[];
    companyToEdit: Company | null;
    selectedCompany: Company | undefined;
    setCompanies: (companies: Company[]) => void;
    setImosCompanies: (imosCompanies: ImosCompany[]) => void;
    setCompanyToEdit: (company: Company | null) => void;
    setSelectedCompany: (company: Company | undefined) => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const CompaniesContext = React.createContext<CompaniesContextType>(undefined!);

export const CompaniesProvider = ({ children }: ContextProps) => {
    const [companies, setCompanies] = useState<Company[]>([]);
    const [imosCompanies, setImosCompanies] = useState<ImosCompany[]>([]);
    const [companyToEdit, setCompanyToEdit] = useState<Company | null>(null);
    const [selectedCompany, setSelectedCompany] = useState<Company | undefined>();

    return (
        <CompaniesContext.Provider
            value={{
                companies,
                setCompanies,
                imosCompanies,
                setImosCompanies,
                selectedCompany,
                setSelectedCompany,
                companyToEdit,
                setCompanyToEdit,
            }}
        >
            {children}
        </CompaniesContext.Provider>
    );
};

export const useCompaniesStore = () => useContext(CompaniesContext);
