import { useLoaderStore } from '../context/LoaderContext';

export default () => {
    const { loaderDetails } = useLoaderStore();

    const isLoadingType = (type: string) => loaderDetails.find((l) => l.type && l.type === type);

    const isLoadingTypeWithData = (type: string, property: string, value: any) => {
        return loaderDetails.some((l) => {
            if (l.type && l.type === type && l.additionalLoaderData) {
                if (l.additionalLoaderData[property] === value) {
                    return true;
                }
            }
        });
    };

    const getVisibleLoaderMessage = (onlyFullScreenLoaders?: boolean) => {
        const details = onlyFullScreenLoaders ? loaderDetails.find((l) => l.isFullScreenLoader) : loaderDetails[0];
        return details?.message ?? undefined;
    };
    return { isLoadingType, isLoadingTypeWithData, getVisibleLoaderMessage, loaderDetails };
};
