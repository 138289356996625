import { useEffect } from 'react';
import { usePbiStore } from 'context/PBIContext';
import { PbiResponse } from 'types/ModelTypes';
import useApi from './useApi';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';
import { useCompaniesStore } from 'context/CompaniesContext';

export default () => {
    const { setPbiAccessToken } = usePbiStore();
    const { state, getApiData } = useApi();
    const { selectedCompany } = useCompaniesStore();

    const getAccessToken = async () => {
        getApiData({
            endpoint: ApiEndpoints.getPowerBiEmdeddedToken,
            queryParam: ApiQueryParams.getPowerBiEmdeddedToken(selectedCompany?.companyId),
        });
    };

    useEffect(() => {
        if (state.status === 'success') {
            const pbiAccessToken = state.result as PbiResponse;
            setPbiAccessToken({ ...pbiAccessToken });
        }
        if (state.status === 'error') {
            setPbiAccessToken(null);
        }
    }, [state]);

    return { getAccessToken };
};
