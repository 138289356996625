import { Action, State } from '../types/ApiTypes';

export default (state: State, action: Action): State => {
    switch (action.type) {
        case 'request_started':
            return {
                ...state,
                status: 'loading',
                isLoading: true,
                endpoint: action.endpoint,
                additionalInfo: action.additionalInfo,
                error: null,
            };
        case 'request_successful':
            return {
                ...state,
                status: 'success',
                endpoint: action.endpoint,
                result: action.result,
                additionalInfo: action.additionalInfo,
                isLoading: false,
                error: null,
            };
        case 'request_failed':
            return {
                ...state,
                status: 'error',
                isLoading: false,
                endpoint: action.endpoint,
                additionalInfo: action.additionalInfo,
                error: action.error,
            };

        default:
            return state;
    }
};
