import { useUserStore } from 'context/UserContext';
import { useEffect } from 'react';
import { ExternalUser } from 'types/ExternalUserType';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';
import useApi from './useApi';
import { useCompaniesStore } from 'context/CompaniesContext';

export default () => {
    const { state, getApiData } = useApi();
    const { setExternalUsers } = useUserStore();
    const { selectedCompany } = useCompaniesStore();

    const getExternalUsers = async () => {
        getApiData({ endpoint: ApiEndpoints.getExternalUsers });
    };

    const getExternalUsersByCompany = async () => {
        if (!selectedCompany) return;
        getApiData({ endpoint: ApiEndpoints.customerAdminGetUsers, queryParam: ApiQueryParams.customerAdminGetUsers(selectedCompany.companyId) });
    };

    useEffect(() => {
        if (state.status === 'success') {
            const externalUsers = state.result as ExternalUser[];
            setExternalUsers(externalUsers);
        }
    }, [state]);

    return { getExternalUsers, getExternalUsersByCompany };
};
