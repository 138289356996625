import React, { useContext } from 'react';
import { useReducer } from 'react';
import { ContextProps, UserAccount } from 'types/Types';
import AuthReducer from './AuthReducer';
import { AuthAction, AuthState } from './AuthTypes';

const initialState: AuthState = { type: 'login-silent', status: 'unauthorized', isAuthorizing: true, error: null };

type AuthContextType = {
    state: AuthState;
    dispatch: React.Dispatch<AuthAction>;
    userAccount: UserAccount | null;
    setUserAccount: (userAccount: UserAccount | null) => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthStateContext = React.createContext<AuthContextType>(undefined!);

export const AuthStateProvider = ({ children }: ContextProps) => {
    const [state, dispatch] = useReducer(AuthReducer, initialState);
    const [userAccount, setUserAccount] = React.useState<UserAccount | null>(null);

    return (
        <AuthStateContext.Provider
            value={{
                state,
                dispatch,
                userAccount,
                setUserAccount,
            }}
        >
            {children}
        </AuthStateContext.Provider>
    );
};

export const useAuthStateStore = () => useContext(AuthStateContext);
