import React from 'react';
import { ContentWrapper } from '../eta/Eta.style';

const Data: React.FC = () => {
    return (
        <ContentWrapper>
            <div>
                <h1>Sample data file</h1>
                Click{' '}
                <a href={'data/sample.csv'} target="_blank" rel="noreferrer">
                    here
                </a>{' '}
                to download a sample file.
                <h1>Access via API</h1>
                Click{' '}
                <a
                    href={
                        'https://odfjell-apim-prod.azure-api.net/odfjellextapi-prod/swagger/ui/?url=https://odfjell-apim-prod.azure-api.net/odfjellextapi-prod/swagger/json#/GetCargoList/GetCargoList'
                    }
                    target="_blank"
                    rel="noreferrer"
                >
                    here
                </a>{' '}
                to access the Swagger documentation of the Odfjell External API.
            </div>
        </ContentWrapper>
    );
};

export default Data;
