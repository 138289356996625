import { useInvoicesStore } from 'context/InvoicesContext';
import { useEffect } from 'react';
import { UnpaidInvoice } from 'types/ModelTypes';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';
import useApi from './useApi';

export default () => {
    const { state, getApiData } = useApi();
    const { setUnpaidInvoices } = useInvoicesStore();

    const getUnpaidInvoices = async (companyId: number) => {
        await getApiData({
            endpoint: ApiEndpoints.getUnpaidInvoices,
            queryParam: ApiQueryParams.getUnpaidInvoices(companyId),
        });
    };

    useEffect(() => {
        if (state.status === 'success') {
            const unpaidInvoices = state.result as UnpaidInvoice[];
            setUnpaidInvoices(unpaidInvoices);
        }
    }, [state]);

    return { getUnpaidInvoices };
};
