const constants = {
    headerHeight: '5rem',
};

const colors = {
    semiDarkBlue: '#aac2d6',
    darkBlue: '#00447e',
    darkDarkBlue: '#021d33',
    lightBlue: '#009fdf',
    orange: '#ec6825',
    danger: '#dc3545',
    grey: '#ccc',
};

const selectableElement = (isSelected: boolean) => `
    cursor: pointer;
    transition: background-color 50ms cubic-bezier(0.4, 0, 0.2, 1);
    ${
        isSelected
            ? `background: ${colors.semiDarkBlue} !important; 
         color: ${colors.darkDarkBlue};
         `
            : `
         &:hover{
            background: #eee;
         }
         &:active {
             transform: scale(0.99);
             transition: color 200ms ease-in 50ms
         }
         `
    }
    &:hover{
        background: #eee;
    }
`;

const clickable = (scale?: number, hoverColor?: string, background?: string) => `
    cursor: pointer;
   
    &:active {
        transform: scale(${scale ?? 0.99});
        transition: color 200ms ease-in 50ms
    }

    ${
        hoverColor
            ? `
        &:hover {
            color: ${hoverColor} !important;
        }
    `
            : ''
    }

    ${
        background
            ? `
        &:hover {
            background: ${background} !important;
        }
    `
            : ''
    }
`;

const card = () => `
    box-shadow: rgb(204 204 204) 0px 2px 6px 2px;
    padding: 1rem;
`;

export default {
    colors,
    primaryTextColor: '#00447e',
    headerHeight: constants.headerHeight,
    contentHeight: `calc(100vh - ${constants.headerHeight} - 2rem)`,
    borderRadius: '4px',
    selectableElement,
    clickable,
    card,
};
