import React from 'react';
import { CargoOperation } from 'types/ModelTypes';
import CargoOperationSymbol from 'components/CargoOperationSymbol';
import styled from 'styled-components';

const CargoItem = styled.div<StyledProps>`
    display: inline-block;
    margin-right: 0.5rem;
    width: ${(props) => (props.width ? props.width : 'unset')};
`;

type StyledProps = {
    width?: string;
    isActive?: boolean;
};

type Props = {
    cargoOperation: CargoOperation;
    width?: string;
    isActive?: boolean;
};

const VesselCargoOperation: React.FC<Props> = (props) => {
    const cargoOperationQty = (cargoOperation: CargoOperation) => {
        const value = cargoOperation.qtyBL ? cargoOperation.qtyBL : cargoOperation.qtyNominated;
        return value.toFixed(3);
    };

    return (
        <>
            <span title={props.cargoOperation.operationType}>
                <CargoItem width={props.width}>{cargoOperationQty(props.cargoOperation)} MT</CargoItem>
                <CargoOperationSymbol operationType={props.cargoOperation.operationType} />
            </span>
        </>
    );
};

export default VesselCargoOperation;
