import styled from 'styled-components';

export const Select = styled.select`
    &.select-title {
        color: #e65100;
        padding: 0.25rem 0.25rem 0.25rem 0.5rem;
        font-size: 1.25rem;
        border: 1px solid #e65100;
        border-radius: 4px;
        cursor: pointer;
        font-family: roboto;
        outline: none;
        width: 17rem;
    }
`;
