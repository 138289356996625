import React, { useEffect } from 'react';
import { useAppStateStore } from 'context/AppStateContext';
import VesselCargoOperation from '../vessel-list/VesselCargoOperation';
import { CargoOperationWrapper, CargoItem } from '../vessel-list/VesselList.style';
import { CargoOperation } from 'types/ModelTypes';
import { DetailsContent, DetailsHeader, PortCallDetailsWrapper } from './PortCallDetails.style';
import { useEtaStore } from 'context/EtaContext';
import useGetCargoData from 'api/useGetCargoData';
import { useQueryParamsHandler } from 'odfjell-query-manager';

const PortCallDetailsComponent: React.FC = () => {
    const { selectedCustomerVoyage, selectedPortCall, selectedCargoOperation } = useAppStateStore();
    const { getCargoData } = useGetCargoData();
    const { filterState } = useEtaStore();
    const { triggerQueryParamUpdate } = useQueryParamsHandler();

    useEffect(() => {
        if (!selectedCargoOperation) return;
        getCargoData(selectedCustomerVoyage, selectedPortCall, selectedCargoOperation);
    }, [selectedCargoOperation]);

    const selectCargoOperation = (cargoOperation: CargoOperation) => {
        triggerQueryParamUpdate({
            name: 'cargoOperationId',
            newValue: cargoOperation.idCargoOperation.toString(),
        });
    };

    return (
        <>
            {selectedCustomerVoyage && selectedPortCall && (
                <PortCallDetailsWrapper>
                    <DetailsHeader>
                        <span>{selectedPortCall.portName}</span>
                        <span>
                            {selectedCustomerVoyage.voyage.voyageNumber} {selectedCustomerVoyage.vessel.vesselName}{' '}
                        </span>
                    </DetailsHeader>
                    <DetailsContent>
                        {selectedPortCall.cargoOperations
                            .filter((cargoOperation) => {
                                if (filterState && filterState.cargoSearch) {
                                    return cargoOperation.tradeName.toLowerCase().indexOf(filterState.cargoSearch.toLowerCase()) !== -1;
                                }
                                return cargoOperation;
                            })
                            .map((cargoOperation) => {
                                return (
                                    <CargoOperationWrapper
                                        key={cargoOperation.idCargoOperation}
                                        onClick={() => selectCargoOperation(cargoOperation)}
                                        isactive={
                                            selectedCargoOperation && selectedCargoOperation.idCargoOperation === cargoOperation.idCargoOperation
                                                ? 'true'
                                                : 'false'
                                        }
                                    >
                                        <CargoItem width={'50%'}>{cargoOperation.tradeName}</CargoItem>
                                        <VesselCargoOperation width={'6rem'} cargoOperation={cargoOperation} />
                                    </CargoOperationWrapper>
                                );
                            })}
                    </DetailsContent>
                </PortCallDetailsWrapper>
            )}
        </>
    );
};

export default PortCallDetailsComponent;
