import { useUserStore } from 'context/UserContext';
import { useEffect } from 'react';
import { ExternalUser } from 'types/ExternalUserType';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';
import useApi from './useApi';

export default () => {
    const { state, postApiData } = useApi();
    const { setExternalUsers, externalUsers } = useUserStore();

    const updateExternalUser = async (externalUser: ExternalUser, companyId?: number) =>
        postApiData({
            endpoint: ApiEndpoints.updateExternalUser,
            data: externalUser,
            queryParam: companyId ? ApiQueryParams.customerAdminUpdateUser(companyId) : undefined,
        });

    useEffect(() => {
        if (state.status === 'success') {
            const externalUserResponse = state.result as ExternalUser;
            const updatedExternalUsers = [...externalUsers];

            const indexOfUser = updatedExternalUsers.findIndex((user) => user.externalUserId === externalUserResponse.externalUserId);
            if (indexOfUser > -1) {
                updatedExternalUsers[indexOfUser] = externalUserResponse;
            }

            setExternalUsers([...updatedExternalUsers]);
        }
    }, [state]);

    return { updateExternalUser };
};
