import React, { useContext, useReducer, useState } from 'react';
import { ReportStatus } from 'types/ModelTypes';
import { ContextProps } from 'types/Types';
import { FilterAction, filterReducer } from 'reducers/FilterReducer';

type EmissionReportContextType = {
    emissionReports: ReportStatus[];
    isTriggeringReports: boolean;
    setEmissionReports: (reportStatuses: ReportStatus[]) => void;
    setIsTriggeringReports: (isTriggered: boolean) => void;
    filterState: any;
    dispatch: React.Dispatch<FilterAction>;
};

const initialFilterState = {
    chartererSearch: '',
    vesselSearch: '',
    voyageSearch: '',
    bookingSearch: '',
    loadPortSearch: '',
    unloadPortSearch: '',
    bookingNumberSearch: '',
    filterDateFrom: null,
    filterDateTo: null,
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const EmissionContext = React.createContext<EmissionReportContextType>(undefined!);

export const EmissionReportProvider = ({ children }: ContextProps) => {
    const [emissionReports, setEmissionReports] = useState<ReportStatus[]>([]);
    const [isTriggeringReports, setIsTriggeringReports] = useState<boolean>(false);
    const [filterState, dispatch] = useReducer(filterReducer, initialFilterState);

    return (
        <EmissionContext.Provider
            value={{
                emissionReports,
                setEmissionReports,
                isTriggeringReports,
                setIsTriggeringReports,
                filterState,
                dispatch,
            }}
        >
            {children}
        </EmissionContext.Provider>
    );
};

export const useEmissionReportStore = () => useContext(EmissionContext);
