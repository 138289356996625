import './App.scss';
import styled from 'styled-components';
import AppRouter from 'route/AppRouter';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faSearch,
    faTimesCircle,
    faShip,
    faSort,
    faSortUp,
    faSortDown,
    faList,
    faPlus,
    faMinus,
    faSignInAlt,
    faSignOutAlt,
    faUserCog,
    faUser,
    faTimes,
    faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { PBIContextProvider } from 'context/PBIContext';
import { AppStateProvider } from 'context/AppStateContext';
import { EtaProvider } from 'context/EtaContext';
import { AppSettingsStateProvider } from 'context/AppSettingsStateContext';
import { AuthStateProvider } from 'auth/AuthStateProvider';
import { CompaniesProvider } from 'context/CompaniesContext';
import { InvoicesProvider } from 'context/InvoicesContext';
import { UserProvider } from 'context/UserContext';
import { Toaster } from 'react-hot-toast';
import { LoaderContextProvider } from 'context/LoaderContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { EmissionReportProvider } from 'context/EmissionReportContext';
import { QueryParamsProvider } from 'odfjell-query-manager/QueryParamsContext';

const AppContainer = styled.div`
    color: ${(props) => props.theme.primaryTextColor};
`;

library.add(
    faSearch,
    faTimesCircle,
    faShip,
    faList,
    faPlus,
    faMinus,
    faSignInAlt,
    faSignOutAlt,
    faSort,
    faSortUp,
    faSortDown,
    faUserCog,
    faUser,
    faTimes,
    faDownload,
);

const AppComponent = () => {
    return (
        <AppContainer>
            <Toaster position="bottom-right" reverseOrder={false} />
            <AppSettingsStateProvider>
                <QueryParamsProvider>
                    <LoaderContextProvider>
                        <AuthStateProvider>
                            <PBIContextProvider>
                                <AppStateProvider>
                                    <EtaProvider>
                                        <CompaniesProvider>
                                            <InvoicesProvider>
                                                <UserProvider>
                                                    <EmissionReportProvider>
                                                        <AppRouter />
                                                    </EmissionReportProvider>
                                                </UserProvider>
                                            </InvoicesProvider>
                                        </CompaniesProvider>
                                    </EtaProvider>
                                </AppStateProvider>
                            </PBIContextProvider>
                        </AuthStateProvider>
                    </LoaderContextProvider>
                </QueryParamsProvider>
            </AppSettingsStateProvider>
        </AppContainer>
    );
};

export default AppComponent;
