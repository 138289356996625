import React, { useEffect } from 'react';
import { service, factories } from 'powerbi-client';
import { usePbiStore } from 'context/PBIContext';
import usePBIEmbedded from 'app/usePBIEmbedded';
import { ReportContainer } from 'components/Syles';
import SpinnerLoaderComponent from 'components/loader/SpinnerLoader';
import useApiLoaderHelper from 'hooks/useApiLoaderHelper';
import { ApiEndpoints } from 'api/apiEndpointUtils';

const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);

const PortOperations: React.FC = () => {
    const reportContainerId = 'portOperationsPBIContainer';
    const { getEmbeddedConfiguration } = usePBIEmbedded();
    const { pbiAccessToken } = usePbiStore();
    const { isLoadingType } = useApiLoaderHelper();
    const isLoadingPbiAccessToken = isLoadingType(ApiEndpoints.getPowerBiEmdeddedToken);

    useEffect(() => {
        const embedContainer = document.getElementById(reportContainerId);
        if (!embedContainer || !pbiAccessToken) return;

        const embedConfiguration = getEmbeddedConfiguration(pbiAccessToken, 'ReportSection');

        powerbi.reset(embedContainer);
        powerbi.embed(embedContainer, embedConfiguration);
    }, [pbiAccessToken]);

    if (isLoadingPbiAccessToken) {
        return (
            <>
                <SpinnerLoaderComponent showOverlay={true}></SpinnerLoaderComponent>
            </>
        );
    }
    return (
        <>
            <ReportContainer id={reportContainerId}></ReportContainer>
        </>
    );
};

export default PortOperations;
