import styled from 'styled-components';

type ContentWrapperProps = {
    $isEditPanelOpen: boolean;
};
export const ContentWrapper = styled.div<ContentWrapperProps>`
    width: 100%;
    height: calc(100vh - 9rem);
    display: grid;
    grid-template-columns: ${(props) => (!props.$isEditPanelOpen ? '1fr' : '20% 1fr')};
    grid-gap: 2rem;

    input {
        padding: 0.3rem;

        &:focus-visible {
            outline-color: #42a3e2;
        }
    }

    h3 {
        margin: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
    }
`;

type CompaniesTRProps = {
    $isSelected?: boolean;
    $isSelectable: boolean;
};
export const CompaniesTR = styled.tr<CompaniesTRProps>`
    ${(props) => (props.$isSelectable ? props.theme.selectableElement(props.$isSelected ?? false) : '')}
    display: inline-block;
    width: 100%;
`;
export const CompaniesTable = styled.table`
    width: 100%;

    thead {
        background: #eee;
        color: #0f3c60;
        font-weight: 700;
    }

    th {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
    }
`;
