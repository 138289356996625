import React, { useContext, useState } from 'react';
import { UnpaidInvoice } from 'types/ModelTypes';
import { ContextProps } from 'types/Types';

type InvoicesContextType = {
    unpaidInvoices: UnpaidInvoice[];
    setUnpaidInvoices: (unpaidInvoice: UnpaidInvoice[]) => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const InvoicesContext = React.createContext<InvoicesContextType>(undefined!);

export const InvoicesProvider = ({ children }: ContextProps) => {
    const [unpaidInvoices, setUnpaidInvoices] = useState<UnpaidInvoice[]>([]);

    return (
        <InvoicesContext.Provider
            value={{
                unpaidInvoices,
                setUnpaidInvoices,
            }}
        >
            {children}
        </InvoicesContext.Provider>
    );
};

export const useInvoicesStore = () => useContext(InvoicesContext);
