import moment from 'moment';
import { PortCall } from 'types/ModelTypes';

export const prettifyDate = (date: Date, includeTime = false) => moment(date).format(`DD.MM.YYYY${includeTime ? ' hh:mm' : ''}`);

export const prettifyNumberDate = (dateNum: number) => {
    const dateStr = dateNum.toString();
    return moment(dateStr, 'YYYYMMDD').format(`DD.MM.YYYY`);
};

export const operatorDateString = (fromDate: Date, toDate: Date) => {
    if (!fromDate) return '';

    if (fromDate === toDate) {
        return `${prettifyDate(fromDate)}`;
    }

    if (toDate) {
        return `${prettifyDate(fromDate)} - ${prettifyDate(toDate)}`;
    }

    return `${prettifyDate(fromDate)}`;
};

export const renderPortCallDates = (portCall: PortCall, isEta: boolean) => {
    const operatorEta = portCall.operatorEta;

    if (operatorEta) {
        const fromDate = isEta ? operatorEta.etaFromDateUtc : operatorEta.etdFromDateUtc;
        const toDate = isEta ? operatorEta.etaToDateUtc : operatorEta.etdToDateUtc;
        return operatorDateString(fromDate, toDate);
    }

    return '';
};
