import React from 'react';
import { Company } from 'types/ModelTypes';
import { CompaniesTable, CompaniesTR, ContentWrapper } from './Companies.style';
import { useCompaniesStore } from 'context/CompaniesContext';
import EditCompanyPanelComponent from './edit-company-panel/EditCompanyPanel';

const CompaniesTab: React.FC = () => {
    const { companies, companyToEdit, setCompanyToEdit } = useCompaniesStore();

    const addCompany = () => {
        const company: Company = {
            companyId: 0,
            companyDisplayName: '',
            linkedImosCompanyIds: [],
        };

        setCompanyToEdit({ ...company });
    };

    return (
        <>
            <ContentWrapper $isEditPanelOpen={companyToEdit !== null}>
                <CompaniesTable>
                    <thead>
                        <CompaniesTR $isSelectable={false}>
                            <th>
                                Name
                                <button onClick={() => addCompany()}>Add</button>
                            </th>
                        </CompaniesTR>
                    </thead>
                    <tbody>
                        {companies.map((c, i) => (
                            <CompaniesTR
                                key={`companies${c.companyId}_${i}`}
                                $isSelectable={true}
                                $isSelected={companyToEdit !== null && companyToEdit.companyId === c.companyId}
                                onClick={() => setCompanyToEdit({ ...c })}
                            >
                                <td>{c.companyDisplayName}</td>
                            </CompaniesTR>
                        ))}
                    </tbody>
                </CompaniesTable>
                {companyToEdit && <EditCompanyPanelComponent company={{ ...companyToEdit }}></EditCompanyPanelComponent>}
            </ContentWrapper>
        </>
    );
};

export default CompaniesTab;
