// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

type Props = {
    isFulleHeight: boolean;
};

type StyleProps = {
    $isFullHeight: boolean;
};

const BackgroundImageElem = styled.div<StyleProps>`
    position: relative;
    display: flex;
    height: ${(props) => (props.$isFullHeight ? '100%' : '60%')};
    background: url(../../front-olympus.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: 24%;

    h1 {
        font-family: 'Pridi', serif;
        width: 100%;
        color: #fff;
        text-align: center;
        align-self: center;
        font-size: 6rem;
        font-weight: 300;
        margin-top: 23rem;
    }
`;

const BackgroundImageComponent = (props: Props) => (
    <BackgroundImageElem $isFullHeight={props.isFulleHeight}>
        <h1>Odfjell Customer Portal</h1>
    </BackgroundImageElem>
);

export default BackgroundImageComponent;
