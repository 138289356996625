import styled from 'styled-components';

type Props = {
    tdWidth?: number | null;
    $sortable?: boolean;
};

export const Table = styled.table<Props>`
    table-layout: fixed;
    width: 100%;
    margin-top: 1rem;
    border-collapse: collapse;
    background: #fff;

    thead {
        background: #efedeb;
        font-weight: 400;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        color: #190b05;
    }

    th,
    td {
        padding: 0.25rem 0.5rem;
        font-size: 0.7rem;
        text-align: left;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
        font-weight: 500;
    }

    th {
        background: #f4f4f4;
        color: #000;
        font-size: 0.85rem;
        font-weight: 600;
        ${(props) => (props.$sortable ? 'cursor: pointer;' : '')}
    }

    td {
        font-size: 0.75rem;
    }

    tbody {
        tr {
            cursor: pointer;

            &:hover {
                background: #e1e9f3;
            }
        }
    }

    .active-item {
        background: #ccc;
    }

    .sortable-th {
        cursor: pointer;
        position: relative;
    }

    .relative {
        position: relative;
    }
`;

export const ReportContainer = styled.div`
    height: 99%;
    width: 99%;
    margin: 0 auto;

    iframe {
        border: 0;
    }
`;
