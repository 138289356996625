import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Header from 'components/header/Header';
import Login from 'pages/login/Login';
import { PrivateRoute } from './PrivateRoute';
import Home from 'pages/home/Home';
import Eta from 'pages/eta/Eta';
import PortOperations from 'pages/port-operations/PortOperations';
import Statistics from 'pages/statistics/Statistics';
import Data from 'pages/data/Data';
import About from 'pages/eta/about/About';
import AdminPage from 'pages/admin/AdminPage';
import CustomerAdminPage from 'pages/admin/CustomerAdminPage';
import { useAppStateStore } from 'context/AppStateContext';
import useAuth from 'auth/useAuth';
import useB2C from 'auth/useB2C';
import { useAuthStateStore } from 'auth/AuthStateProvider';
import useGetPBIAccessToken from 'api/useGetPBIAccessToken';
import InvoicesPage from 'pages/invoices/Invoices';
import SpinnerLoader from 'components/loader/SpinnerLoader';
import useGetUnpaidInvoices from 'api/useGetUnpaidInvoices';
import Emission from 'pages/emission/Emission';
import useApiLoaderHelper from 'hooks/useApiLoaderHelper';
import { useCompaniesStore } from 'context/CompaniesContext';
import { useQueryParamsManager } from 'hooks/useQueryParamsManager';
import { useQueryParamsHandler } from 'odfjell-query-manager';

const ContentWrapper = styled.div`
    position: relative;
    height: ${(props) => props.theme.contentHeight};
`;

const RouterContent: React.FC = () => {
    const { checkAuthStatus } = useAuth();
    const { userAccount, state } = useAuthStateStore();
    const { selectedCompany } = useCompaniesStore();
    const {
        selectedCargoOperation,
        selectedPortCall,
        selectedCustomerVoyage,
        setSelectedCargoOperation,
        setSelectedPortCall,
        setSelectedCustomerVoyage,
    } = useAppStateStore();
    const location = useLocation();
    const navigate = useNavigate();
    const { getAccessToken } = useGetPBIAccessToken();
    const { getUnpaidInvoices } = useGetUnpaidInvoices();
    const { getVisibleLoaderMessage } = useApiLoaderHelper();
    const { triggerAllQueryParamsFromUrl } = useQueryParamsHandler();
    useB2C();
    useQueryParamsManager();

    const visibleLoaderMessage = getVisibleLoaderMessage(true);

    const isOnPowerBIPage = () => {
        const pathName = location.pathname;
        return pathName === '/operations' || pathName === '/statistics';
    };

    const header = <Header />;
    const LoginRoute = () => {
        if (userAccount) {
            let redirectPath = userAccount.urlFrom ?? '';
            if (redirectPath.indexOf('/') === -1) redirectPath = `/${redirectPath}`;
            return <Route path="/login" element={<Navigate to={redirectPath} />}></Route>;
        }

        return <Route path="/login" element={<Login />}></Route>;
    };

    useEffect(() => {
        if (selectedCargoOperation) {
            setSelectedCargoOperation(null);
        }
        if (selectedPortCall) {
            setSelectedPortCall(null);
        }
        if (selectedCustomerVoyage) {
            setSelectedCustomerVoyage(null);
        }

        triggerAllQueryParamsFromUrl();
    }, [location]);

    // useEffect(() => {
    //     if (!queryParamUpdate) return;
    //     const companyId = getQueryParamValue('companyId');

    //     selectCompany(companyId);
    // }, [queryParamUpdate]);

    useEffect(() => {
        if (!selectedCompany) return;

        getUnpaidInvoices(selectedCompany.companyId);

        if (selectedCompany.companyId > -1) {
            getAccessToken();
        } else if (isOnPowerBIPage()) {
            navigate('/');
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (userAccount && (userAccount.role === 'customer' || userAccount.role === 'external-admin')) {
            getAccessToken();
        } else if (!userAccount) {
            checkAuthStatus();
        }
    }, [userAccount]);

    useEffect(() => {
        checkAuthStatus();
    }, []);

    return (
        <>
            {header}
            {visibleLoaderMessage && <SpinnerLoader showOverlay={true} text={visibleLoaderMessage ?? ''} />}
            {!state.isAuthorizing && (userAccount?.role === 'odfjell-employee' || userAccount?.role === 'odfjell-admin' ? selectedCompany : true) && (
                <ContentWrapper>
                    <Routes>
                        {LoginRoute()}
                        <Route
                            path="/"
                            element={
                                <PrivateRoute>
                                    <Home />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/eta"
                            element={
                                <PrivateRoute>
                                    <Eta />
                                </PrivateRoute>
                            }
                        />
                        {selectedCompany && selectedCompany.companyId > -1 && (
                            <>
                                <Route
                                    path="/operations"
                                    element={
                                        <PrivateRoute>
                                            <PortOperations />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/statistics"
                                    element={
                                        <PrivateRoute>
                                            <Statistics />
                                        </PrivateRoute>
                                    }
                                />
                            </>
                        )}

                        <Route
                            path="/data"
                            element={
                                <PrivateRoute>
                                    <Data />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/emission"
                            element={
                                <PrivateRoute>
                                    <Emission />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/about"
                            element={
                                <PrivateRoute>
                                    <About />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/invoices"
                            element={
                                <PrivateRoute>
                                    <InvoicesPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/admin"
                            element={
                                <PrivateRoute authRole="odfjell-admin">
                                    <AdminPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/customerAdmin"
                            element={
                                <PrivateRoute authRole="external-admin">
                                    <CustomerAdminPage />
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </ContentWrapper>
            )}
        </>
    );
};

export default RouterContent;
