import styled from 'styled-components';

type StyledProps = {
    width?: string;
    isactive?: string | null;
};

export const Q88DocumentButton = styled.button`
    display: block;
    cursor: pointer;
    color: #004781;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 4px;
    padding: 0.5rem 0.8rem;
    font-weight: 500;
    font-size: x-small;

    &:hover {
        background: #ccc;
    }
`;

export const VesselTitle = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    background: #f4f4f4;

    .voyage-name {
        display: flex;
        align-items: center;
        font-size: 0.9em;
        cursor: pointer;
        h3 {
            padding: 0;
            font-weight: 700;
        }

        h3,
        h4 {
            margin: 0 0.5rem;
            font-weight: 500;
        }
    }
`;

export const VesselWrapper = styled.div`
    border: solid 1px #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 #00000024;
    margin-bottom: 1rem;
    padding-top: 0;
`;

export const PortCallListWrapper = styled.div`
    position: relative;
    th:last-child,
    td:last-child {
        text-align: right;
        padding-right: 2rem;
    }

    .active-row {
        background: #e1e9f3;
        font-weight: 700;

        td {
            font-weight: 900;
        }
    }

    .no-pos-row {
        opacity: 0.5;
    }
`;

export const ExpandedWrapper = styled.td`
    background: #f9f9f9;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: 500;
`;

export const CargoWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(25rem, 100%), 1fr));
    grid-column-gap: 1rem;

    .active-cargo-operation {
        background: #ccc;
        font-size: 1.05rem;
    }
`;

export const CargoOperationWrapper = styled.div<StyledProps>`
    font-size: 0.9rem;
    cursor: pointer;
    background: ${(props) => (props.isactive === 'true' ? '#e1e9f3' : 'unset')};

    &:hover {
        background: #e1e9f3;
        font-size: 0.75rem;
        transition: 200ms all ease-in-out;
    }
`;

export const CargoItem = styled.div<StyledProps>`
    display: inline-block;
    margin-right: 0.5rem;
    padding: 0.15rem 0.5rem;
    width: ${(props) => (props.width ? props.width : 'unset')};
`;
