import React from 'react';
import { Wrapper } from './About.style';

const About: React.FC = () => {
    return (
        <>
            <Wrapper>
                <h1>Privacy and Data Protection</h1>
                <p>
                    Odfjell respects the data privacy of visitors to our websites. This page explains how Odfjell handles the personal data we collect
                    on our website. If you do not agree with the terms herein, please do not use this website. Privacy and data protection laws
                    protect the integrity and confidentiality of a person’s private information. Odfjell is committed to protecting the privacy rights
                    of our employees and everyone with whom we do business. We will only use personal data for appropriate purposes such as creating a
                    personalized and improved experience on our internet pages. Personal data will be processed in accordance with applicable data
                    protection regulations.
                </p>
                <p>
                    We collect your voluntarily provided personal data for the purpose of providing you with our services and to react on your
                    requests or inquiries. On our websites, we may collect information about name, email address and other contact information.
                </p>
                <p>
                    Odfjell SE is the data controller for personal data submitted to this portal. If you have any questions about this privacy and
                    data protection policy please contact privacy@odfjell.com.
                </p>
            </Wrapper>
        </>
    );
};

export default About;
