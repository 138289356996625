import React from 'react';
import moment from 'moment';
import { prettifyDate } from 'utils/renderTextHelper';
import VesselCargoOperation from 'components/vessel-list/VesselCargoOperation';
import { CargoDetailsInfo, CargoDetailsWrapper, LoaderWrapper } from './CargoOperationDetails.style';
import SpinnerLoaderComponent from 'components/loader/SpinnerLoader';
import { PortCall } from 'types/ModelTypes';
import { useEtaStore } from 'context/EtaContext';
import { useAppStateStore } from 'context/AppStateContext';
import useApiLoaderHelper from 'hooks/useApiLoaderHelper';
import { ApiEndpoints } from 'api/apiEndpointUtils';

const CargoOperationDetailsComponent: React.FC = () => {
    const { selectedCustomerVoyage, selectedPortCall, selectedCargoOperation } = useAppStateStore();
    const { portCallCargoOperationMaps } = useEtaStore();
    const { isLoadingType } = useApiLoaderHelper();
    const isLoadingCargoData = isLoadingType(ApiEndpoints.getCargoData);

    const getPortCallDate = (portCall: PortCall) => {
        if (portCall.operatorEta) {
            const etaFrom = portCall.operatorEta.etaFromDateUtc;
            const etaTo = portCall.operatorEta.etaToDateUtc;
            const etdFrom = portCall.operatorEta.etdFromDateUtc;
            const etdTo = portCall.operatorEta.etdToDateUtc;

            let arrivalString = '';
            if (moment(etaFrom).isSame(etaTo, 'day')) {
                arrivalString = prettifyDate(etaFrom);
            } else {
                arrivalString = `(${prettifyDate(etaFrom)}-${prettifyDate(etaTo)})`;
            }

            if (etdFrom) {
                let departureString = '';

                if (moment(etdFrom).isSame(etdTo, 'day')) {
                    departureString = prettifyDate(etdFrom);
                } else {
                    departureString = `(${prettifyDate(etdFrom)}-${prettifyDate(etdTo)})`;
                }

                return arrivalString + ' - ' + departureString;
            }

            return arrivalString;
        }

        return '';
    };

    return (
        <>
            {selectedCustomerVoyage && selectedCargoOperation && (
                <CargoDetailsWrapper id="cargoOperationDetails">
                    {isLoadingCargoData && (
                        <LoaderWrapper>
                            <SpinnerLoaderComponent></SpinnerLoaderComponent>
                        </LoaderWrapper>
                    )}
                    {!isLoadingCargoData &&
                        selectedPortCall &&
                        portCallCargoOperationMaps.map((pcMap, i) => (
                            <div key={`${i}_${pcMap.cargoOperation.idCargoOperation}_${pcMap.portCall.idPortCall}`}>
                                <CargoDetailsInfo>
                                    <div className={'port-name'} title={'Port'}>
                                        {pcMap.portCall.portName}
                                    </div>
                                    <div className={'port-call-eta'} title={'Arrival - Departure'}>
                                        <div>{getPortCallDate(pcMap.portCall)}</div>
                                    </div>

                                    <VesselCargoOperation cargoOperation={pcMap.cargoOperation} width={'6rem'} />
                                </CargoDetailsInfo>
                            </div>
                        ))}
                </CargoDetailsWrapper>
            )}
        </>
    );
};

export default CargoOperationDetailsComponent;
