import { SnackbarAction, SnackbarState } from 'types/Types';

export default (state: SnackbarState, action: SnackbarAction): SnackbarState => {
    switch (action.type) {
        case 'show':
            return {
                ...state,
                type: action.type,
                text: action.text,
                severity: action.severity,
            };
        case 'hide':
            return {
                ...state,
                type: action.type,
                severity: undefined,
                text: '',
            };
        default:
            return state;
    }
};
