import useApi from './useApi';
import { useEmissionReportStore } from 'context/EmissionReportContext';
import { useEffect } from 'react';
import { useToast } from 'hooks/useToast';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';

export default () => {
    const { state, getApiData } = useApi();
    const { setIsTriggeringReports } = useEmissionReportStore();
    const { toastLoader } = useToast();

    const regenerateReports = async (reportStatusIds: string) => {
        const get = getApiData({
            endpoint: ApiEndpoints.regenerateReports,
            queryParam: ApiQueryParams.regenerateReports(reportStatusIds),
        });
        toastLoader('Regenerating reports', 'The reports will be sent to emissions@odfjell.com shortly', get);
        await get;
    };

    const sendReportMail = async (reportStatusIds: string) => {
        const get = getApiData({
            endpoint: ApiEndpoints.sendReportMail,
            queryParam: ApiQueryParams.sendReportMail(reportStatusIds),
        });
        toastLoader('Sending reports', 'You will recieve the reports shortly', get);
        await get;
    };

    useEffect(() => {
        setIsTriggeringReports(state.isLoading);
    }, [state]);

    return { regenerateReports, sendReportMail };
};
