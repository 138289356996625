import styled from 'styled-components';
import { Link } from 'react-router-dom';

type StyleProps = {
    $isdisabled?: boolean;
};

export const InfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 2%;
`;

export const InfoItem = styled(Link)<StyleProps>`
    cursor: ${(props) => (props.$isdisabled ? 'default' : 'pointer')} !important;
    padding: 1rem;
    color: ${(props) => (props.$isdisabled ? '#ccc' : 'unset')} !important;

    &:hover {
        background: ${(props) => (props.$isdisabled ? 'unset' : '#f6f7f8')} !important;
    }

    h2 {
        color: ${(props) => (props.$isdisabled ? '#ccc' : '#ec6825')} !important;
        font-weight: 300;
        margin-bottom: 0;
    }

    h4 {
        margin: 0;
        line-height: 1.8rem;
        font-weight: 400;
    }
`;
