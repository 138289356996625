import { useEffect } from 'react';
import { ReportStatus, ReportType } from 'types/ModelTypes';
import useApi from './useApi';
import { useEmissionReportStore } from 'context/EmissionReportContext';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';

export default () => {
    const { state, getApiData } = useApi();
    const { setEmissionReports } = useEmissionReportStore();

    const getEmissionReports = async (companyId: number, reportType: ReportType) => {
        await getApiData({
            endpoint: ApiEndpoints.getEmissionReports,
            queryParam: ApiQueryParams.getEmissionReports(companyId, reportType),
        });
    };

    useEffect(() => {
        if (state.status === 'success') {
            const reportStatuses = state.result as ReportStatus[];
            setEmissionReports([...reportStatuses].filter((r) => r.vesselName));
        }
    }, [state]);

    return { getEmissionReports };
};
