import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useCompaniesStore } from 'context/CompaniesContext';
import { useAuthStateStore } from 'auth/AuthStateProvider';

type Props = {
    path?: string;
    title: string;
    isDisabled?: boolean;
    disableActiveLink?: boolean;
    queryParams?: string;
};

const Div = styled.div`
    cursor: pointer;

    .active-link {
        border-bottom: solid 1px;
    }
`;

const DisabledHeader = styled.div`
    color: #ccc;
`;

const HeaderItem: React.FC<Props> = ({ path, title, isDisabled, disableActiveLink, queryParams }) => {
    const location = useLocation();
    const { selectedCompany } = useCompaniesStore();
    const { userAccount } = useAuthStateStore();

    const isCustomer = userAccount && userAccount.role === 'external-user';

    const queryParamsStr = queryParams ?? '';
    const companyQueryParam =
        selectedCompany && !isCustomer ? `?companyId=${selectedCompany.companyId}${queryParamsStr ? `&${queryParamsStr}` : ''}` : queryParamsStr;

    if (isDisabled) {
        return <DisabledHeader>{title.toUpperCase()}</DisabledHeader>;
    }
    return (
        <Div>
            <Link
                className={!disableActiveLink && location.pathname === `/${path}` ? 'active-link' : ''}
                to={{
                    pathname: `/${path}`,
                    search: companyQueryParam,
                }}
            >
                {title.toUpperCase()}
            </Link>
        </Div>
    );
};

export default HeaderItem;
