import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppStateStore } from 'context/AppStateContext';

type Props = {
    name: string;
    placeholder: string;
    value: string;
    clear: (name: string) => void;
    onChange: (e: any) => void;
};

const Input = styled.input`
    padding: 0.5rem;
`;

const SeachInput = styled.div`
    display: inline-block;
    position: relative;
    margin-right: 1rem;

    input {
        padding-right: 2.5rem;
        width: 8rem;
    }

    .search-icon {
        position: absolute;
        right: 1rem;
        top: 25%;
    }

    .clear-icon {
        position: absolute;
        top: -0.5rem;
        right: -0.7rem;
        cursor: pointer;
    }
`;

const SearchInputComponent: React.FC<Props> = (props) => {
    const { setHasInputFocus } = useAppStateStore();
    const clear = () => props.clear(props.name);

    return (
        <>
            <SeachInput>
                <Input
                    type="text"
                    name={props.name}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    autoComplete={'off'}
                    onFocus={() => setHasInputFocus(true)}
                    onBlur={() => setHasInputFocus(false)}
                />
                <FontAwesomeIcon className="search-icon" icon="search" />
                {props.value && <FontAwesomeIcon className="clear-icon clickable-element" icon="times-circle" onClick={clear} title="Clear" />}
            </SeachInput>
        </>
    );
};

export default SearchInputComponent;
