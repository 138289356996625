import React, { useState } from 'react';
import CargoMap from 'components/vessel-map/CargoMap';
import CargoList from 'components/cargo-list/CargoList';
import VesselList from 'components/vessel-list/VesselList';
import EtaFiltersComponent from '../../components/eta-filters/EtaFilters';
import { ContentWrapper, ListContentWrapper, ListWrapper, PortCallWrapper } from './Eta.style';
import PortCallDetailsComponent from 'components/port-call-details/PortCallDetails';
import CargoOperationDetailsComponent from 'components/cargo-operation-details/CargoOperationDetails';
import { useAppStateStore } from 'context/AppStateContext';
import { useEtaStore } from 'context/EtaContext';

const Eta: React.FC = () => {
    const { filteredCustomerVoyages, filterState } = useEtaStore();
    const { selectedPortCall, selectedCargoOperation } = useAppStateStore();
    const [viewMode, setViewMode] = useState('ship');

    const CargoOperation = () => {
        const isInFilter =
            selectedCargoOperation && selectedCargoOperation.tradeName.toLowerCase().indexOf(filterState.cargoSearch.toLowerCase()) !== -1;
        if (isInFilter) {
            return <CargoOperationDetailsComponent />;
        }

        return <></>;
    };

    return (
        <>
            <ContentWrapper>
                <ListContentWrapper>
                    <EtaFiltersComponent viewMode={viewMode} setViewMode={setViewMode} />
                    {filteredCustomerVoyages && (
                        <ListWrapper>
                            {viewMode === 'list' && <CargoList />}
                            {viewMode === 'ship' && <VesselList />}
                        </ListWrapper>
                    )}
                </ListContentWrapper>
                <div>
                    {/* {
                        isLoading && !isLoadingEtaItems &&
                        <SpinnerLoader showOverlay={true}/>
                    } */}
                    {selectedPortCall && (
                        <PortCallWrapper id="portCallWrapper">
                            <PortCallDetailsComponent />
                            {CargoOperation()}
                        </PortCallWrapper>
                    )}
                    <CargoMap />
                </div>
            </ContentWrapper>
        </>
    );
};

export default Eta;
