import styled from 'styled-components';

export const Wrapper = styled.div`
    ${(props) => props.theme.card()}

    label {
        input {
            margin-left: 0.5rem;
        }
    }
`;

export const ImosCompaniesWrapper = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 1rem;
`;

export const ImosCompaniesList = styled.div`
    height: calc(100vh - 20rem);

    .test {
        height: 10rem;
    }

    .company-row {
        display: flex;
        justify-content: space-between;
        padding-right: 2rem;
        width: 95% !important;
        ${(props) => props.theme.clickable(undefined, undefined, props.theme.colors.semiDarkBlue)}

        svg {
            ${(props) => props.theme.clickable(0.8)}
            font-size: 0.8rem;
        }

        .remove-icon {
            color: #dc3545;
        }

        .add-icon {
        }
    }
`;

export const Searchbar = styled.div`
    display: inline-block;
    width: 100%;
    margin-bottom: 0.5rem;
    h4 {
        display: inline-block;
    }

    input {
        display: inline-block;
        width: 60%;
        margin-left: 1rem;
    }
`;

export const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
`;
