import { useCompaniesStore } from 'context/CompaniesContext';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ImosCompany } from 'types/ImosCompanyType';
import { Company } from 'types/ModelTypes';
import { ButtonRow, ImosCompaniesList, ImosCompaniesWrapper, Searchbar, Wrapper } from './EditCompanyPanel.style';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUpsertCompany from 'api/useUpsertCompany';

type Props = {
    company: Company;
};

const EditCompanyPanelComponent: React.FC<Props> = ({ company }) => {
    const { imosCompanies } = useCompaniesStore();
    const [companyToEdit, setCompanyToEdit] = useState<Company>({ ...company });
    const [linkedImosCompanies, setLinkedImosCompanies] = useState<ImosCompany[]>([]);
    const [availableImosCompanies, setAvailableImosCompanies] = useState<ImosCompany[]>([]);
    const [linkedCompanySearch, setLinkedCompanySearch] = useState('');
    const [availableCompanySearch, setAvailableCompanySearch] = useState('');
    const { upsertCompany } = useUpsertCompany();

    useEffect(() => {
        if (!company || !company.linkedImosCompanyIds) return;
        setCompanyToEdit({ ...company });
    }, [company]);

    useEffect(() => {
        if (!companyToEdit || !companyToEdit.linkedImosCompanyIds) return;
        filterLinkedImosCompanies(linkedCompanySearch);
        filterAvailableImosCompanies(availableCompanySearch);
    }, [companyToEdit]);

    const filterLinkedImosCompanies = (companySearch: string) => {
        setLinkedImosCompanies([
            ...imosCompanies.filter(
                (c) =>
                    company.linkedImosCompanyIds.some((id) => id === c.companyId) &&
                    (companySearch !== '' && c.fullName.toLowerCase().indexOf(companySearch.toLowerCase())) !== -1,
            ),
        ]);
    };

    const filterAvailableImosCompanies = (companySearch: string) => {
        setAvailableImosCompanies([
            ...imosCompanies.filter(
                (c) =>
                    !company.linkedImosCompanyIds.some((id) => id === c.companyId) &&
                    (companySearch !== '' && c.fullName.toLowerCase().indexOf(companySearch.toLowerCase())) !== -1,
            ),
        ]);
    };

    const onCompanyDisplayNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!companyToEdit) return;
        companyToEdit.companyDisplayName = e.target.value;
        setCompanyToEdit({ ...companyToEdit });
    };

    const onAvailableCompanySearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!companyToEdit) return;
        setAvailableCompanySearch(e.target.value);
        filterAvailableImosCompanies(e.target.value);
    };
    const onLinkedCompanySearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!companyToEdit) return;
        setLinkedCompanySearch(e.target.value);
        filterLinkedImosCompanies(e.target.value);
    };

    const onAddImosCompanyClick = (imosCompany: ImosCompany) => {
        companyToEdit.linkedImosCompanyIds.push(imosCompany.companyId);
        setCompanyToEdit({ ...companyToEdit });
    };

    const onRemoveImosCompanyClick = (index: number) => {
        companyToEdit.linkedImosCompanyIds.splice(index, 1);
        setCompanyToEdit({ ...companyToEdit });
    };

    const onSaveClick = () => {
        if (companyToEdit.companyDisplayName.trim() === '') return;
        upsertCompany(companyToEdit);
    };

    const Row = (params: any) => {
        const _company = params.data[params.index] as ImosCompany;
        return (
            <div className="company-row" style={params.style} title="Add" onClick={() => onAddImosCompanyClick(_company)}>
                {_company.fullName}
                <FontAwesomeIcon icon={'plus'} title={'Add'} className="add-icon" />
            </div>
        );
    };

    return (
        <>
            <Wrapper>
                <div>
                    <label>
                        Company name
                        <input
                            type="text"
                            name={'companyName'}
                            value={companyToEdit.companyDisplayName}
                            onChange={(e) => onCompanyDisplayNameChange(e)}
                        ></input>
                    </label>
                </div>
                <ImosCompaniesWrapper>
                    <div>
                        <Searchbar>
                            <h4>Linked companies</h4>
                            <input
                                type="text"
                                name="linkedCompanyName"
                                value={linkedCompanySearch}
                                placeholder="Company name"
                                onChange={(e) => onLinkedCompanySearchChange(e)}
                            ></input>
                        </Searchbar>
                        <ImosCompaniesList>
                            {linkedImosCompanies.map((imosCompany, i) => (
                                <div
                                    className="company-row"
                                    key={`linkedImos_${imosCompany.companyId}_${i}`}
                                    title="Remove"
                                    onClick={() => onRemoveImosCompanyClick(i)}
                                >
                                    {imosCompany.fullName}
                                    <FontAwesomeIcon icon={'times'} title={'Remove'} className="remove-icon" />
                                </div>
                            ))}
                        </ImosCompaniesList>
                    </div>
                    <div>
                        <Searchbar>
                            <h4>Companies</h4>
                            <input
                                type="text"
                                name="linkedCompanyName"
                                value={availableCompanySearch}
                                placeholder="Company name"
                                onChange={(e) => onAvailableCompanySearchChange(e)}
                            ></input>
                        </Searchbar>
                        <ImosCompaniesList>
                            <AutoSizer>
                                {({ height, width }: any) => (
                                    <List
                                        height={height}
                                        width={width}
                                        itemData={availableImosCompanies}
                                        itemCount={availableImosCompanies.length}
                                        itemSize={20}
                                    >
                                        {Row}
                                    </List>
                                )}
                            </AutoSizer>
                        </ImosCompaniesList>
                    </div>
                </ImosCompaniesWrapper>
                <ButtonRow>
                    <button onClick={() => onSaveClick()}>Save</button>
                    {companyToEdit.companyId > 0 && <button>Delete</button>}
                </ButtonRow>
            </Wrapper>
        </>
    );
};

export default EditCompanyPanelComponent;
