import { useContext, useEffect, useReducer } from 'react';
import ApiReducer from './ApiReducer';
import getContext from 'context/AppSettingsContext';
import { useLoaderStore } from 'context/LoaderContext';
import { getLoaderDetailsType } from './apiEndpointUtils';
import axios, { AxiosRequestConfig } from 'axios';

type GetProps = {
    endpoint: string;
    queryParam?: string | null;
    additionalInfo?: any;
    config?: AxiosRequestConfig;
};

type PostProps = {
    endpoint: string;
    data?: any;
    queryParam?: string | null;
    config?: AxiosRequestConfig;
    additionalInfo?: any;
};

export default () => {
    const appSettings = useContext(getContext());
    const { loaderDetails, setLoaderDetails } = useLoaderStore();
    const [state, dispatch] = useReducer(ApiReducer, { status: 'empty', isLoading: false, error: null, endpoint: null });

    useEffect(() => {
        if (!state.endpoint) return;
        if (state.isLoading) {
            const loaderDetail = getLoaderDetailsType(state.endpoint);

            if (loaderDetail) {
                if (state.additionalInfo?.customLoaderMessage) {
                    loaderDetail.message = state.additionalInfo.customLoaderMessage;
                }
                loaderDetail.additionalLoaderData = state.additionalInfo?.additionalLoaderData;
                loaderDetails.push(loaderDetail);
                setLoaderDetails([...loaderDetails]);
            }
        } else {
            const _loaderDetails = [...[...loaderDetails].filter((l) => l.endpoint !== state.endpoint)];
            setLoaderDetails([..._loaderDetails]);
        }

        return () => {
            setLoaderDetails([]);
        };
    }, [state]);

    const getApiData = async (props: GetProps) => {
        const endpoint = props.endpoint;
        const queryParam = props.queryParam;
        const additionalInfo = props.additionalInfo;
        const config = props.config;

        try {
            dispatch({ type: 'request_started', endpoint, additionalInfo });

            if (!endpoint) {
                throw new Error('Missing url parameter');
            }
            const url = appSettings.api + endpoint + (queryParam && queryParam.length > 0 ? queryParam : '');
            const response = await axios.get(url, config);

            if (response.status !== 200) {
                throw new Error(`${response.status} ${response.statusText}`);
            }

            dispatch({ type: 'request_successful', result: response.data, endpoint, additionalInfo });
        } catch (error) {
            dispatch({ type: 'request_failed', error: error, endpoint, additionalInfo });
        }
    };

    const putApiData = async (props: PostProps) => {
        const endpoint = props.endpoint;
        const data = props.data;
        const queryParam = props.queryParam;
        const config = props.config;
        const additionalInfo = props.additionalInfo;
        try {
            dispatch({ type: 'request_started', endpoint, additionalInfo });

            if (!endpoint) {
                throw new Error('Missing url parameter');
            }
            const url = appSettings.api + endpoint + (queryParam && queryParam.length > 0 ? queryParam : '');
            const response = await axios.put(url, data, config);

            if (response.status !== 200) {
                throw new Error(`${response.status} ${response.statusText}`);
            }

            dispatch({ type: 'request_successful', result: response.data, endpoint, additionalInfo });
        } catch (error) {
            dispatch({ type: 'request_failed', error: error, endpoint, additionalInfo });
        }
    };

    const postApiData = async (props: PostProps) => {
        const endpoint = props.endpoint;
        const data = props.data;
        const queryParam = props.queryParam;
        const config = props.config;
        const additionalInfo = props.additionalInfo;
        try {
            dispatch({ type: 'request_started', endpoint, additionalInfo });

            if (!endpoint) {
                throw new Error('Missing url parameter');
            }
            const url = appSettings.api + endpoint + (queryParam && queryParam.length > 0 ? queryParam : '');
            const response = await axios.post(url, data, config);

            if (response.status !== 200) {
                throw new Error(`${response.status} ${response.statusText}`);
            }

            dispatch({ type: 'request_successful', result: response.data, endpoint, additionalInfo });
        } catch (error) {
            dispatch({ type: 'request_failed', error: error, endpoint, additionalInfo });
        }
    };

    const deleteApiData = async (props: PostProps) => {
        const endpoint = props.endpoint;
        const data = props.data;
        const queryParam = props.queryParam;
        const additionalInfo = props.additionalInfo;
        try {
            dispatch({ type: 'request_started', endpoint, additionalInfo });

            if (!endpoint) {
                throw new Error('Missing url parameter');
            }
            const url = appSettings.api + endpoint + (queryParam && queryParam.length > 0 ? queryParam : '');
            const response = await axios.delete(url, data);

            if (response.status !== 200) {
                throw new Error(`${response.status} ${response.statusText}`);
            }

            dispatch({ type: 'request_successful', result: response.data, endpoint, additionalInfo });
        } catch (error) {
            dispatch({ type: 'request_failed', error: error, endpoint, additionalInfo });
        }
    };

    return { state, getApiData, putApiData, deleteApiData, postApiData };
};
