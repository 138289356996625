import React, { useEffect, useState } from 'react';
import { Table } from 'components/Syles';
import DateInput from 'components/date-input/DateInput';
import _ from 'lodash';
import PaginatorComponent from 'components/paginator/Paginator';
import { TableWrapper, PageWrapper } from './AdminPage.style';
import { useUserStore } from 'context/UserContext';
import useGetExternalUsers from 'api/useGetExternalUsers';
import usePutExternalUser from 'api/usePutExternalUser';
import useDeleteExternalUser from 'api/useDeleteExternalUser';
import { ExternalUser } from 'types/ExternalUserType';
import { useCompaniesStore } from 'context/CompaniesContext';

const CustomerAdminPage: React.FC = () => {
    const { getExternalUsersByCompany } = useGetExternalUsers();
    const { selectedCompany } = useCompaniesStore();
    const { externalUsers, setExternalUsers } = useUserStore();
    const [modifiedUsers, setModifiedUsers] = useState<ExternalUser[]>([]);
    const [paginatedUsers, setPaginatedUsers] = useState<ExternalUser[]>([]);
    const { updateExternalUser } = usePutExternalUser();
    const { deleteExternalUser } = useDeleteExternalUser();

    useEffect(() => {
        getExternalUsersByCompany();
    }, [selectedCompany]);

    const updateOrAddUserList = (externalUser: ExternalUser) => {
        if (!selectedCompany) return;
        const indexOfUser = modifiedUsers.findIndex((x) => x.externalUserId === externalUser.externalUserId);

        if (indexOfUser > -1) {
            modifiedUsers[indexOfUser] = externalUser;
        } else {
            modifiedUsers.push(externalUser);
        }
        setModifiedUsers(_.cloneDeep(modifiedUsers));
        setExternalUsers(_.cloneDeep(externalUsers));
        updateExternalUser(externalUser, selectedCompany.companyId);
    };

    const onExpiryDateChange = (date: Date, externalUser: ExternalUser) => {
        externalUser.expiryDate = date;
        updateOrAddUserList(externalUser);
    };

    const onDeleteUserClick = (externalUser: ExternalUser) => {
        if (!selectedCompany) return;
        if (window.confirm(`You are about to delete ${externalUser.email}. Are you sure?`)) {
            const indexOfExternalUser = externalUsers.findIndex((x) => x.externalUserId === externalUser.externalUserId);

            if (indexOfExternalUser > -1) {
                externalUsers.splice(indexOfExternalUser, 1);
                setExternalUsers(_.cloneDeep(externalUsers));
                deleteExternalUser(externalUser, selectedCompany.companyId);
            }
        }
    };

    return (
        <>
            <PageWrapper>
                <TableWrapper>
                    <Table>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Expiry date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedUsers.map((externalUser) => (
                                <tr key={externalUser.externalUserId}>
                                    <td>{externalUser.email}</td>
                                    <td>
                                        <DateInput
                                            name={`expiryDate${externalUser.externalUserId}`}
                                            date={externalUser.expiryDate}
                                            onChange={(date) => onExpiryDateChange(date, externalUser)}
                                        ></DateInput>
                                    </td>
                                    <td>
                                        <button onClick={() => onDeleteUserClick(externalUser)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="paginator-wrapper">
                        <PaginatorComponent list={externalUsers} setList={setPaginatedUsers}></PaginatorComponent>
                    </div>
                </TableWrapper>
            </PageWrapper>
        </>
    );
};

export default CustomerAdminPage;
