import { useCompaniesStore } from 'context/CompaniesContext';
import { useEffect } from 'react';
import { ImosCompany } from 'types/ImosCompanyType';
import { ApiEndpoints } from './apiEndpointUtils';
import useApi from './useApi';

export default () => {
    const { state, getApiData } = useApi();
    const { setImosCompanies } = useCompaniesStore();

    const getImosCompanies = async () => {
        getApiData({ endpoint: ApiEndpoints.getImosCompanies });
    };

    useEffect(() => {
        if (state.status === 'success') {
            const companies = state.result as ImosCompany[];
            if (!companies) return;
            setImosCompanies(
                companies
                    .filter((c) => c.companyId > 0)
                    .sort((a, b) => (a.shortName.trim().toLowerCase() > b.shortName.trim().toLowerCase() ? 1 : -1)),
            );
        }
    }, [state]);

    return { getImosCompanies };
};
