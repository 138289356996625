import * as Msal from '@azure/msal-browser';

export const B2CAuthConfig: Msal.Configuration = {
    auth: {
        clientId: 'bf06b286-fa6e-4c12-8f5b-e3b23a91eec4',
        authority: 'https://odfjellcustomerportalb2c.b2clogin.com/odfjellcustomerportalb2c.onmicrosoft.com/B2C_1A_signup_signin',
        knownAuthorities: ['odfjellcustomerportalb2c.b2clogin.com'],
    },
    system: {
        loadFrameTimeout: 60000,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};

export const ResetPasswordConfig = {
    authority: 'https://odfjellcustomerportalb2c.b2clogin.com/odfjellcustomerportalb2c.onmicrosoft.com/B2C_1A_PasswordReset',
};

export const B2CScopes = ['openid', 'profile', 'bf06b286-fa6e-4c12-8f5b-e3b23a91eec4'];
