import { useCompaniesStore } from 'context/CompaniesContext';

export const useHandleAppItems = () => {
    const { companies, setSelectedCompany, selectedCompany } = useCompaniesStore();

    const selectCompany = (companyId?: string | null) => {
        if (!companyId) return;
        const company = companies.find((company) => company.companyId === +companyId);
        if (company && company.companyId !== selectedCompany?.companyId) setSelectedCompany({ ...company });
    };

    return { selectCompany };
};
