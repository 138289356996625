import React, { useContext, useState } from 'react';
import { ContextProps } from 'types/Types';
import { LoaderDetailsType } from '../types/LoaderDetailsType';

type LoaderContextType = {
    loaderDetails: LoaderDetailsType[];
    setLoaderDetails: (loaderDetails: LoaderDetailsType[]) => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const LoaderContext = React.createContext<LoaderContextType>(undefined!);

export const LoaderContextProvider = ({ children }: ContextProps) => {
    const [loaderDetails, setLoaderDetails] = useState<LoaderDetailsType[]>([]);

    return (
        <LoaderContext.Provider
            value={{
                loaderDetails,
                setLoaderDetails,
            }}
        >
            {children}
        </LoaderContext.Provider>
    );
};

export const useLoaderStore = () => useContext(LoaderContext);
