export interface CustomerVoyage {
    vessel: Vessel;
    voyage: Voyage;
    portCalls: PortCall[];
}

export interface CargoOperation {
    idCargoOperation: number;
    idCargo: number;
    qtyBL: number;
    qtyNominated: number;
    operationType: string;
    tradeName: string;
}

export interface PortCallCargoOperationMap {
    cargoOperation: CargoOperation;
    portCall: PortCall;
}

export interface PortCall {
    idPortCall: number;
    portName: string;
    portArrivalDateUTC: Date;
    portDepartureDateUTC: Date;
    cargoOperations: CargoOperation[];
    operatorEta: OperatorEta;
}

export interface OperatorEta {
    operatorEtaId: number;
    portCallId: number;
    chartererId: number;
    etaFromDateUtc: Date;
    etaToDateUtc: Date;
    etdFromDateUtc: Date;
    etdToDateUtc: Date;
    modifiedDate: Date;
    remarks: string;
}

export interface Voyage {
    voyageNumber: number;
    idVoyage: number;
    sortDesc: boolean;
    voyageStatus: string;
    voyageStartingDateUtc: Date;
    voyageEndingDateUtc: Date;
}

export interface Vessel {
    imoNumber: number;
    vesselName: string;
    sortDesc: boolean;
    currentVesselPosition: VesselPosition;
}

export interface CargoData {
    portCallCargoOperationMaps: PortCallCargoOperationMap[];
    cargoPositions: VesselPosition[];
}

export interface VesselPosition {
    id: number;
    imoNumber: number;
    latitude: number;
    longitude: number;
    speed: number;
    course: number;
    timestamp: Date;
    vesselName: string;
}

export interface PBIAccessToken {
    expires_on: string;
    access_token: string;
}

export interface Company {
    companyId: number;
    companyDisplayName: string;
    linkedImosCompanyIds: number[];
}

export enum ReportType {
    SCC = 1,
    EUETS = 2,
}

export interface ReportStatus {
    reportStatusId: number;
    imoNumber: number;
    chartererId: number;
    bookingNumber: number;
    reportUrl: string;
    status: string;
    vesselName: string;
    voyageNumber: string;
    loadPort: string;
    unloadPort: string;
    lastUpdated: Date;
    cpDate: Date;
    loadingDate: Date;
    unloadingDate: Date;
    reportType: ReportType;
}

export interface UnpaidInvoice {
    unpaidInvoicesId: string;
    companyId: number;
    amountInUsd: number;
    dateInvoice: number;
    dateDueInvoice: number;
    dateDueActual: number;
    invoiceTypeCode: string;
    invoiceNumber: string;
    invoiceTransactionNumber: string;
    paymentTermsCode: string;
    paymentTermsDescription: string;
    paidInFull: string;
    approvedBy: string;
    cpPlace: string;
    broker: string;
    voyage: string;
    lineOfBusinessName: string;
    overdueDays: number;
    overdueCategory: string;
    dueWithinCategory: string;
    tradeAreaDescription: string;
}

export interface PbiResponse {
    token: string;
    embedUrl: string;
    reportId: string;
}
