import { LoaderDetailsType } from 'types/LoaderDetailsType';
import { ReportStatus } from 'types/ModelTypes';

export const ApiEndpoints = {
    deleteExternalUser: 'DeleteExternalUser',
    downloadExcelReport: 'DownloadExcelReport',
    downloadQ88Document: 'GetQ88PdfDocument',
    getCargoData: 'GetCargoData',
    getCompanies: 'GetCompanies',
    getCustomerVoyages: 'GetCustomerVoyages',
    getExternalUsers: 'GetExternalUsers',
    customerAdminGetUsers: 'GetUsers',
    updateExternalUser: 'UpdateExternalUser',
    getImosCompanies: 'GetImosCompanies',
    getPowerBiEmdeddedToken: 'GetPowerBiEmdeddedToken',
    getVesselPositions: 'GetVesselPositions',
    getEmissionReports: 'GetEmissionReports',
    getUnpaidInvoices: 'GetUnpaidInvoices',
    getUserCompany: 'GetUserCompany',
    regenerateReports: 'RegenerateReports',
    sendReportMail: 'SendReportMail',
    updateCompany: 'UpdateCompany',
    addCompany: 'AddCompany',
};
export const ApiQueryParams = {
    deleteExternalUser: (externalUserId: string, companyId?: number) =>
        `?externalUserId=${externalUserId}${companyId ? `&companyId=${companyId}` : ''}`,
    downloadExcelReport: (companyId: number, reportStatus: ReportStatus) =>
        `?companyId=${companyId}&chartererId=${reportStatus.chartererId}&reportStatusId=${reportStatus.reportStatusId}`,
    downloadQ88Document: (imoNumber: number, companyId?: number) => `?imoNumber=${imoNumber}${companyId ? `&companyId=${companyId}` : ''}`,
    getCargoData: (imoNumber: number, idCargo: number, companyId?: number) =>
        `?imoNumber=${imoNumber}&idCargo=${idCargo}${companyId ? `&companyId=${companyId}` : ''}`,
    getPowerBiEmdeddedToken: (companyId?: number) => (companyId ? `?companyId=${companyId}` : ''),
    getCustomerVoyages: (from: string, to: string, companyId: number) => `?from=${from}&to=${to}&companyId=${companyId}`,
    getVesselPositions: (imoNumber: string, from: string, to: string, companyId?: number) =>
        `?imoNumber=${imoNumber}&from=${from}&to=${to}${companyId ? `&companyId=${companyId}` : ''}`,
    getEmissionReports: (companyId: number, reportType: number) => `?companyId=${companyId}&reportType=${reportType}`,
    getUnpaidInvoices: (companyId: number) => `?companyId=${companyId}`,
    customerAdminGetUsers: (companyId: number) => `?companyId=${companyId}`,
    customerAdminUpdateUser: (companyId: number) => `?companyId=${companyId}`,
    customerAdminDeleteUser: (companyId: number) => `?companyId=${companyId}`,
    regenerateReports: (reportStatusIds: string) => `?reportStatusIds=${reportStatusIds}`,
    sendReportMail: (reportStatusIds: string) => `?reportStatusIds=${reportStatusIds}`,
};

export const getLoaderDetailsType = (endpoint: string): LoaderDetailsType | undefined => {
    switch (endpoint) {
        case ApiEndpoints.deleteExternalUser:
            return { message: 'Deleting user', isFullScreenLoader: true, endpoint };
        case ApiEndpoints.downloadExcelReport:
        case ApiEndpoints.downloadQ88Document:
            return { message: 'Downloading', isFullScreenLoader: true, endpoint };
        case ApiEndpoints.getCargoData:
        case ApiEndpoints.getCompanies:
        case ApiEndpoints.getCustomerVoyages:
        case ApiEndpoints.getExternalUsers:
        case ApiEndpoints.customerAdminGetUsers:
        case ApiEndpoints.getImosCompanies:
        case ApiEndpoints.getPowerBiEmdeddedToken:
        case ApiEndpoints.getVesselPositions:
        case ApiEndpoints.getEmissionReports:
        case ApiEndpoints.getUnpaidInvoices:
        case ApiEndpoints.getUserCompany:
            return { message: 'Loading', isFullScreenLoader: true, endpoint };
        case ApiEndpoints.updateExternalUser:
        case ApiEndpoints.updateCompany:
        case ApiEndpoints.addCompany:
            return { message: 'Updating', isFullScreenLoader: true, endpoint };
        case ApiEndpoints.regenerateReports:
            return { message: 'Regenerating', isFullScreenLoader: true, endpoint };
        case ApiEndpoints.sendReportMail:
            return { message: 'Sending', isFullScreenLoader: true, endpoint };
        default:
            return undefined;
    }
};
