import './VesselList.scss';
import React, { useState, useEffect } from 'react';
import { CargoOperation, PortCall, CustomerVoyage, Vessel } from 'types/ModelTypes';
import { useEtaStore } from 'context/EtaContext';
import VesselCargoOperation from './VesselCargoOperation';
import useGetPortCallVesselPositions from 'api/useGetPortCallVesselPositions';
import { renderPortCallDates, prettifyDate } from 'utils/renderTextHelper';
import SortIconComponent from 'components/SortIcon';
import {
    ExpandedWrapper,
    CargoWrapper,
    CargoOperationWrapper,
    CargoItem,
    VesselWrapper,
    VesselTitle,
    PortCallListWrapper,
    Q88DocumentButton,
} from './VesselList.style';
import { Table } from 'components/Syles';
import moment from 'moment';
import { useAppStateStore } from 'context/AppStateContext';
import useGetCargoData from 'api/useGetCargoData';
import { useQueryParamsHandler } from 'odfjell-query-manager';
import useDownloadQ88Document from 'api/useDownloadQ88Document';

type PortCallTableBody = {
    customerVoyage: CustomerVoyage;
    rows: JSX.Element[];
};

const VesselListComponent: React.FC = () => {
    const { getCargoData } = useGetCargoData();
    const { triggerQueryParamUpdate } = useQueryParamsHandler();
    const { getPortCallVesselPositions } = useGetPortCallVesselPositions();
    const { downloadQ88Document } = useDownloadQ88Document();
    const { filteredCustomerVoyages, setFilteredCustomerVoyages } = useEtaStore();
    const { selectedPortCall, selectedCargoOperation, setSelectedCargoOperation, selectedCustomerVoyage, setSelectedVesselFilter } =
        useAppStateStore();
    const [tbodyRows, setTbodyRows] = useState<PortCallTableBody[]>([]);

    const showTrack = (customerVoyage: CustomerVoyage, portCall: PortCall, cargoOperation: CargoOperation) => {
        getCargoData(customerVoyage, portCall, cargoOperation);
        setSelectedCargoOperation(cargoOperation);
    };

    const showPortCallTrack = (customerVoyage: CustomerVoyage) => getPortCallVesselPositions(customerVoyage.vessel, customerVoyage.voyage);

    const expandPortCall = (portCall: PortCall) => {
        triggerQueryParamUpdate(
            {
                name: 'portCallId',
                newValue: portCall.idPortCall.toString(),
                clearParam: portCall.idPortCall === selectedPortCall?.idPortCall,
            },
            ['cargoOperationId'],
        );
    };

    const renderExpandedPortCall = (customerVoyage: CustomerVoyage, portCall: PortCall): JSX.Element => {
        return (
            <tr className={'animate-in'} key={`expanded-${portCall.idPortCall}`}>
                <ExpandedWrapper colSpan={3}>
                    <CargoWrapper>
                        {portCall.cargoOperations.map((cargoOperation) => {
                            return (
                                <CargoOperationWrapper
                                    key={cargoOperation.idCargoOperation}
                                    onClick={() => showTrack(customerVoyage, portCall, cargoOperation)}
                                    className={
                                        selectedCargoOperation?.idCargoOperation === cargoOperation.idCargoOperation ? 'active-cargo-operation' : ''
                                    }
                                >
                                    <CargoItem width={'16rem'}>{cargoOperation.tradeName}</CargoItem>
                                    <VesselCargoOperation cargoOperation={cargoOperation} />
                                </CargoOperationWrapper>
                            );
                        })}
                    </CargoWrapper>
                </ExpandedWrapper>
            </tr>
        );
    };

    const sortVoyage = (customerVoyage: CustomerVoyage) => {
        customerVoyage.voyage.sortDesc = !customerVoyage.voyage.sortDesc;

        customerVoyage.portCalls = customerVoyage.portCalls.sort((a, b) => {
            const aArrival = a.operatorEta ? a.operatorEta.etaFromDateUtc : a.portArrivalDateUTC;
            const bArrival = b.operatorEta ? b.operatorEta.etaFromDateUtc : b.portArrivalDateUTC;

            if (!customerVoyage.voyage.sortDesc) {
                return aArrival > bArrival ? 1 : -1;
            }
            return aArrival < bArrival ? 1 : -1;
        });

        setFilteredCustomerVoyages([...filteredCustomerVoyages]);
    };

    const goToVessel = (vessel: Vessel) => {
        setSelectedVesselFilter({ ...vessel });
    };

    const onQ88DownloadClick = (vessel: Vessel) => {
        downloadQ88Document(vessel);
    };

    useEffect(() => {
        if (selectedCustomerVoyage) {
            showPortCallTrack(selectedCustomerVoyage);
        }
    }, [selectedCustomerVoyage]);

    useEffect(() => {
        const portCallTableBodies = filteredCustomerVoyages.map((customerVoyage) => {
            const portCallRows: JSX.Element[] = [];

            customerVoyage.portCalls.forEach((portCall) => {
                const portCallRow = (
                    <tr
                        key={portCall.idPortCall}
                        onClick={() => expandPortCall(portCall)}
                        className={
                            (selectedPortCall?.idPortCall === portCall.idPortCall ? 'active-row' : '') +
                            (moment().isBefore(portCall?.operatorEta?.etaFromDateUtc) ? ' no-pos-row' : '')
                        }
                    >
                        <td>{portCall.portName}</td>
                        <td>{renderPortCallDates(portCall, true)}</td>
                    </tr>
                );

                portCallRows.push(portCallRow);
            });

            const portCallTableBody: PortCallTableBody = {
                customerVoyage: customerVoyage,
                rows: portCallRows,
            };

            return portCallTableBody;
        });

        setTbodyRows(portCallTableBodies);
    }, [filteredCustomerVoyages, selectedPortCall]);

    useEffect(() => {
        if (!selectedCargoOperation || !selectedPortCall) return;

        const newTbodyRows = [...tbodyRows];

        const tbodyRow = newTbodyRows.find((tbodyRow) =>
            tbodyRow.rows.find((portCallRow) => portCallRow.key && +portCallRow.key === selectedPortCall?.idPortCall),
        );
        const indexOfExpandedRow = tbodyRow?.rows.findIndex((row) => row.key?.toString().indexOf('expanded') === 0);

        if (!tbodyRow || !indexOfExpandedRow || indexOfExpandedRow < 0) return;
        tbodyRow.rows[indexOfExpandedRow] = renderExpandedPortCall(tbodyRow.customerVoyage, selectedPortCall);
        setTbodyRows(newTbodyRows);
    }, [selectedCargoOperation]);

    return (
        <>
            <div>
                {filteredCustomerVoyages.map((customerVoyage) => {
                    return (
                        <VesselWrapper key={`customerVoyage_${customerVoyage.vessel.imoNumber}_${customerVoyage.voyage.voyageNumber}`}>
                            <VesselTitle>
                                <div
                                    className="voyage-name"
                                    title={`Filter by ${customerVoyage.vessel.vesselName}`}
                                    onClick={() => goToVessel(customerVoyage.vessel)}
                                >
                                    <h3>
                                        {customerVoyage.voyage.voyageNumber} {customerVoyage.vessel.vesselName}
                                    </h3>
                                    <h4></h4>
                                    <Q88DocumentButton onClick={() => onQ88DownloadClick(customerVoyage.vessel)} title="Download Q88 document">
                                        Q88 Document
                                    </Q88DocumentButton>
                                </div>
                                <div className="voyage-date-range">
                                    {prettifyDate(customerVoyage.voyage.voyageStartingDateUtc)} -{' '}
                                    {prettifyDate(customerVoyage.voyage.voyageEndingDateUtc)}
                                </div>
                            </VesselTitle>
                            <div>
                                <PortCallListWrapper>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Port</th>
                                                <th>Arrival</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tbodyRows.find(
                                                    (x) =>
                                                        x.customerVoyage.vessel.imoNumber === customerVoyage.vessel.imoNumber &&
                                                        x.customerVoyage.voyage.idVoyage === customerVoyage.voyage.idVoyage,
                                                )?.rows
                                            }
                                        </tbody>
                                    </Table>
                                    <SortIconComponent
                                        sortFunction={() => sortVoyage(customerVoyage)}
                                        sortDesc={customerVoyage.voyage.sortDesc}
                                        title={'Order by arrival date'}
                                    />
                                </PortCallListWrapper>
                            </div>
                        </VesselWrapper>
                    );
                })}
            </div>
        </>
    );
};

export default VesselListComponent;
