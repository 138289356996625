// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
import {
    DrawLocationMarkers,
    InitMap,
    GetPolyline,
    SetMapOnAll,
    GetLocationMarker,
    FitBounds,
    CenterMap,
    getCurrentLocationMarker,
} from 'utils/googleMapsHelper';
import { VesselPosition } from 'types/ModelTypes';
import { LatLng } from 'types/Types';
import { MapContainer, MapSpinnerWrapper } from './CargoMap.style';
import { prettifyDate } from 'utils/renderTextHelper';
import SpinnerLoaderComponent from 'components/loader/SpinnerLoader';
import { useAppStateStore } from 'context/AppStateContext';
import { useEtaStore } from 'context/EtaContext';
import useApiLoaderHelper from 'hooks/useApiLoaderHelper';
import { ApiEndpoints } from 'api/apiEndpointUtils';

type GoogleMapsEvent = {
    event: 'idle';
};

const drawLocationMarkersOnEvent = (map: google.maps.Map, locationMarkers: google.maps.Marker[]) => {
    if (locationMarkers) {
        DrawLocationMarkers(locationMarkers, map);
    }
};

const CargoMapComponent = () => {
    const [map, setMap] = useState<google.maps.Map>();
    const [mapEvent, setMapEvent] = useState<GoogleMapsEvent>();
    const [locationMarkers, setLocationMarkers] = useState<google.maps.Marker[]>();
    const [vesselInfoWindows, setVesselInfoWindows] = useState<google.maps.InfoWindow[]>([]);
    const [cargoTrack, setCargoTrack] = useState<google.maps.Polyline>();
    const { filteredCustomerVoyages, cargoPositions, portCallVesselPositions } = useEtaStore();
    const { hasInputFocus } = useAppStateStore();
    const [hasVisibleMarkers, setHasVisibleMarkers] = useState(false);
    const [mapHeight, setMapHeight] = useState('100%');
    const [currentLocationMarker, setCurrentLocationMarker] = useState<google.maps.Marker>();
    const { isLoadingType } = useApiLoaderHelper();
    const isLoadingCargoData = isLoadingType(ApiEndpoints.getCargoData);
    const isLoadingMapData = isLoadingType(ApiEndpoints.getVesselPositions);

    let markerInfoWindow: google.maps.InfoWindow | null = null;

    const addMapMarkers = (positions: Array<VesselPosition>, addTrack = false, addVesselInfoWindow = false) => {
        if (!map) return;

        const latLngs = positions.map<LatLng>((position) => {
            return { lat: position.latitude, lng: position.longitude };
        });
        const infoWindows: google.maps.InfoWindow[] = [];

        if (cargoTrack) {
            cargoTrack.setMap(null);
        }

        if (addTrack) {
            const newCargoTrack = GetPolyline(map, latLngs);
            if (newCargoTrack) {
                setCargoTrack(newCargoTrack);
            }
        }

        if (locationMarkers) {
            SetMapOnAll(null, locationMarkers);
        }

        if (currentLocationMarker) {
            currentLocationMarker.setMap(null);
        }

        const newLocationMarkers: google.maps.Marker[] = [];
        positions.forEach((position) => {
            const latLng: LatLng = { lat: position.latitude, lng: position.longitude };
            const locationMarker = GetLocationMarker(map, latLng, position.course);

            if (locationMarker) {
                let vesselInfoWindow: google.maps.InfoWindow;
                const infoWindowContent = `<div class="marker-info" key="infowindow_content_${position.timestamp}">
                    <h2>${position.vesselName ? position.vesselName : 'Location data'}</h2>
                    <p><label>UTC:</label><span>${prettifyDate(position.timestamp, true)}</span></p>
                </div>`;

                if (addVesselInfoWindow) {
                    vesselInfoWindow = new google.maps.InfoWindow({
                        content: `<div class="marker-info" key="vessel_infowindow_${position.timestamp}">
                            <h2>${position.vesselName ? position.vesselName : 'Location data'}</h2>
                            <p><label>UTC:</label><span>${prettifyDate(position.timestamp, true)}</span></p>
                        </div>`,
                    });
                    if (!hasInputFocus) {
                        vesselInfoWindow.open(map, locationMarker);
                    }
                    infoWindows.push(vesselInfoWindow);
                    locationMarker.setMap(null);
                }

                google.maps.event.addListener(locationMarker, 'mouseover', () => {
                    if (markerInfoWindow) {
                        markerInfoWindow.close();
                    }

                    if (vesselInfoWindow) {
                        vesselInfoWindow.close();
                    }
                    const shortInfoWindow = new google.maps.InfoWindow({
                        content: infoWindowContent,
                    });

                    shortInfoWindow.open(map, locationMarker);
                    markerInfoWindow = shortInfoWindow;
                });

                google.maps.event.addListener(locationMarker, 'mouseout', function () {
                    if (markerInfoWindow) {
                        markerInfoWindow.close();
                        markerInfoWindow = null;
                    }
                    if (vesselInfoWindow) {
                        vesselInfoWindow.open(map, locationMarker);
                    }
                });

                locationMarker.setMap(null);
                newLocationMarkers.push(locationMarker);
            }
        });

        setLocationMarkers(newLocationMarkers);
        setVesselInfoWindows([...infoWindows]);

        if (addTrack) {
            addCurrentLocationMarker(positions);
        }

        if (newLocationMarkers.length > 1) {
            FitBounds(map, newLocationMarkers);
        } else {
            CenterMap(map);
        }
        const hasMapMarkers = newLocationMarkers && newLocationMarkers.length > 0;
        setHasVisibleMarkers(hasMapMarkers ? hasMapMarkers : false);
        return infoWindows;
    };

    const addCurrentLocationMarker = (positions: Array<VesselPosition>) => {
        if (positions.length === 0 || !map) return;

        const latestPosition = positions[positions.length - 1];
        const locationMarker = getCurrentLocationMarker(map, {
            lat: latestPosition.latitude,
            lng: latestPosition.longitude,
        });

        locationMarker.setZIndex(999);

        const infoWindow = new google.maps.InfoWindow({
            content: `<div class="marker-info">
                <h2>Location data</h2>
                <p><label>UTC:</label><span>${prettifyDate(latestPosition.timestamp, true)}</span></p>
            </div>`,
        });

        google.maps.event.addListener(locationMarker, 'mouseover', () => {
            infoWindow.open(map, locationMarker);
        });

        google.maps.event.addListener(locationMarker, 'mouseout', () => {
            if (infoWindow) {
                infoWindow.close();
            }
        });

        setCurrentLocationMarker(locationMarker);
    };

    useEffect(() => {
        const elem = document.getElementById('cargoMap');
        if (google && elem && !map) {
            const map = InitMap('cargoMap');

            map.addListener('idle', () => {
                setMapEvent({ event: 'idle' });
                if (locationMarkers) {
                    drawLocationMarkersOnEvent(map, locationMarkers);
                }
            });

            setMap(map);
        }
    }, [google]);

    useEffect(() => {
        if (!mapEvent) return;

        if (mapEvent.event === 'idle') {
            if (locationMarkers && map) {
                drawLocationMarkersOnEvent(map, locationMarkers);
            }
        }
    }, [mapEvent]);

    useEffect(() => {
        addMapMarkers(cargoPositions, true);
    }, [cargoPositions]);

    useEffect(() => {
        addMapMarkers(portCallVesselPositions, true);
    }, [portCallVesselPositions]);
    useEffect(() => {
        if (vesselInfoWindows && !hasInputFocus) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            vesselInfoWindows.forEach((infoWindow, i) => infoWindow.open(map, locationMarkers![i]));
        }
    }, [hasInputFocus]);

    useEffect(() => {
        if (!filteredCustomerVoyages) return;

        const vesselPositions: any[] = [];

        filteredCustomerVoyages.forEach((customerVoyage) => {
            if (customerVoyage && customerVoyage.voyage.voyageStatus === 'Commenced') {
                const vessel = customerVoyage.vessel;
                if (vessel) {
                    if (!vesselPositions.some((vp) => vp.vesselName === vessel.vesselName)) {
                        const vesselPosition = vessel.currentVesselPosition;
                        if (vesselPosition) {
                            vesselPosition.vesselName = vessel.vesselName;
                            vesselPositions.push(vesselPosition);
                        }
                    }
                }
            }
        });

        addMapMarkers(vesselPositions, false, true);
    }, [filteredCustomerVoyages]);

    useEffect(() => {
        const portCallWrapperElem = document.getElementById('portCallWrapper');

        if (portCallWrapperElem) {
            const offsetHeight = portCallWrapperElem.offsetHeight;

            setMapHeight(`calc(100% - ${offsetHeight}px  - 1rem)`);
        } else {
            setMapHeight('100%');
        }
    }, [document.getElementById('portCallWrapper'), document.getElementById('cargoOperationDetails'), isLoadingCargoData]);

    return (
        <>
            <MapContainer $mapHeight={mapHeight}>
                <div id="cargoMap"></div>
                {!hasVisibleMarkers && (
                    <div className="warning-wrapper">
                        <span>No position data</span>
                    </div>
                )}
                {isLoadingMapData && (
                    <MapSpinnerWrapper>
                        <div className={'map-spinner'}>
                            <SpinnerLoaderComponent></SpinnerLoaderComponent>
                        </div>
                    </MapSpinnerWrapper>
                )}
            </MapContainer>
        </>
    );
};

export default CargoMapComponent;
