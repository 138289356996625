import React, { useContext, useState } from 'react';
import { ExternalUser } from 'types/ExternalUserType';
import { ContextProps, UserAccount } from 'types/Types';

type UserContextType = {
    externalUsers: ExternalUser[];
    userAccount: UserAccount | null;
    setExternalUsers: (externalUsers: ExternalUser[]) => void;
    setUserAccount: (userAccount: UserAccount | null) => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const UserContext = React.createContext<UserContextType>(undefined!);

export const UserProvider = ({ children }: ContextProps) => {
    const [externalUsers, setExternalUsers] = useState<ExternalUser[]>([]);
    const [userAccount, setUserAccount] = useState<UserAccount | null>(null);

    return (
        <UserContext.Provider
            value={{
                externalUsers,
                setExternalUsers,
                userAccount,
                setUserAccount,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUserStore = () => useContext(UserContext);
