import { useEffect } from 'react';
import { Company } from 'types/ModelTypes';
import useApi from './useApi';
import { ApiEndpoints } from './apiEndpointUtils';
import { useCompaniesStore } from 'context/CompaniesContext';

export default () => {
    const { state, getApiData } = useApi();
    const { setSelectedCompany } = useCompaniesStore();

    const getUserCompany = async () => {
        getApiData({ endpoint: ApiEndpoints.getUserCompany });
    };

    useEffect(() => {
        if (state.status === 'success') {
            const company = state.result as Company;
            setSelectedCompany({ ...company });
        }
    }, [state]);

    return { getUserCompany };
};
