import React, { useEffect, useState } from 'react';
import { PageWrapper, Tab, TabsWrapper } from './AdminPage.style';
import UsersTab from './users/UsersTab';
import CompaniesTab from './companies/CompaniesTab';
import useGetImosCompanies from 'api/useGetImosCompanies';
import { useAuthStateStore } from 'auth/AuthStateProvider';

const AdminPage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<'users' | 'companies'>('users');
    const { userAccount } = useAuthStateStore();
    const { getImosCompanies } = useGetImosCompanies();

    const isOdfjellEmployee = () => userAccount && (userAccount.role === 'odfjell-employee' || userAccount.role === 'odfjell-admin');

    useEffect(() => {
        if (isOdfjellEmployee()) {
            getImosCompanies();
        }
    }, [userAccount]);

    return (
        <>
            <TabsWrapper>
                <Tab $isSelected={selectedTab === 'users'} onClick={() => setSelectedTab('users')}>
                    Users
                </Tab>
                <Tab $isSelected={selectedTab === 'companies'} onClick={() => setSelectedTab('companies')}>
                    Companies
                </Tab>
            </TabsWrapper>
            <PageWrapper>
                {selectedTab === 'users' && <UsersTab></UsersTab>}
                {selectedTab === 'companies' && <CompaniesTab></CompaniesTab>}
            </PageWrapper>
        </>
    );
};

export default AdminPage;
