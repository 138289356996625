import styled from 'styled-components';

export const TableWrapper = styled.div`
    .paginator-wrapper {
        float: right;
        padding: 0 1rem;
    }
`;

export const FilterWrapper = styled.div`
    display: flex;
    span {
        margin-right: 1rem;
    }

    .add-col {
        border-left: solid 1px;
        padding-left: 1rem;
    }
`;

export const FilterElement = styled.div`
    display: inline-block;
    margin-right: 1rem;

    span {
        margin-right: 0.5rem;
    }

    select {
        padding: 0.3rem;
    }
`;

export const PageWrapper = styled.div`
    display: flex;
    padding: 0 1rem;
    margin-top: 1rem;
`;

type TabProps = {
    $isSelected: boolean;
};
export const TabsWrapper = styled.div`
    display: flex;
    padding: 0 1rem;
`;

export const Tab = styled.div<TabProps>`
    cursor: pointer;
    padding: 1rem;
    padding-bottom: 0.5rem;

    ${(props) =>
        props.$isSelected
            ? `
        border-bottom: solid 1px;
    `
            : ''}
`;
