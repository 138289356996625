import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerWrapper } from './DateInput.style';

type Prop = {
    name: string;
    date: Date | null;
    dateFormat?: string;
    isClearable?: boolean;
    onChange: (date: Date) => void;
};

const DateInput: React.FC<Prop> = (props) => {
    return (
        <DatePickerWrapper>
            <DatePicker
                selected={props.date ? new Date(props.date) : null}
                showPopperArrow={false}
                isClearable={props.isClearable}
                name={props.name}
                showMonthDropdown={true}
                onChange={props.onChange}
                dateFormat={props.dateFormat ?? 'dd.MM.yy'}
            />
        </DatePickerWrapper>
    );
};

export default DateInput;
