import { toast } from 'react-hot-toast';

// Ref. documentation: https://react-hot-toast.com/
export const useToast = () => {
    const toastSuccess = (text: string) => {
        toast.success(text);
    };
    const toastError = (text: string) => {
        toast.error(text);
    };

    const toastLoader = (loadingText: string, successText: string, promise: any) => {
        toast.promise(
            promise,
            {
                loading: loadingText,
                success: successText,
                error: 'Something went wrong, please try again.',
            },
            {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                success: {
                    duration: 6000,
                },
            },
        );
    };
    return { toastSuccess, toastError, toastLoader };
};
