import React from 'react';
import { ModalWrapper } from './ProfileModal.style';
import useAuth from 'auth/useAuth';

const ProfileModalComponent: React.FC = () => {
    const { logout } = useAuth();

    return (
        <>
            <ModalWrapper>
                <button onClick={logout}>Sign out</button>
            </ModalWrapper>
        </>
    );
};

export default ProfileModalComponent;
