import React from 'react';
import './SpinnerLoader.scss';
import styled from 'styled-components';

type Props = {
    showOverlay?: boolean;
    text?: string;
};

const OverlayLoader = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #535a5dcf;
    top: 0;
    z-index: 999;

    .loader-wrapper {
        text-align: center;
        margin-top: 25%;
    }

    .loader-text {
        color: #fff;
    }
`;

const SimpleLoader = styled.div`
    display: inline-block;
`;

const SpinnerLoaderComponent: React.FC<Props> = (props) => {
    return (
        <>
            {props.showOverlay && (
                <OverlayLoader>
                    <div className="loader-wrapper">
                        <div className="lds-ripple">
                            <div></div>
                            <div></div>
                        </div>
                        <div className="loader-text">{props.text ?? 'Loading'}</div>
                    </div>
                </OverlayLoader>
            )}
            {!props.showOverlay && (
                <SimpleLoader>
                    <div className="lds-ripple">
                        <div></div>
                        <div></div>
                    </div>
                    <div className="loader-text">{props.text ?? 'Loading'}</div>
                </SimpleLoader>
            )}
        </>
    );
};

export default SpinnerLoaderComponent;
