// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import './index.css';
import App from './App';
import { ThemeProvider } from 'styled-components';
import { createRoot } from 'react-dom/client';
import theme from 'theme';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>,
);
