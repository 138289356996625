import styled from 'styled-components';

export const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 50% 1fr;
    grid-gap: 2rem;
    padding: 0 1rem;
    overflow: hidden;
    height: 100%;
    margin-top: 20px;
`;

export const ListContentWrapper = styled.div`
    position: relative;
    min-height: 0;
    display: flex;
    flex-direction: column;
`;

export const ListWrapper = styled.div`
    overflow: auto;
`;

export const PortCallWrapper = styled.div`
    max-height: 43vh;
    overflow: auto;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
`;
