import styled from 'styled-components';

export const PortCallDetailsWrapper = styled.div`
    color: ${(props) => props.theme.colors.darkBlue};
`;

export const DetailsHeader = styled.h2`
    background: ${(props) => props.theme.colors.darkBlue};
    color: #00447e;
    padding: 0.25rem 0.5rem;
    font-size: 1.1rem;
    margin: 0;
    font-weight: 400;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
    background: #f4f4f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .port-call-dates-wrapper {
        float: right;
        width: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: flex-end;
        margin: 0;

        .port-call-date {
            display: flex;
        }
    }
`;

export const DetailsContent = styled.div`
    margin: 0.5rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
`;
