import { Table } from 'components/Syles';
import styled from 'styled-components';

export const CargoTable = styled(Table)`
    .vessel {
        width: 10rem;
    }

    .voyage {
        width: 4rem;
    }

    .cargo {
        width: 13rem;
    }

    .qty {
        width: 8rem;
    }

    .port {
        width: 9rem;
    }
`;
