import moment from 'moment';

export const filterByString = (list: any[], property: string, searchValue: string) =>
    list.filter((item) => item[property].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);

export const filterByNumber = (list: any[], property: string, searchValue: number) =>
    list.filter((item) => item[property].toString().includes(searchValue));

export const filterByDateFrom = (list: any[], propertyStart: string, propertyEnd: string, dateValue: Date) => {
    const dateM = moment(dateValue);
    return list.filter((item) =>
        item[propertyStart]
            ? // load date after dateM
              moment(item[propertyStart]).isSameOrAfter(dateM, 'day')
            : // if item has no load date, add it if unload date is after dateM
              item[propertyEnd] && moment(item[propertyEnd]).isSameOrAfter(dateM, 'day'),
    );
};

export const filterByDateTo = (list: any[], propertyStart: string, propertyEnd: string, dateValue: Date) => {
    const dateM = moment(dateValue);
    return list.filter((item) =>
        item[propertyEnd]
            ? // unload date before dateM
              moment(item[propertyEnd]).isSameOrBefore(dateM, 'day')
            : item[propertyStart] && moment(item[propertyStart]).isSameOrBefore(dateM, 'day'),
    );
};
