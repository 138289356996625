import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 1rem;
    h4 {
        margin-bottom: 0;
    }
`;

export const FiltersWrapper = styled.div`
    display: inline-block;
    background: #f4f4f4;
    padding: 1rem;
    padding-bottom: 0;
    border: solid 1px #ddd;
    box-shadow: 0 2px 6px -1px #ddd;
    margin-bottom: 0.5rem;
`;

export const FilterRow = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

export const DateRangeFilterWrapper = styled.div`
    display: flex;
    align-items: center;

    input {
        padding: 0.5rem;
        margin: 0 1rem;
    }
`;

export const EmissionDescription = styled.p`
    font-style: italic;
    font-size: 0.8rem;
    color: #00447ee0;
    margin-bottom: 2rem;
`;

export const PortDateCol = styled.div`
    display: grid;
    grid-template-columns: 7rem 1fr;
`;

export const TableWrapper = styled.div`
    height: calc(100vh - 25rem);
    overflow: auto;

    .checkbox-col {
        width: 2rem;
    }
`;

export const PaginatorWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    button {
        margin: 0 0.5rem;
    }
`;
