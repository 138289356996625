/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { Vessel } from 'types/ModelTypes';
import useApi from './useApi';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';
import { useCompaniesStore } from 'context/CompaniesContext';
// @ts-ignore
import htmlToPdfMake from 'html-to-pdfmake';

export default () => {
    const { state, getApiData } = useApi();
    const { selectedCompany } = useCompaniesStore();
    const [pdfMake, setPdfMake] = useState<any>();

    const downloadQ88Document = async (vessel: Vessel) => {
        getApiData({
            endpoint: ApiEndpoints.downloadQ88Document,
            queryParam: ApiQueryParams.downloadQ88Document(vessel.imoNumber, selectedCompany?.companyId),
            additionalInfo: vessel,
        });
    };

    const loadPdfMaker = async () => {
        let _pdfMake = { ...pdfMake };
        if (!pdfMake) {
            // @ts-ignore
            const pdfMakeModule = await import('pdfmake/build/pdfmake');
            // @ts-ignore
            const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
            _pdfMake = pdfMakeModule.default;
            _pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
            setPdfMake(_pdfMake);
        }
        return _pdfMake;
    };

    useEffect(() => {
        if (state.status === 'success') {
            const data = state.result as any;
            const htmlString = data.replace(/\t/g, '').replace(/\n/g, '');

            const loadPdfData = async () => await loadPdfMaker();

            loadPdfData().then((result) => {
                const htmlPdf = htmlToPdfMake(htmlString, {
                    tableAutoSize: true,
                    defaultStyles: {
                        table: { border: '1px solid grey' },
                        td: { fontSize: 9 },
                    },
                });

                const documentDefinition = {
                    content: [htmlPdf],
                    pageSize: 'A4',
                    pageMargins: [20, 20, 20, 20],
                };

                result.createPdf(documentDefinition).download(state.additionalInfo.vesselName + '-q88-document.pdf');
            });
        }
    }, [state]);

    return { downloadQ88Document };
};
