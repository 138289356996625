import { useAuthStateStore } from './AuthStateProvider';

export default () => {
    const { dispatch } = useAuthStateStore();

    const login = () => dispatch({ type: 'login' });
    const logout = () => dispatch({ type: 'logout' });
    const checkAuthStatus = () => dispatch({ type: 'login-silent' });

    return { login, logout, checkAuthStatus };
};
