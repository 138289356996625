import React from 'react';

interface iSettings {
    api: string;
    adGroupIdAdminUsers: string;
    adGroupIdOTUsers: string;
}

let AppSettingsContext: React.Context<iSettings>;
export class AppSettingsGetter {
    public settings: any;
    public async getAppSettings() {
        this.settings = await fetch('assets/appsetting.json')
            .then((r) => r.json())
            .then((r) => r as iSettings);
        AppSettingsContext = React.createContext(this.settings);
        return this.settings;
    }
}

const getContext = function () {
    return AppSettingsContext;
};
export default getContext;
