import { useEffect } from 'react';
import { ReportStatus, ReportType } from 'types/ModelTypes';
import { AxiosRequestConfig } from 'axios';
import useApi from './useApi';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';

export default () => {
    const { state, getApiData } = useApi();

    const downloadReport = async (companyId: number, reportStatus: ReportStatus) => {
        const headers = { 'Content-Type': 'blob' };
        const config = {
            responseType: 'arraybuffer',
            headers,
        };

        getApiData({
            endpoint: ApiEndpoints.downloadExcelReport,
            queryParam: ApiQueryParams.downloadExcelReport(companyId, reportStatus),
            config: config as AxiosRequestConfig,
            additionalInfo: reportStatus,
        });
    };

    useEffect(() => {
        if (state.status === 'success') {
            const data = state.result as any;
            const url = URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            let fileName = state.additionalInfo.reportType === ReportType.SCC ? 'voyage_emission_report_' : 'eu_ets_report_';
            fileName += `${state.additionalInfo.vesselName.toLowerCase().replace(' ', '_')}_${state.additionalInfo.bookingNumber}.xlsx`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }
    }, [state]);

    return { downloadReport };
};
