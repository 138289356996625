import React, { useState, useEffect } from 'react';
import SelectInput, { SelectInputOption } from 'components/select-input/SelectInput';
import { Wrapper } from './Paginator.style';
import { Pagination } from 'antd';

type props = {
    list: any[];
    setList: (list: any[]) => void;
};

const PaginatorComponent: React.FC<props> = (props) => {
    const [itemsPerPage, setItemsPerPage] = useState('25');
    const [currentPage] = useState<number>(1);
    const numberPerPageOptions = ['5', '10', '25', '50', '100'];

    useEffect(() => {
        if (!props.list) return;
        paginateList(currentPage);
    }, [props.list, itemsPerPage]);

    const onItemsPerPageChange = (event: any) => {
        const value = event.target.value;
        setItemsPerPage(value);
    };

    const handlePageChange = (page: any) => {
        paginateList(page);
    };

    const paginateList = (page: number) => {
        const startIndex = (page - 1) * +itemsPerPage;
        const endIndex = startIndex + +itemsPerPage;
        const paginatedList = props.list.slice(startIndex, endIndex);
        props.setList(paginatedList);
    };

    return (
        <>
            <Wrapper>
                <span>Items per page</span>
                <SelectInput
                    name="itemsPerPage"
                    options={numberPerPageOptions.map((o) => ({ label: o, value: o }) as SelectInputOption)}
                    onChange={onItemsPerPageChange}
                    value={itemsPerPage}
                />
                <Pagination
                    defaultCurrent={1}
                    total={props.list.length}
                    pageSize={+itemsPerPage}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            </Wrapper>
        </>
    );
};

export default PaginatorComponent;
