import React from 'react';
import BackgroundImage from 'components/BackgroundImage';
import { InfoWrapper, InfoItem } from './Home.style';
import { useCompaniesStore } from 'context/CompaniesContext';
import { useAuthStateStore } from 'auth/AuthStateProvider';
import { usePbiStore } from 'context/PBIContext';

const Home: React.FC = () => {
    const { userAccount } = useAuthStateStore();
    const { pbiAccessToken } = usePbiStore();
    const { selectedCompany } = useCompaniesStore();
    const isCustomer = userAccount && userAccount.role === 'external-user';
    const companyQueryParam = selectedCompany && !isCustomer ? `?companyId=${selectedCompany.companyId}` : '';

    const isPbiDisabled = !pbiAccessToken || pbiAccessToken.embedUrl === '-1' || selectedCompany?.companyId === -1;

    return (
        <>
            <BackgroundImage isFulleHeight={false} />
            <InfoWrapper>
                <InfoItem
                    to={{
                        pathname: `/eta`,
                        search: companyQueryParam,
                    }}
                >
                    <h2>ETA/Cargo</h2>
                    <div>
                        <h4>Where are my cargoes?</h4>
                        <h4>When will my cargo arrive?</h4>
                        <h4>Which ships are carrying my cargo?</h4>
                        <h4>Which cargoes are inbound the next weeks?</h4>
                    </div>
                </InfoItem>
                <InfoItem
                    $isdisabled={isPbiDisabled}
                    to={{
                        pathname: `/operations`,
                        search: companyQueryParam,
                    }}
                >
                    <h2>Port operations</h2>
                    <div>
                        <h4>At which times were my cargoes handled?</h4>
                        <h4>Which cargoes were handled at a specific port?</h4>
                        <h4>Were my cargoes handled within time limit?</h4>
                    </div>
                </InfoItem>
                <InfoItem
                    $isdisabled={isPbiDisabled}
                    to={{
                        pathname: `/statistics`,
                        search: companyQueryParam,
                    }}
                >
                    <h2>Statistics</h2>
                    <div>
                        <h4>How much has been loaded/unloaded?</h4>
                        <h4>How is the business with Odfjell going?</h4>
                    </div>
                </InfoItem>
                <InfoItem
                    to={{
                        pathname: `/data`,
                        search: companyQueryParam,
                    }}
                >
                    <h2>Data</h2>
                    <div>
                        <h4>How to download data files?</h4>
                        <h4>How to access Odfjell data via API?</h4>
                    </div>
                </InfoItem>
                <InfoItem
                    to={{
                        pathname: `/invoices`,
                        search: companyQueryParam,
                    }}
                >
                    <h2>Invoices</h2>
                    <div>
                        <h4>How many unpaid invoices do we have?</h4>
                        <h4>How much do we owe?</h4>
                    </div>
                </InfoItem>
                <InfoItem
                    to={{
                        pathname: `/emission`,
                        search: companyQueryParam,
                    }}
                >
                    <h2>Emission</h2>
                    <div>
                        <h4>Relevant Emission data.</h4>
                        <h4>Voyage Emission Reports</h4>
                    </div>
                </InfoItem>
            </InfoWrapper>
        </>
    );
};
export default Home;
