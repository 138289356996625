// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CustomerTitle from 'components/CustomerTitle';
import HeaderItem from './HeaderItem';
import UnpaidInvoicesComponent from './UnpaidInvoices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Wrapper,
    LogoWrapper,
    Logo,
    HeaderItemWrapper,
    ProfileWrapper,
    LoginWrapper,
    SubHeaderItemsWrapper,
    HeaderItemWithSubItems,
} from './Header.style';
import ProfileModalComponent from 'components/profile-modal/ProfileModal';
import SelectInput, { SelectInputOption } from 'components/select-input/SelectInput';
import useGetCompanies from 'api/useGetCompanies';
import { useAuthStateStore } from 'auth/AuthStateProvider';
import useAuth from 'auth/useAuth';
import { usePbiStore } from 'context/PBIContext';
import { useEtaStore } from 'context/EtaContext';
import { useCompaniesStore } from 'context/CompaniesContext';
import useGetUserCompany from 'api/useGetUserCompany';
import { useQueryParamsHandler } from 'odfjell-query-manager';
import { ReportType } from 'types/ModelTypes';

const HeaderComponent = () => {
    const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
    const [isEmissionTabsVisible, setIsEmissionTabsVisible] = useState(false);
    const { setFilteredCustomerVoyages } = useEtaStore();
    const { userAccount } = useAuthStateStore();
    const { companies, selectedCompany } = useCompaniesStore();
    const { pbiAccessToken } = usePbiStore();
    const { getCompanies } = useGetCompanies();
    const { getUserCompany } = useGetUserCompany();
    const { login } = useAuth();
    const { triggerQueryParamUpdate } = useQueryParamsHandler();
    const { search } = useLocation();
    const location = useLocation();

    const isOdfjellEmployee = () => userAccount && (userAccount.role === 'odfjell-employee' || userAccount.role === 'odfjell-admin');

    const isPbiDisabled = !pbiAccessToken || pbiAccessToken.embedUrl === '-1' || selectedCompany?.companyId === -1;

    const CustomerDetails = () => {
        if (userAccount) {
            let customerName = userAccount.customerName ? userAccount.customerName : '';

            if (isOdfjellEmployee() && selectedCompany) {
                customerName = selectedCompany.companyDisplayName;
            }

            return <CustomerTitle customerName={customerName} />;
        }
        return <></>;
    };

    const OdjfellAdmin = () => {
        if (userAccount && userAccount.role === 'odfjell-admin') {
            return <HeaderItem title={'Admin'} path={'admin'} />;
        }
        return <></>;
    };

    const CustomerAdmin = () => {
        if (userAccount && userAccount.role === 'external-admin') {
            return <HeaderItem title={'Admin'} path={'customerAdmin'} />;
        }
        return <></>;
    };

    const toggleProfileModalVisible = () => {
        setIsProfileModalVisible(!isProfileModalVisible);
    };

    const onCompanySelected = (event: any) => {
        setFilteredCustomerVoyages([]);

        const companyId = event.target.value;
        triggerQueryParamUpdate({ name: 'companyId', newValue: companyId }, 'clear-all');
    };

    useEffect(() => {
        if (isOdfjellEmployee() && (!companies || companies.length === 0)) {
            getCompanies();
        } else if (userAccount && !isOdfjellEmployee() && !selectedCompany) {
            getUserCompany();
        }
    }, [userAccount]);

    return (
        <>
            <Wrapper>
                <LogoWrapper>
                    <Link to={`/${search}`}>
                        <Logo src="../../../odfjell-logo.svg" alt="Odfjell" />
                    </Link>
                    <div>
                        {userAccount && isOdfjellEmployee() && (
                            <>
                                <SelectInput
                                    className="select-title"
                                    name="companySelect"
                                    options={companies.map(
                                        (o) =>
                                            ({
                                                label: o.companyDisplayName,
                                                value: o.companyId.toString(),
                                            }) as SelectInputOption,
                                    )}
                                    onChange={onCompanySelected}
                                    value={selectedCompany?.companyId.toString() ?? ''}
                                />
                            </>
                        )}
                        {!(userAccount && isOdfjellEmployee()) && <Link to={`/${search}`}>{CustomerDetails()}</Link>}
                    </div>
                </LogoWrapper>
                {userAccount && (
                    <HeaderItemWrapper>
                        <HeaderItem title={'eta/cargo'} path={'eta'} />
                        <HeaderItem title={'port operations'} path={'operations'} isDisabled={isPbiDisabled} />
                        <HeaderItem title={'statistics'} path={'statistics'} isDisabled={isPbiDisabled} />
                        {!isOdfjellEmployee() && <HeaderItem title={'emission'} path={'emission'} />}
                        {isOdfjellEmployee() && (
                            <HeaderItemWithSubItems
                                onMouseOver={() => setIsEmissionTabsVisible(true)}
                                onMouseLeave={() => setIsEmissionTabsVisible(false)}
                            >
                                <a className={location.pathname === `/emission` ? 'active-link' : ''}>Emission</a>
                                {isEmissionTabsVisible && (
                                    <SubHeaderItemsWrapper>
                                        <HeaderItem
                                            title={'SCC'}
                                            path={'emission'}
                                            disableActiveLink={true}
                                            queryParams={`reportType=${ReportType.SCC}`}
                                        />
                                        <HeaderItem
                                            title={'EU ETS'}
                                            path={'emission'}
                                            disableActiveLink={true}
                                            queryParams={`reportType=${ReportType.EUETS}`}
                                        />
                                    </SubHeaderItemsWrapper>
                                )}
                            </HeaderItemWithSubItems>
                        )}
                        <HeaderItem title={'data'} path={'data'} />
                        <HeaderItem title={'about'} path={'about'} />
                        {OdjfellAdmin()}
                        {CustomerAdmin()}
                        <UnpaidInvoicesComponent />
                        <ProfileWrapper onClick={toggleProfileModalVisible}>
                            <FontAwesomeIcon className="user-icon" icon="user" />
                        </ProfileWrapper>
                    </HeaderItemWrapper>
                )}
                {!userAccount && <LoginWrapper onClick={login}>Login | Sign Up</LoginWrapper>}
            </Wrapper>
            {isProfileModalVisible && <ProfileModalComponent></ProfileModalComponent>}
        </>
    );
};

export default HeaderComponent;
