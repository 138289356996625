import { useAppStateStore } from 'context/AppStateContext';
import { useEtaStore } from 'context/EtaContext';
import { QueryParamProps, QueryParamValueProps, useQueryParamsHandler } from 'odfjell-query-manager';
import { PortCall } from 'types/ModelTypes';

export const useHandleEtaItems = () => {
    const {
        setSelectedPortCall,
        selectedPortCall,
        setSelectedCustomerVoyage,
        selectedCustomerVoyage,
        setSelectedCargoOperation,
        selectedCargoOperation,
    } = useAppStateStore();
    const { customerVoyages, setCustomerVoyages } = useEtaStore();
    const { findQueryParamValue } = useQueryParamsHandler();

    const selectPortCall = (queryParamValue: QueryParamValueProps, allQueryParamValues: QueryParamValueProps[]) => {
        const portCallId = queryParamValue.newValue;
        if (!portCallId || queryParamValue.clearParam) {
            if (selectedPortCall) setSelectedPortCall(null);
            if (selectedCustomerVoyage) {
                setCustomerVoyages([...customerVoyages]);
                setSelectedCustomerVoyage(null);
            }
            if (selectedCargoOperation) setSelectedCargoOperation(null);
            return;
        }
        const customerVoyage = customerVoyages.find((c) => c.portCalls.some((p) => p.idPortCall === +portCallId));
        const portCall = customerVoyage?.portCalls.find((p) => p.idPortCall === +portCallId);
        if (portCall) setSelectedPortCall({ ...portCall });
        if (customerVoyage) setSelectedCustomerVoyage({ ...customerVoyage });

        if (allQueryParamValues.length > 0) {
            // const cargoOperationParam = allQueryParamValues.find((p) => p.name === 'cargoOperationId');
            const cargoOperationParam = findQueryParamValue('cargoOperationId');
            if (cargoOperationParam?.newValue) {
                selectCargoOperation(cargoOperationParam.newValue, portCall);
            }
        } else if (selectedCargoOperation) {
            setSelectedCargoOperation(null);
        }
    };

    const selectCargoOperation = (cargoOperationId: string | null | undefined, portCall?: PortCall | null) => {
        const _portCall = portCall ?? selectedPortCall;
        if (!_portCall || !cargoOperationId) return;
        const cargoOperation = _portCall.cargoOperations.find((c) => c.idCargoOperation === +cargoOperationId);

        if (!cargoOperation) return;

        setSelectedCargoOperation({ ...cargoOperation });
    };

    const handleEtaPage = (queryParamUpdate: QueryParamProps) => {
        queryParamUpdate.queryParamValues.forEach((queryParamValue) => {
            if (queryParamValue.name === 'portCallId') selectPortCall(queryParamValue, queryParamUpdate.queryParamValues);
            if (queryParamValue.name === 'cargoOperationId') selectCargoOperation(queryParamValue.newValue);
        });
    };

    return { handleEtaPage };
};
