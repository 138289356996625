import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInvoicesStore } from 'context/InvoicesContext';
import React, { useEffect, useState } from 'react';
import { UnpaidInvoice } from 'types/ModelTypes';
import { prettifyNumberDate } from 'utils/renderTextHelper';
import { InvoicesTable, Wrapper } from './Invoices.style';

type SortColumn = 'voyage' | 'invoiceNumber' | 'dateInvoice' | 'dateDueInvoice' | 'overdueDays' | 'amountInUsd' | 'invoiceTypeCode';

const InvoicesPage: React.FC = () => {
    const { unpaidInvoices } = useInvoicesStore();
    const [sortedUnpaidInvoices, setSortedUnpaidInvoices] = useState<UnpaidInvoice[]>([]);
    const [sortColumn, setSortColumn] = useState<SortColumn>('overdueDays');
    const [sortDesc, setSortDesc] = useState(false);

    useEffect(() => {
        if (!unpaidInvoices) return;

        sortByColumn(sortColumn);
    }, [unpaidInvoices]);

    const stringSortValue = (a: string, b: string) => {
        if (sortDesc) {
            return a > b ? 1 : -1;
        }
        return a > b ? -1 : 1;
    };

    const numberSortValue = (a: number, b: number) => (sortDesc ? a - b : b - a);

    const sortByColumn = (sortColumn: SortColumn) => {
        let invoices = [...unpaidInvoices];

        if (sortColumn === 'voyage') {
            invoices = invoices.sort((a, b) => stringSortValue(a.voyage, b.voyage));
        }
        if (sortColumn === 'invoiceTypeCode') {
            invoices = invoices.sort((a, b) => stringSortValue(a.invoiceTypeCode, b.invoiceTypeCode));
        }
        if (sortColumn === 'invoiceNumber') {
            invoices = invoices.sort((a, b) => stringSortValue(a.invoiceNumber, b.invoiceNumber));
        }
        if (sortColumn === 'dateInvoice') {
            invoices = invoices.sort((a, b) => numberSortValue(a.dateInvoice, b.dateInvoice));
        }
        if (sortColumn === 'dateDueInvoice') {
            invoices = invoices.sort((a, b) => numberSortValue(a.dateDueInvoice, b.dateDueInvoice));
        }
        if (sortColumn === 'overdueDays') {
            invoices = invoices.sort((a, b) => numberSortValue(a.overdueDays, b.overdueDays));
        }
        if (sortColumn === 'amountInUsd') {
            invoices = invoices.sort((a, b) => numberSortValue(a.amountInUsd, b.amountInUsd));
        }

        setSortColumn(sortColumn);
        setSortedUnpaidInvoices([...invoices]);
        setSortDesc(!sortDesc);
    };

    const SortIcon = (column: SortColumn) => {
        if (column !== sortColumn) return <></>;

        return sortDesc ? <FontAwesomeIcon icon="sort-down" /> : <FontAwesomeIcon icon="sort-up" />;
    };

    const TH = (title: string, sortColumn: SortColumn, className?: string) => (
        <th title="Sort" onClick={() => sortByColumn(sortColumn)} className={className ?? ''}>
            {title} {SortIcon(sortColumn)}
        </th>
    );

    return (
        <Wrapper>
            <h3>Unpaid invoices</h3>
            <div>
                <InvoicesTable>
                    <thead>
                        <tr>
                            {TH('Voyage', 'voyage')}
                            {TH('Type', 'invoiceTypeCode')}
                            {TH('Invoice number', 'invoiceNumber')}
                            {TH('Invoice date', 'dateInvoice')}
                            {TH('Due date', 'dateDueInvoice')}
                            {TH('Overdue days', 'overdueDays')}
                            {TH('Amount', 'amountInUsd', 'amount')}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedUnpaidInvoices.map((invoice, i) => (
                            <tr key={`invoice_${invoice.unpaidInvoicesId}_${i}`}>
                                <td>{invoice.voyage}</td>
                                <td>{invoice.invoiceTypeCode}</td>
                                <td>{invoice.invoiceNumber}</td>
                                <td>{prettifyNumberDate(invoice.dateInvoice)}</td>
                                <td>{prettifyNumberDate(invoice.dateDueInvoice)}</td>
                                <td>{invoice.overdueDays}</td>
                                <td className={'amount'}>
                                    <span>{Number(invoice.amountInUsd.toFixed(0)).toLocaleString()}</span> USD
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </InvoicesTable>
            </div>
        </Wrapper>
    );
};

export default InvoicesPage;
