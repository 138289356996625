import * as Msal from '@azure/msal-browser';
import Axios from 'axios';
import getContext from 'context/AppSettingsContext';
import { useContext, useEffect } from 'react';
import { AuthRole, UserAccount } from 'types/Types';
import { B2CAuthConfig, B2CScopes, ResetPasswordConfig } from './AuthConfig';
import { useAuthStateStore } from './AuthStateProvider';
import { Client } from '@microsoft/microsoft-graph-client';
import { useLocation } from 'react-router-dom';

type IdTokenClaims = {
    iss?: string;
    oid?: string;
    sub?: string;
    tid?: string;
    ver?: string;
    upn?: string;
    preferred_username?: string;
    name?: string;
    nonce?: string;
    exp?: string;
    home_oid?: string;
    sid?: string;
    cloud_instance_host_name?: string;
    customerName?: string;
    role?: string;
    idp_access_token?: string;
};

export default () => {
    const b2cMsalObj = new Msal.PublicClientApplication(B2CAuthConfig);
    const { state, dispatch, setUserAccount, userAccount } = useAuthStateStore();
    const appSettings = useContext(getContext());
    const odfjellAdTennantId = '4309616f-0474-48d6-8ecc-d2abec18a877'; // TODO: Secure this
    const b2cTennantId = 'f62ba696-5202-492d-a72e-3cc26e6dda5a';
    const isOdfjellAccount = (tennantId: string) => odfjellAdTennantId === tennantId;
    const location = useLocation();

    useEffect(() => {
        if (state.status === 'authorizing') {
            if (state.type === 'login') {
                const locationState = location.state as any;
                const pathname = locationState?.from?.pathname ?? '';
                const search = locationState?.from?.search ?? '';
                const redirectState = `${pathname}${search}`;
                b2cMsalObj.loginRedirect({
                    scopes: B2CScopes,
                    state: redirectState,
                });
            }
            if (state.type === 'login-silent') {
                checkAuthStatus();
            }
        }
        if (state.type === 'logout' || (state.type === 'login-silent' && state.status === 'unauthorized' && !state.isAuthorizing && userAccount)) {
            logoutB2C();
        }
    }, [state]);

    useEffect(() => {
        b2cMsalObj
            .handleRedirectPromise()
            .then((response) => {
                if (response) {
                    const idTokenClaims = response.idTokenClaims as any;

                    // Password has been reset successfully
                    if (idTokenClaims.acr && idTokenClaims.acr === 'b2c_1a_passwordreset') {
                        localStorage.setItem('b2cDetails', 'password_reset');
                        dispatch({ type: 'logout' });
                    }
                    // User has signed up but not been aproved
                    else if (!idTokenClaims.role && response.tenantId === b2cTennantId) {
                        localStorage.setItem('b2cDetails', 'sign_up_requested');
                        dispatch({ type: 'logout' });
                    } else {
                        setUser(response);
                        dispatch({ type: 'login_successful', result: response });
                    }
                }
            })
            .catch((error: Msal.AuthError) => {
                // User has clicked forgot password
                if (error.errorMessage.indexOf('AADB2C90118') !== -1) {
                    b2cMsalObj.loginRedirect({
                        authority: ResetPasswordConfig.authority,
                        scopes: B2CScopes,
                    });
                } else {
                    dispatch({ type: 'login_failed', error: error });
                }
            });
    }, []);

    const checkAuthStatus = async () => {
        const accounts = b2cMsalObj.getAllAccounts();
        if (accounts && accounts.length > 0 && B2CAuthConfig.auth) {
            const account = accounts[0];

            try {
                b2cMsalObj
                    .acquireTokenSilent({
                        account,
                        scopes: B2CScopes,
                        authority: B2CAuthConfig.auth.authority,
                    })
                    .then(async (result) => {
                        if (result) {
                            await setUser(result);
                            dispatch({ type: 'login_silent_successful', result });
                        } else {
                            dispatch({ type: 'login_silent_failed', error: null });
                        }
                    });
            } catch (error) {
                const signedInAccounts = b2cMsalObj.getAllAccounts();
                if (signedInAccounts && signedInAccounts.length > 0) {
                    logoutB2C();
                }
                dispatch({ type: 'login_silent_failed', error });
            }
        } else {
            dispatch({ type: 'login_silent_failed', error: null });
        }
    };

    const logoutB2C = () => {
        b2cMsalObj.logout().then(() => {
            if (userAccount) {
                setUserAccount(null);
            }
            if (userAccount && userAccount.role === 'odfjell-employee') {
                // TODO: Logout of office 365. There are issues signing back in, get back to this
                localStorage.clear();
                sessionStorage.clear();
                // window.location.href = 'https://login.microsoftonline.com/odfjell.onmicrosoft.com/oauth2/v2.0/logout?post_logout_redirect_uri='
            }
        });
    };

    const setUser = async (authenticationResult: Msal.AuthenticationResult) => {
        const idTokenClaims = authenticationResult.idTokenClaims as IdTokenClaims;
        const isOdfjellEmployee = isOdfjellAccount(authenticationResult.tenantId);
        const authRole = idTokenClaims.role ? (idTokenClaims.role as AuthRole) : ('customer' as AuthRole);
        const userAccount: UserAccount = {
            userName: idTokenClaims.name ? idTokenClaims.name : '',
            customerName: idTokenClaims.customerName ? idTokenClaims.customerName : '',
            role: isOdfjellEmployee ? 'odfjell-employee' : authRole,
            urlFrom: authenticationResult.state ?? undefined,
        };

        let accessToken = authenticationResult.accessToken;
        if (isOdfjellEmployee) {
            const idTokenClaims = authenticationResult.idTokenClaims as any;
            const isOdfjellAdmin = await checkOTMemberGroups(idTokenClaims.idp_access_token ?? '');
            if (isOdfjellAdmin) {
                userAccount.role = 'odfjell-admin';
            }
            accessToken = idTokenClaims.idp_access_token;
        }
        interceptAxiosRequest(accessToken);
        setUserAccount(userAccount);
    };

    const interceptAxiosRequest = (accessToken: string) => {
        Axios.interceptors.request.use((config: any) => {
            const headers = { Authorization: `Bearer ${accessToken}` };
            config.headers = headers;
            return config;
        });

        Axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response.status === 401) {
                    logoutB2C();
                }
                return error;
            },
        );
    };

    const checkOTMemberGroups = async (accessToken: string) => {
        const client = getClient(accessToken);
        let isOdfjellAdmin = false;
        await client
            .api('me/checkMemberGroups')
            .post({
                groupIds: [appSettings.adGroupIdAdminUsers],
            })
            .then((res) => {
                if (res.value.length > 0) {
                    isOdfjellAdmin = true;
                }
                return res;
            })
            .catch(() => {
                logoutB2C();
            });

        return isOdfjellAdmin;
    };

    const getClient = (accessToken: string) => {
        const client = Client.init({
            authProvider: (done) => {
                done(null, accessToken);
            },
        });
        return client;
    };

    return {};
};
