import React, { useContext } from 'react';
import { ContextProps } from 'types/Types';
import { PbiResponse } from 'types/ModelTypes';

type PBIContextType = {
    pbiAccessToken: PbiResponse | null;
    setPbiAccessToken: (pbiAccessToken: PbiResponse | null) => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const PBIContext = React.createContext<PBIContextType>(undefined!);

export const PBIContextProvider = ({ children }: ContextProps) => {
    const [pbiAccessToken, setPbiAccessToken] = React.useState<PbiResponse | null>(null);

    return (
        <PBIContext.Provider
            value={{
                pbiAccessToken,
                setPbiAccessToken,
            }}
        >
            {children}
        </PBIContext.Provider>
    );
};

export const usePbiStore = () => useContext(PBIContext);
