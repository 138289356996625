import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    operationType: string;
};

const Symbol = styled.div`
    display: inline-block;

    .loading {
        color: #0d6323;
        transform: rotate(180deg);
    }

    .unloading {
        color: #7b2626db;
    }
`;

const CargoOperationSymbolComponent: React.FC<Props> = ({ operationType }: Props) => {
    const operationSymbol = () => {
        if (operationType === 'Loading')
            return (
                <Symbol>
                    <FontAwesomeIcon className="loading" icon="sign-in-alt" />
                </Symbol>
            );
        if (operationType === 'Unloading')
            return (
                <Symbol>
                    <FontAwesomeIcon className="unloading" icon="sign-out-alt" />
                </Symbol>
            );
        return <></>;
    };

    return <>{operationSymbol()}</>;
};

export default CargoOperationSymbolComponent;
