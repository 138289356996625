import { useEffect } from 'react';
import { VesselPosition, Vessel, Voyage } from 'types/ModelTypes';
import useApi from './useApi';
import { GetIsoDate } from 'utils/dateHelper';
import { useEtaStore } from 'context/EtaContext';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';
import { useCompaniesStore } from 'context/CompaniesContext';

export default () => {
    const { state, getApiData } = useApi();
    const { setPortCallVesselPositions } = useEtaStore();
    const { selectedCompany } = useCompaniesStore();

    const getPortCallVesselPositions = (vessel: Vessel, voyage: Voyage) => {
        const from = GetIsoDate(voyage.voyageStartingDateUtc);
        const to = GetIsoDate(voyage.voyageEndingDateUtc);
        getApiData({
            endpoint: ApiEndpoints.getVesselPositions,
            queryParam: ApiQueryParams.getVesselPositions(vessel.imoNumber.toString(), from, to, selectedCompany?.companyId),
        });
    };

    useEffect(() => {
        if (state.status === 'success') {
            const vesselPositions = state.result as VesselPosition[];

            setPortCallVesselPositions(vesselPositions);
        }
    }, [state]);

    return { state, getPortCallVesselPositions };
};
