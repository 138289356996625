import { Table } from 'components/Syles';
import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 1rem;
`;

export const InvoicesTable = styled(Table)`
    tr {
        cursor: unset !important;

        &:hover {
            background: unset !important;
        }
    }

    th {
        cursor: pointer;

        svg {
            margin-left: 0.5rem;
        }
    }

    .amount {
        span {
            width: 3.5rem;
            display: inline-block;
            text-align: left;
        }
    }
`;
