import styled from 'styled-components';

type MapProps = {
    $mapHeight: string;
};

export const MapContainer = styled.div<MapProps>`
    position: relative;
    height: 100%;

    .marker-info {
        color: #004781;
        padding: 0;

        p {
            margin: 0.5rem 0;

            label {
                margin-right: 0.25rem;
                font-weight: 500;
            }
        }
    }

    #cargoMap {
        width: 100%;
        height: ${(props) => (props.$mapHeight ? props.$mapHeight : '100%')};
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .warning-wrapper {
        width: 100%;
        height: 100%;
        background: #e4e4e4ed;
        position: absolute;
        top: 0;
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;
        display: flex;
        justify-content: center;

        span {
            padding-top: 10rem;
        }
    }
`;

export const MapSpinnerWrapper = styled.div`
    z-index: 10;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffff96;

    .map-spinner {
        margin-top: 40%;
        margin-left: 50%;
    }
`;
