import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { PortCall, CargoOperation, CustomerVoyage, Vessel } from 'types/ModelTypes';
import { prettifyDate } from 'utils/renderTextHelper';
import SortIconComponent from '../SortIcon';
import VesselCargoOperation from '../vessel-list/VesselCargoOperation';
import { CargoTable } from './CargoList.style';
import { useEtaStore } from 'context/EtaContext';
import { useAppStateStore } from 'context/AppStateContext';
import { useQueryParamsHandler } from 'odfjell-query-manager';
import useDownloadQ88Document from 'api/useDownloadQ88Document';
import { Q88DocumentButton } from 'components/vessel-list/VesselList.style';

type CargoItem = {
    customerVoyage: CustomerVoyage;
    portCall: PortCall;
    cargoOperation: CargoOperation;
};

type SortColumn = 'vessel' | 'voyage' | 'cargo' | 'qty' | 'port' | 'date';

const CargoListComponent: React.FC = () => {
    const [cargoItems, setCargoItems] = useState<CargoItem[]>([]);
    const [sortColumn, setSortColumn] = useState<SortColumn>('date');
    const [isSortDesc, setIsSortDesc] = useState<boolean>(false);
    const { filteredCustomerVoyages } = useEtaStore();
    const { selectedCargoOperation, selectedPortCall } = useAppStateStore();
    const { triggerQueryParamsUpdate } = useQueryParamsHandler();
    const { downloadQ88Document } = useDownloadQ88Document();

    const showTrack = (cargoItem: CargoItem) => {
        triggerQueryParamsUpdate([
            {
                name: 'portCallId',
                newValue: cargoItem.portCall.idPortCall.toString(),
            },
            {
                name: 'cargoOperationId',
                newValue: cargoItem.cargoOperation.idCargoOperation.toString(),
            },
        ]);
    };

    useEffect(() => {
        let cargoList = filteredCustomerVoyages.flatMap((customerVoyage) =>
            customerVoyage.portCalls.flatMap((portCall) =>
                portCall.cargoOperations.map((cargoOperation) => {
                    const cargoItem: CargoItem = { customerVoyage, portCall, cargoOperation };
                    return cargoItem;
                }),
            ),
        );

        cargoList = getSortedCargoItems(sortColumn, isSortDesc, cargoList);
        setCargoItems(cargoList);
    }, filteredCustomerVoyages);

    const getSortedCargoItems = (column: SortColumn, sortDesc: boolean, items: CargoItem[]): CargoItem[] => {
        let sortedCargoItems = [...items];

        if (column === 'vessel') {
            sortedCargoItems = sortedCargoItems.sort((a, b) =>
                sortDesc
                    ? a.customerVoyage.vessel.vesselName.toLowerCase() > b.customerVoyage.vessel.vesselName.toLowerCase()
                        ? 1
                        : -1
                    : a.customerVoyage.vessel.vesselName.toLowerCase() < b.customerVoyage.vessel.vesselName.toLowerCase()
                      ? 1
                      : -1,
            );
        }
        if (column === 'voyage') {
            sortedCargoItems = sortedCargoItems.sort((a, b) =>
                sortDesc
                    ? a.customerVoyage.voyage.voyageNumber > b.customerVoyage.voyage.voyageNumber
                        ? 1
                        : -1
                    : a.customerVoyage.voyage.voyageNumber < b.customerVoyage.voyage.voyageNumber
                      ? 1
                      : -1,
            );
        }
        if (column === 'cargo') {
            sortedCargoItems = sortedCargoItems.sort((a, b) =>
                sortDesc
                    ? a.cargoOperation.tradeName.toLowerCase() > b.cargoOperation.tradeName.toLowerCase()
                        ? 1
                        : -1
                    : a.cargoOperation.tradeName.toLowerCase() < b.cargoOperation.tradeName.toLowerCase()
                      ? 1
                      : -1,
            );
        }
        if (column === 'port') {
            sortedCargoItems = sortedCargoItems.sort((a, b) =>
                sortDesc
                    ? a.portCall.portName.toLowerCase() > b.portCall.portName.toLowerCase()
                        ? 1
                        : -1
                    : a.portCall.portName.toLowerCase() < b.portCall.portName.toLowerCase()
                      ? 1
                      : -1,
            );
        }
        if (column === 'date') {
            sortedCargoItems = sortedCargoItems.sort((a, b) => {
                const aArrival = a.portCall.operatorEta ? a.portCall.operatorEta.etaFromDateUtc : a.portCall.portArrivalDateUTC;
                const bArrival = b.portCall.operatorEta ? b.portCall.operatorEta.etaFromDateUtc : b.portCall.portArrivalDateUTC;

                if (sortDesc) {
                    return aArrival < bArrival ? 1 : -1;
                }

                return aArrival > bArrival ? 1 : -1;
            });
        }

        return sortedCargoItems;
    };

    const sortByColumn = (column: SortColumn) => {
        const sortDesc = !isSortDesc;
        const sortedCargoItems = getSortedCargoItems(column, sortDesc, cargoItems);

        setSortColumn(column);
        setCargoItems(sortedCargoItems);
        setIsSortDesc(sortDesc);
    };

    const getPortCallDate = (portCall: PortCall) => {
        if (portCall.operatorEta) {
            const etaFrom = portCall.operatorEta.etaFromDateUtc;
            const etaTo = portCall.operatorEta.etaToDateUtc;
            const etdFrom = portCall.operatorEta.etdFromDateUtc;
            const etdTo = portCall.operatorEta.etdToDateUtc;

            let arrivalString = '';
            let departureString = '';

            if (!etaFrom) return '';

            if (moment(etaFrom).isSame(etaTo, 'day')) {
                arrivalString = prettifyDate(etaFrom);
            } else {
                arrivalString = `(${prettifyDate(etaFrom)}-${prettifyDate(etaTo)})`;
            }

            if (etdFrom) {
                if (moment(etdFrom).isSame(etdTo, 'day')) {
                    departureString = prettifyDate(etdFrom);
                } else {
                    departureString = `(${prettifyDate(etdFrom)}-${prettifyDate(etdTo)})`;
                }

                return arrivalString + ' - ' + departureString;
            }

            return arrivalString;
        }

        return '';
    };

    const onQ88DownloadClick = (vessel: Vessel) => {
        downloadQ88Document(vessel);
    };

    return (
        <>
            <CargoTable>
                <thead>
                    <tr>
                        <th className="sortable-th vessel" onClick={() => sortByColumn('vessel')} title="Order by vessel name">
                            Vessel
                            {sortColumn === 'vessel' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th></th>
                        <th className="sortable-th voyage" onClick={() => sortByColumn('voyage')} title="Order by voyage number">
                            Voyage
                            {sortColumn === 'voyage' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th className="sortable-th cargo" onClick={() => sortByColumn('cargo')} title="Order by cargo name">
                            Cargo
                            {sortColumn === 'cargo' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th className="sortable-th qty" onClick={() => sortByColumn('qty')} title="Order by quantity">
                            Quantity
                            {sortColumn === 'qty' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th className="sortable-th port" onClick={() => sortByColumn('port')} title="Order by port name">
                            Port
                            {sortColumn === 'port' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                        <th className="sortable-th" onClick={() => sortByColumn('date')} title="Order by arrival date">
                            Date
                            {sortColumn === 'date' && <SortIconComponent sortDesc={isSortDesc} />}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {cargoItems.map((cargoItem, i) => {
                        return (
                            <tr
                                key={i}
                                onClick={() => showTrack(cargoItem)}
                                className={
                                    selectedCargoOperation?.idCargoOperation === cargoItem.cargoOperation.idCargoOperation &&
                                    selectedPortCall?.idPortCall === cargoItem.portCall.idPortCall
                                        ? 'active-item'
                                        : ''
                                }
                            >
                                <td className="vessel">{cargoItem.customerVoyage.vessel.vesselName}</td>
                                <td>
                                    <Q88DocumentButton
                                        title="Download Q88 document"
                                        onClick={() => onQ88DownloadClick(cargoItem.customerVoyage.vessel)}
                                    >
                                        Q88 Document
                                    </Q88DocumentButton>
                                </td>
                                <td className="voyage">{cargoItem.customerVoyage.voyage.voyageNumber}</td>
                                <td className="cargo" title={cargoItem.cargoOperation.operationType}>
                                    {cargoItem.cargoOperation.tradeName}
                                </td>
                                <td className="qty">
                                    <VesselCargoOperation cargoOperation={cargoItem.cargoOperation} width={'5rem'} />
                                </td>
                                <td className="port">{cargoItem.portCall.portName}</td>
                                <td>{getPortCallDate(cargoItem.portCall)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </CargoTable>
        </>
    );
};

export default CargoListComponent;
