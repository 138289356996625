import styled from 'styled-components';

export const Wrapper = styled.nav`
    padding: 0 2rem;
    display: grid;
    grid-template-columns: 40% 1fr;
    align-items: center;
    box-shadow: 0 1px 7px -2px #ccc;
    height: ${(props) => props.theme.headerHeight};
`;

export const HeaderItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Logo = styled.img`
    width: 6rem;
`;

export const LogoWrapper = styled.div`
    display: flex;
    align-items: baseline;

    img {
        margin-right: 2rem;
    }

    button {
        margin: 0 0.5rem;
    }
`;

export const ProfileWrapper = styled.div`
    cursor: pointer;
    text-align: center;
    margin-right: 2rem;
    cursor: pointer;
    text-align: center;
    margin-right: 2rem;
    border: 1px solid;
    border-radius: 50%;
    padding: 10px 15px;

    &:hover {
        color: ${(props) => props.theme.colors.orange};
    }
`;

export const LoginWrapper = styled.div`
    cursor: pointer;
    justify-self: right;
    border: solid 1px;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: 500;
`;

export const HeaderItemWithSubItems = styled.div`
    position: relative;

    .active-link {
        border-bottom: solid 1px;
    }

    a {
        cursor: pointer;
    }
`;

export const SubHeaderItemsWrapper = styled.div`
    position: absolute;
    z-index: 999;
    background: #fff;
    padding: 1rem;
    width: 6rem;
    border-radius: 5px;
    right: -3rem;

    a {
        &:hover {
            font-weight: 500;
        }
    }
`;
