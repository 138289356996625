import { LatLng, GoogleMapsVesselIcon } from 'types/Types';

const DEFAULT_COLOR = '#f36f33';
const CENTER_COORDS = { lat: 10, lng: 5 };
const MIN_ZOOM = 2;
const MAX_ZOOM = 18;

export const InitMap = (mapId: string): google.maps.Map => {
    const cargoMapElement = document.getElementById(mapId) as HTMLElement;

    const map = new google.maps.Map(cargoMapElement, {
        maxZoom: MAX_ZOOM,
        minZoom: MIN_ZOOM,
        zoom: MIN_ZOOM,
        center: CENTER_COORDS,
        mapTypeControl: true,
        mapTypeControlOptions: {
            mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE],
        },
        streetViewControl: false,
        zoomControl: false,
    });

    addBoundsChangedListener(map);

    return map;
};

export const GetPolyline = (map: google.maps.Map, latLngs: LatLng[], strokeColor?: string): google.maps.Polyline | undefined => {
    if (!latLngs) {
        return;
    }

    if (!strokeColor) {
        strokeColor = DEFAULT_COLOR;
    }
    return new google.maps.Polyline({
        map: map,
        path: latLngs,
        geodesic: true,
        strokeColor: strokeColor,
        strokeOpacity: 1.0,
        strokeWeight: 2,
    });
};

export const GetLocationMarker = (map: google.maps.Map, latLng: LatLng, course: number, color?: string) => {
    const locationMarkerIcon = getVesselIcon(course, color);
    const locationMarker = GetMarker(map, latLng, locationMarkerIcon);

    return locationMarker;
};

export const GetMarker = (map: google.maps.Map, latLng: LatLng, icon?: GoogleMapsVesselIcon, label?: string, title?: string) =>
    new google.maps.Marker({
        position: latLng,
        map: map,
        icon: icon,
        label: label,
        title: title,
    });

export const SetMapOnAll = (map: google.maps.Map | null, elements: any) => {
    if (!elements) return;

    if (Array.isArray(elements)) {
        elements.forEach((element) => element.setMap(map));
    } else {
        elements.setMap(map);
    }
};

export const DrawLocationMarkers = (locationMarkers: google.maps.Marker[], map: google.maps.Map) => {
    const visibleMarkers = [];

    if (!map) return;

    const scale = 1 << map.getZoom();
    const mapBounds = map.getBounds();

    if (!mapBounds || !locationMarkers) {
        return;
    }

    for (let i = 0; i < locationMarkers.length; i++) {
        const locationMarker = locationMarkers[i];
        const locationMarkerMap = locationMarker.getMap();
        const locationMarkerPosition = locationMarker.getPosition();

        if (!locationMarkerPosition) {
            continue;
        }

        if (!mapBounds.contains(locationMarkerPosition)) {
            if (locationMarkerMap) {
                locationMarker.setMap(null);
            }
            continue;
        }
        const mapProjection = map.getProjection();

        if (!mapProjection) continue;

        const locationMarkerPoint = mapProjection.fromLatLngToPoint(locationMarkerPosition);
        const radius = 10 / scale;

        const isMarkerOvelappingExisitingMarker = isMarkerOvelappingAnotherMarker(visibleMarkers, locationMarkerPoint, radius, mapProjection);

        if (!isMarkerOvelappingExisitingMarker) {
            if (!locationMarkerMap) {
                locationMarker.setMap(map);
            }

            visibleMarkers.push(locationMarker);
        } else {
            if (locationMarkerMap) {
                locationMarker.setMap(null);
            }
        }
    }

    return visibleMarkers;
};

export const MoveToLocation = (map: google.maps.Map, coordinate: LatLng, zoomLevel?: number) => {
    if (!zoomLevel) {
        zoomLevel = 12;
    }
    const location = new google.maps.LatLng(coordinate.lat, coordinate.lng);

    map.panTo(location);
    map.setZoom(zoomLevel);
};

export const FitBounds = (map: google.maps.Map, markers: google.maps.Marker[]) => {
    const bounds = new google.maps.LatLngBounds();

    if (!markers) return;
    markers.forEach((marker) => {
        const position = marker.getPosition();
        if (position) {
            bounds.extend(position);
        }
    });

    map.fitBounds(bounds);
};

export const CenterMap = (map: google.maps.Map) => {
    map.setCenter(CENTER_COORDS);
    map.setZoom(MIN_ZOOM);
};

export const getCurrentLocationMarker = (map: google.maps.Map, coordinate: LatLng) => {
    const locationMarker = GetMarker(map, coordinate);

    return locationMarker;
};

const isMarkerOvelappingAnotherMarker = (
    existingMarkers: google.maps.Marker[],
    markerPoint: google.maps.Point,
    radius: number,
    mapProjection: google.maps.Projection,
) => {
    for (let i = 0; i < existingMarkers.length; i++) {
        const existingMarkerLatLng = existingMarkers[i].getPosition();

        if (!existingMarkerLatLng) continue;

        const existingMarkerProjection = mapProjection.fromLatLngToPoint(existingMarkerLatLng);
        const locationOverlapps = isPointInRadius(markerPoint.x, markerPoint.y, existingMarkerProjection.x, existingMarkerProjection.y, radius);

        if (locationOverlapps) {
            return true;
        }
    }

    return false;
};

const isPointInRadius = (originX: number, originY: number, pointX: number, pointY: number, radius: number) => {
    const dist_points = (originX - pointX) * (originX - pointX) + (originY - pointY) * (originY - pointY);
    radius *= radius;
    if (dist_points < radius) {
        return true;
    }
    return false;
};

const addBoundsChangedListener = (map: google.maps.Map) => {
    map.addListener('bounds_changed', () => {
        const maxZoomLevel = MAX_ZOOM;
        const minZoomLevel = MIN_ZOOM;
        const currentZoom = map.getZoom();

        if (currentZoom > maxZoomLevel) {
            map.setZoom(maxZoomLevel);
        } else if (currentZoom < minZoomLevel) {
            map.setZoom(minZoomLevel);
        }
    });
};

const getVesselIcon = (rotation?: number, color?: string): GoogleMapsVesselIcon => {
    const path = 'M 400 200 L 500 400 L 400 350 L 300 400 L 400 200 ';
    color = color ? color : DEFAULT_COLOR;

    const icon = {
        path: path,
        rotation,
        fillColor: color,
        fillOpacity: 1,
        strokeColor: color,
        strokeWeight: 1,
        scale: 0.05,
        labelOrigin: new google.maps.Point(0, -25),
        anchor: new google.maps.Point(400, 350),
    };

    if (rotation) {
        icon.rotation = rotation;
    }

    return icon;
};

/*
        static drawLocationMarkers(locationMarkers, map: google.maps.Map) {
            const visibleMarkers = [];
            const scale = 1 << map.getZoom();
            const mapBounds = map.getBounds();
        
            if (!mapBounds || !locationMarkers) {
              return;
            }
        
            for (let i = 0; i < locationMarkers.length - 1; i++) {
              const locationMarker = locationMarkers[i];
              const locationMarkerMap = locationMarker.getMap();
        
              if (!mapBounds.contains(locationMarker.getPosition())) {
                if (locationMarkerMap) {
                  locationMarker.setMap(null);
                }
                continue;
              }
        
              const locationProjection = map.getProjection().fromLatLngToPoint(locationMarker.getPosition());
              const radius = 10 / scale;
        
              const isMarkerOvelappingAnotherMarker = GoogleMapsHelper.isMarkerOvelappingAnotherMarker(visibleMarkers, locationProjection, radius, map);
        
              if (!isMarkerOvelappingAnotherMarker) {
                if (!locationMarkerMap) {
                  locationMarker.setMap(map);
                }
        
                visibleMarkers.push(locationMarker);
              } else {
                if (locationMarkerMap) {
                  locationMarker.setMap(null);
                }
              }
            }
          }*/
/*
        static getLocationMarker(map: google.maps.Map, vesselPosition: VesselPosition, color?) {
            const latLng = { lat: vesselPosition.latitude, lng: vesselPosition.longitude };
    
            const locationMarkerIcon = this.getVesselIcon(vesselPosition.course, color);
            const locationMarker = this.getMarker(map, latLng, locationMarkerIcon);
    
            return locationMarker;
        }*/
/*
        static getMarkerLatLng(marker: google.maps.Marker) {
            if (!marker) return;
    
            const position = marker.getPosition();
    
            return { lat: position.lat(), lng: position.lng() };
        }*/
/*
        static getIcon(path, fillColor, strokeColor, scale, rotation?) {
            const icon = {
                path: path,
                rotation,
                fillColor: fillColor,
                fillOpacity: 1,
                strokeColor: strokeColor,
                strokeWeight: 1,
                scale: scale,
                labelOrigin: new google.maps.Point(0, -25),
                anchor: null
            };
    
            if (rotation) {
                icon.rotation = rotation;
            }
    
            return icon;
        }*/
/*
        static getPolyline(map, latLngs, strokeColor?) {
            if (!latLngs) {
                return;
            }
    
            if (!strokeColor) {
                strokeColor = this.DEFAULT_COLOR;
            }
            return new google.maps.Polyline({
                map: map,
                path: latLngs,
                geodesic: true,
                strokeColor: strokeColor,
                strokeOpacity: 1.0,
                strokeWeight: 2
            });
        }*/
/*
        static getVesselIcon(rotation?, color?) {
            const path = 'M 400 200 L 500 400 L 400 350 L 300 400 L 400 200 ';
            color = color ? color : this.DEFAULT_COLOR;
    
            const icon = {
                path: path,
                rotation,
                fillColor: color,
                fillOpacity: 1,
                strokeColor: color,
                strokeWeight: 1,
                scale: 0.05,
                labelOrigin: new google.maps.Point(0, -25),
                anchor: new google.maps.Point(400, 350)
            };
    
            if (rotation) {
                icon.rotation = rotation;
            }
    
            return icon;
        }*/
/*
        static getMarker(map, position, icon, label?, title?) {
            const marker = new google.maps.Marker({
                position: position,
                map: map,
                icon: icon,
                label: label,
                title: title
            });
    
            return marker;
        }*/

/*   static setMapOnAll(map, elements) {
           if (!elements) return;
   
           if (Array.isArray(elements)) {
               elements.forEach(element => element.setMap(map));
           } else {
               elements.setMap(map);
           }
       }
   */
/*  static moveToLocation(map, coordinate, zoomLevel?) {
          if (!zoomLevel) {
              zoomLevel = 12;
          }
          const location = new google.maps.LatLng(coordinate.lat, coordinate.lng);
  
          map.panTo(location);
          map.setZoom(zoomLevel);
      }*/

/*  static getInfoWindow(infoWindow, map, content, marker, latLng?): google.maps.InfoWindow {
          if (infoWindow) {
              infoWindow.close();
          }
          infoWindow = new google.maps.InfoWindow({
              content: content
          });
          if (latLng) {
              infoWindow.setPosition(latLng);
          }
          infoWindow.open(map, marker);
          return infoWindow;
      }*/
/*
        static getVesselPositionInfoWindowContent(vesselPosition: VesselPosition): string {
            const timestampString = moment(vesselPosition.timestamp).format('DD.MM.YYYY HH:mm');
            const speed = vesselPosition.speed;
    
            return '<div><h5>' + timestampString + '<span class="utc-text"> UTC</span></h5></div>'
                + '<div><h6 class="infoWindowHeader">Speed:</h6>' + speed + ' knots</div>';
        }*/
/*
        static isMarkerOvelappingAnotherMarker(existingMarkers, markerProjection, radius, map) {
            for (let i = 0; i < existingMarkers.length; i++) {
                const existingMarkerProjection = map.getProjection().fromLatLngToPoint(existingMarkers[i].getPosition());
                const locationOverlapps = this.isPointInRadius(markerProjection.x, markerProjection.y,
                    existingMarkerProjection.x, existingMarkerProjection.y, radius);
    
                if (locationOverlapps) {
                    return true;
                }
            }
    
            return false;
        }*/
/*
        static isPointInRadius(originX, originY, pointX, pointY, radius) {
            const dist_points = (originX - pointX) * (originX - pointX) + (originY - pointY) * (originY - pointY);
            radius *= radius;
            if (dist_points < radius) {
                return true;
            }
            return false;
        }*/
