import { useCompaniesStore } from 'context/CompaniesContext';
import { useEffect } from 'react';
import { Company } from 'types/ModelTypes';
import { ApiEndpoints } from './apiEndpointUtils';
import useApi from './useApi';

export default () => {
    const { state, postApiData } = useApi();
    const { companies, setCompanies, setCompanyToEdit } = useCompaniesStore();

    const upsertCompany = async (company: Company) => {
        await postApiData({
            endpoint: company.companyId > 0 ? ApiEndpoints.updateCompany : ApiEndpoints.addCompany,
            data: company,
        });
    };

    useEffect(() => {
        if (state.status === 'success') {
            const companyResponse = state.result as Company;
            const updatedCompanies = [...companies];

            const indexOfCompany = updatedCompanies.findIndex((company) => company.companyId === companyResponse.companyId);
            if (indexOfCompany > -1) {
                updatedCompanies[indexOfCompany] = companyResponse;
            } else {
                updatedCompanies.push(companyResponse);
            }
            setCompanyToEdit({ ...companyResponse });
            setCompanies([...updatedCompanies]);
        }
    }, [state]);

    return { upsertCompany };
};
