// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RouterContent from './RouterContent';
import { AppSettingsStateContext } from 'context/AppSettingsStateContext';
import { AppSettingsGetter } from 'context/AppSettingsContext';

const AppRouter = () => {
    const { setGettingAppSettings, isGettingAppSettings } = useContext(AppSettingsStateContext);

    useEffect(() => {
        setGettingAppSettings(true);
        new AppSettingsGetter().getAppSettings().then(() => {
            setGettingAppSettings(false);
        });
    }, []);

    return isGettingAppSettings ? (
        <div>Retrieving app settings</div>
    ) : (
        <Router>
            <RouterContent></RouterContent>
        </Router>
    );
};

export default AppRouter;
