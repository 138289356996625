import { useState } from 'react';
import React from 'react';

interface iSettings {
    api: string;
}

type AppSettingsStateContextType = {
    isGettingAppSettings: boolean;
    setGettingAppSettings: (isGettingAppSettings: boolean) => void;
    appSettings: iSettings;
    setAppSettings: (appSettings: iSettings) => void;
};

type ContextProps = {
    children: React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AppSettingsStateContext = React.createContext<AppSettingsStateContextType>(undefined!);

export const AppSettingsStateProvider = ({ children }: ContextProps) => {
    const [isGettingAppSettings, setGettingAppSettings] = useState<boolean>(true);
    const [appSettings, setAppSettings] = useState<iSettings>({ api: '' });

    return (
        <AppSettingsStateContext.Provider
            value={{
                isGettingAppSettings,
                setGettingAppSettings,
                appSettings,
                setAppSettings,
            }}
        >
            {children}
        </AppSettingsStateContext.Provider>
    );
};
