import { useEmissionReportStore } from 'context/EmissionReportContext';
import { QueryParamProps } from 'odfjell-query-manager';

export const useHandleEmissionItems = () => {
    const { dispatch } = useEmissionReportStore();
    const setFilters = (paramName: string, filterValue?: string | null) => {
        if (!filterValue) return;

        dispatch({ field: `${paramName}Search`, value: filterValue });
    };

    const handleEmissionPage = (queryParamUpdate: QueryParamProps) => {
        queryParamUpdate.queryParamValues.forEach((queryParamValue) => {
            setFilters(queryParamValue.name, queryParamValue.newValue);
        });
    };
    return { handleEmissionPage };
};
