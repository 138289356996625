import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDownloadSeaCargoReport from 'api/useDownloadEmissionReport';
import useGetImosCompanies from 'api/useGetImosCompanies';
import useTriggerEmissionReport from 'api/useTriggerEmissionReport';
import { useAuthStateStore } from 'auth/AuthStateProvider';
import DateInput from 'components/date-input/DateInput';
import PaginatorComponent from 'components/paginator/Paginator';
import SearchInput from 'components/SearchInput';
import SortIconComponent from 'components/SortIcon';
import { Table } from 'components/Syles';
import { useCompaniesStore } from 'context/CompaniesContext';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ReportStatus, ReportType } from 'types/ModelTypes';
import { filterByDateFrom, filterByDateTo, filterByNumber, filterByString } from 'utils/filterHelper';
import { prettifyDate } from 'utils/renderTextHelper';
import {
    EmissionDescription,
    Wrapper,
    PortDateCol,
    TableWrapper,
    DateRangeFilterWrapper,
    FilterRow,
    FiltersWrapper,
    PaginatorWrapper,
} from './Emission.style';
import { useQueryParamsHandler } from 'odfjell-query-manager';
import useGetEmissionReport from 'api/useGetEmissionReport';
import { useEmissionReportStore } from 'context/EmissionReportContext';

type SortColumn =
    | 'charterer'
    | 'vesselName'
    | 'voyageNumber'
    | 'loadPort'
    | 'unloadPort'
    | 'cpDate'
    | 'loadingDate'
    | 'unloadingDate'
    | 'bookingNumber';

const Emission: React.FC = () => {
    const { imosCompanies, selectedCompany } = useCompaniesStore();
    const { emissionReports, filterState, dispatch } = useEmissionReportStore();
    const { getImosCompanies } = useGetImosCompanies();
    const { getEmissionReports } = useGetEmissionReport();
    const { regenerateReports, sendReportMail } = useTriggerEmissionReport();
    const { userAccount } = useAuthStateStore();
    const { downloadReport } = useDownloadSeaCargoReport();
    const [selectedReportStatuses, setSelectedReportStatuses] = useState<ReportStatus[]>([]);
    const [paginatedReports, setPaginatedReports] = useState<ReportStatus[]>([]);
    const [filteredReports, setFilteredReports] = useState<ReportStatus[]>([]);
    const [selectedReportType, setSelectedReportType] = useState<ReportType | null>(null);
    const [isSortDesc, setIsSortDesc] = useState<boolean>(false);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [filterDateFrom, setFilterDateFrom] = useState<Date | null>(null);
    const [filterDateTo, setFilterDateTo] = useState<Date | null>(null);
    const { queryParamUpdate, triggerQueryParamUpdate, findQueryParamValue } = useQueryParamsHandler();
    const [sortColumn, setSortColumn] = useState<SortColumn>('loadingDate');
    const { chartererSearch, vesselSearch, voyageSearch, loadPortSearch, unloadPortSearch, bookingNumberSearch } = filterState;
    const onFilterChange = (e: any) => dispatch({ field: e.target.name, value: e.target.type === 'checkbox' ? e.target.checked : e.target.value });
    const clearFilter = (field: string) => dispatch({ field: field, value: '' });

    useEffect(() => {
        if (!selectedCompany || !selectedReportType) return;
        getEmissionReports(selectedCompany.companyId, selectedReportType);
    }, [selectedCompany, selectedReportType]);

    useEffect(() => {
        if (isOdfjellEmployee) {
            getImosCompanies();
        }

        triggerQueryParamUpdate({ name: 'reportType' });
    }, [userAccount]);

    useEffect(() => {
        const queryParamValue = findQueryParamValue('reportType');
        if (!queryParamUpdate || !queryParamValue?.newValue || !isOdfjellEmployee) {
            setSelectedReportType(ReportType.SCC);
            return;
        }

        setSelectedReportType(+queryParamValue.newValue);
    }, [queryParamUpdate, userAccount]);

    useEffect(() => {
        if (!filterState) return;
        let _filteredReports = [...emissionReports];
        if (filterState.chartererSearch !== '') {
            _filteredReports = _filteredReports.filter((r) => {
                const charterer = getReportCharterer(r);
                return charterer && charterer.toLowerCase().indexOf(filterState.chartererSearch.toLowerCase()) !== -1;
            });
        }
        if (filterState.vesselSearch !== '') _filteredReports = filterByString(_filteredReports, 'vesselName', filterState.vesselSearch);
        if (filterState.voyageSearch !== '') _filteredReports = filterByString(_filteredReports, 'voyageNumber', filterState.voyageSearch);
        if (filterState.loadPortSearch !== '') _filteredReports = filterByString(_filteredReports, 'loadPort', filterState.loadPortSearch);
        if (filterState.unloadPortSearch !== '') _filteredReports = filterByString(_filteredReports, 'unloadPort', filterState.unloadPortSearch);
        if (isOdfjellEmployee && filterState.bookingNumberSearch !== '')
            _filteredReports = filterByNumber(_filteredReports, 'bookingNumber', filterState.bookingNumberSearch);
        if (filterState.filterDateFrom)
            _filteredReports = filterByDateFrom(_filteredReports, 'loadingDate', 'unloadingDate', filterState.filterDateFrom);
        if (filterState.filterDateTo) _filteredReports = filterByDateTo(_filteredReports, 'loadingDate', 'unloadingDate', filterState.filterDateTo);

        _filteredReports = getSortedReports(sortColumn, isSortDesc, _filteredReports);
        filterReports([..._filteredReports]);
    }, [emissionReports, filterState]);

    const getSortedReports = (column: SortColumn, sortDesc: boolean, reports: ReportStatus[]) => {
        if (column === 'charterer') {
            reports = reports.sort((a, b) => {
                const chartererA = getReportCharterer(a);
                const chartererB = getReportCharterer(b);
                return sortFunc(chartererA, chartererB, sortDesc);
            });
        } else {
            reports = reports.sort((a, b) => sortFunc(a[column], b[column], sortDesc));
        }

        return reports;
    };

    const sortFunc = (valueA: any, valueB: any, sortDesc: boolean) => {
        if (!valueA) return 1;
        if (!valueB) return -1;
        if (typeof valueA === 'string') {
            return sortDesc ? (valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1) : valueA.toLowerCase() < valueB.toLowerCase() ? 1 : -1;
        }
        if (typeof valueA === 'number') {
            return sortDesc ? (valueA > valueB ? 1 : -1) : valueA < valueB ? 1 : -1;
        }
        if (valueA instanceof Date) {
            if (!valueA || !valueB) {
                return sortDesc ? 1 : -1;
            }
            return sortDesc ? (moment(valueA).isAfter(moment(valueB)) ? 1 : -1) : moment(valueA).isBefore(moment(valueB)) ? 1 : -1;
        }
        return 1;
    };

    const sortByColumn = (column: SortColumn) => {
        const sortDesc = !isSortDesc;
        const sortedReports = getSortedReports(column, sortDesc, filteredReports);

        setSortColumn(column);
        filterReports([...sortedReports]);
        setIsSortDesc(sortDesc);
    };

    const filterReports = (reports: ReportStatus[]) => {
        reports = reports.filter((r) => r.vesselName && r.reportType === selectedReportType);
        setFilteredReports([...reports]);
    };

    const dateStr = (date?: Date) => (date ? prettifyDate(date) : '');

    const onDateChange = (date: Date, fieldName: string) => {
        if (fieldName === 'filterDateFrom') {
            setFilterDateFrom(date);
        }
        if (fieldName === 'filterDateTo') {
            setFilterDateTo(date);
        }

        dispatch({ field: fieldName, value: date });
    };

    const getReportCharterer = (report: ReportStatus) => {
        const company = imosCompanies.find((c) => c.companyId === report.chartererId);

        return company?.fullName ?? '';
    };

    const isOdfjellEmployee = userAccount?.role === 'odfjell-admin' || userAccount?.role === 'odfjell-employee';

    const onReportStatusClick = (reportStatus: ReportStatus) => {
        let _selectedReportStatuses = [...selectedReportStatuses];
        if (selectedReportStatuses.some((r) => r.reportStatusId === reportStatus.reportStatusId)) {
            _selectedReportStatuses = _selectedReportStatuses.filter((r) => r.reportStatusId !== reportStatus.reportStatusId);
        } else {
            _selectedReportStatuses.push(reportStatus);
        }
        setSelectedReportStatuses([..._selectedReportStatuses]);
    };

    const isReportStatusChecked = (reportStatus: ReportStatus) =>
        selectedReportStatuses.some((r) => r.reportStatusId === reportStatus.reportStatusId);

    const onIsAllCheckedChange = () => {
        if (isAllSelected) {
            setSelectedReportStatuses([]);
        } else {
            setSelectedReportStatuses([...paginatedReports]);
        }
        setIsAllSelected(!isAllSelected);
    };

    const onRegenerateClick = () => {
        const statusIds = getSelectedReportsString();
        regenerateReports(statusIds);
        setSelectedReportStatuses([]);
    };

    const onSendMailClick = () => {
        const statusIds = getSelectedReportsString();
        sendReportMail(statusIds);
        setSelectedReportStatuses([]);
    };

    const getSelectedReportsString = () => {
        const statusIdsStr = selectedReportStatuses.reduce((stri, r) => `${stri}${r.reportStatusId},`, '');
        return statusIdsStr.substring(0, statusIdsStr.length - 1);
    };

    const onDownloadClick = (report: ReportStatus) => {
        if (!selectedCompany?.companyId) return;
        downloadReport(selectedCompany.companyId, report);
    };

    const PortDetails = (port: string, date?: Date) => {
        if (!port) return <>{port}</>;

        return (
            <PortDateCol>
                <span>{port}</span>
                <span>{dateStr(date)}</span>
            </PortDateCol>
        );
    };

    return (
        <>
            <Wrapper>
                <div>
                    {selectedReportType === ReportType.SCC && <h4>Voyage Emission Reports</h4>}
                    {selectedReportType === ReportType.EUETS && <h4>EU ETS Reports</h4>}
                    <EmissionDescription>
                        The emission reports summarizes the fuel consumption related to the service completed by Odfjell Tankers. <br />
                        If you have any questions to the report, please contact <a href="mailto:emissions@odfjell.com">emissions@odfjell.com</a> and
                        you should expect a timely response.
                    </EmissionDescription>
                </div>
                <FiltersWrapper>
                    <FilterRow>
                        {isOdfjellEmployee && (
                            <>
                                <SearchInput
                                    name={'chartererSearch'}
                                    value={chartererSearch}
                                    placeholder={'Charterer'}
                                    onChange={onFilterChange}
                                    clear={clearFilter}
                                />
                                <SearchInput
                                    name={'bookingNumberSearch'}
                                    value={bookingNumberSearch}
                                    placeholder={'Booking'}
                                    onChange={onFilterChange}
                                    clear={clearFilter}
                                />
                            </>
                        )}
                        <SearchInput
                            name={'vesselSearch'}
                            value={vesselSearch}
                            placeholder={'Vessel'}
                            onChange={onFilterChange}
                            clear={clearFilter}
                        />
                        <SearchInput
                            name={'voyageSearch'}
                            value={voyageSearch}
                            placeholder={'Voyage'}
                            onChange={onFilterChange}
                            clear={clearFilter}
                        />
                        <SearchInput
                            name={'loadPortSearch'}
                            value={loadPortSearch}
                            placeholder={'Load Port'}
                            onChange={onFilterChange}
                            clear={clearFilter}
                        />
                        <SearchInput
                            name={'unloadPortSearch'}
                            value={unloadPortSearch}
                            placeholder={'Unload Port'}
                            onChange={onFilterChange}
                            clear={clearFilter}
                        />
                    </FilterRow>
                    <FilterRow>
                        <DateRangeFilterWrapper>
                            Load / Unload between
                            <DateInput
                                name={'filterDateFrom'}
                                isClearable={true}
                                date={filterDateFrom}
                                onChange={(date) => onDateChange(date, 'filterDateFrom')}
                            ></DateInput>
                            and
                            <DateInput
                                name={'filterDateTo'}
                                isClearable={true}
                                date={filterDateTo}
                                onChange={(date) => onDateChange(date, 'filterDateTo')}
                            ></DateInput>
                        </DateRangeFilterWrapper>
                    </FilterRow>
                </FiltersWrapper>
                <TableWrapper>
                    <Table $sortable={true}>
                        <thead title="Sort">
                            <tr>
                                {isOdfjellEmployee && (
                                    <th onClick={() => sortByColumn('charterer')} className="relative">
                                        Charterer {sortColumn === 'charterer' && <SortIconComponent sortDesc={isSortDesc} />}
                                    </th>
                                )}
                                {isOdfjellEmployee && <th className="relative">Booking</th>}
                                <th onClick={() => sortByColumn('vesselName')} className="relative">
                                    Vessel {sortColumn === 'vesselName' && <SortIconComponent sortDesc={isSortDesc} />}
                                </th>
                                <th onClick={() => sortByColumn('voyageNumber')} className="relative">
                                    Voyage {sortColumn === 'voyageNumber' && <SortIconComponent sortDesc={isSortDesc} />}
                                </th>
                                <th>
                                    <PortDateCol>
                                        <span onClick={() => sortByColumn('loadPort')} className="relative">
                                            Load {sortColumn === 'loadPort' && <SortIconComponent sortDesc={isSortDesc} />}
                                        </span>
                                        <span onClick={() => sortByColumn('loadingDate')} className="relative">
                                            Date {sortColumn === 'loadingDate' && <SortIconComponent sortDesc={isSortDesc} />}
                                        </span>
                                    </PortDateCol>
                                </th>
                                <th>
                                    <PortDateCol>
                                        <span onClick={() => sortByColumn('unloadPort')} className="relative">
                                            Unload {sortColumn === 'unloadPort' && <SortIconComponent sortDesc={isSortDesc} />}
                                        </span>
                                        <span onClick={() => sortByColumn('unloadingDate')} className="relative">
                                            Date {sortColumn === 'unloadingDate' && <SortIconComponent sortDesc={isSortDesc} />}
                                        </span>
                                    </PortDateCol>
                                </th>
                                <th onClick={() => sortByColumn('cpDate')} className="relative">
                                    CP Date {sortColumn === 'cpDate' && <SortIconComponent sortDesc={isSortDesc} />}
                                </th>
                                <th></th>
                                {isOdfjellEmployee && (
                                    <th className="checkbox-col">
                                        <input type="checkbox" checked={isAllSelected} onChange={onIsAllCheckedChange}></input>
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedReports.map((report) => (
                                <tr key={report.reportStatusId} onClick={() => onReportStatusClick(report)}>
                                    {isOdfjellEmployee && <td>{getReportCharterer(report)}</td>}
                                    {isOdfjellEmployee && <td>{report.bookingNumber}</td>}
                                    <td>{report.vesselName}</td>
                                    <td>{report.voyageNumber}</td>
                                    <td>{PortDetails(report.loadPort, report.loadingDate)}</td>
                                    <td>{PortDetails(report.unloadPort, report.unloadingDate)}</td>
                                    <td>{dateStr(report.cpDate)}</td>
                                    <td>
                                        {report.reportUrl && (
                                            <FontAwesomeIcon
                                                title="Download"
                                                icon="download"
                                                onClick={(e) => {
                                                    onDownloadClick(report);
                                                    e.stopPropagation();
                                                }}
                                            />
                                        )}
                                    </td>
                                    {isOdfjellEmployee && (
                                        <td className="checkbox-col">
                                            <input type="checkbox" checked={isReportStatusChecked(report)} readOnly={true}></input>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </TableWrapper>
                <PaginatorWrapper>
                    <PaginatorComponent list={filteredReports} setList={setPaginatedReports}></PaginatorComponent>
                    {selectedReportStatuses.length > 0 && (
                        <div>
                            <button onClick={onRegenerateClick}>Regenerate ({selectedReportStatuses.length})</button>
                            <button onClick={onSendMailClick}>Send me the reports ({selectedReportStatuses.length})</button>
                        </div>
                    )}
                </PaginatorWrapper>
            </Wrapper>
        </>
    );
};

export default Emission;
