import styled from 'styled-components';

type StyledProps = {
    dateWidth?: string;
};

export const CargoDetailsWrapper = styled.div<StyledProps>`
    border-top: 1px solid #ccc;
    font-size: 0.8rem;
    color: ${(props) => props.theme.colors.darkBlue};
    margin: 1rem 0;
    padding: 0.5rem;

    .port-call-eta {
        display: flex;
        width: ${(props) => (props.dateWidth ? props.dateWidth : '12rem')};
    }
`;

export const CargoDetailsInfo = styled.div`
    display: flex;

    .port-name {
        width: 16rem;
    }
`;

export const LoaderWrapper = styled.div`
    text-align: center;
`;
