// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { models, IEmbedConfiguration } from 'powerbi-client';
import { PbiResponse } from 'types/ModelTypes';

export default () => {
    const getEmbeddedConfiguration = (pbiReponse: PbiResponse, pageName?: string) => {
        const embedConfiguration: IEmbedConfiguration = {
            type: 'report',
            tokenType: models.TokenType.Embed,
            accessToken: pbiReponse.token,
            embedUrl: pbiReponse.embedUrl,
            id: pbiReponse.reportId,
            permissions: models.Permissions.All,
            pageName: pageName ? pageName : '',
            // Enable this setting to remove gray shoulders from embedded report
            settings: {
                background: models.BackgroundType.Transparent,
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
            },
        };

        return embedConfiguration;
    };

    return { getEmbeddedConfiguration };
};
