import React from 'react';
import styled from 'styled-components';

type Props = {
    customerName: string;
};

const Title = styled.h1`
    text-transform: capitalize;
    font-weight: 400;
    font-size: 2rem;
    margin: 0;
    color: rgb(236, 104, 37);
`;

const CustomerTitle: React.FC<Props> = (props) => {
    return <Title>{props.customerName}</Title>;
};

export default CustomerTitle;
