import useApi from './useApi';
import { useEffect } from 'react';
import { CustomerVoyage } from 'types/ModelTypes';
import { GetIsoDate } from 'utils/dateHelper';
import { useEtaStore } from 'context/EtaContext';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';
import { useCompaniesStore } from 'context/CompaniesContext';
import { useQueryParamsHandler } from 'odfjell-query-manager';

export default () => {
    const { state, getApiData } = useApi();
    const { selectedCompany } = useCompaniesStore();
    const { setCustomerVoyages, setFilteredCustomerVoyages, filterStartDate, filterEndDate } = useEtaStore();
    const { triggerQueryParamsUpdate } = useQueryParamsHandler();

    const getCustomerVoyages = () => {
        if (!selectedCompany) return;
        const from = GetIsoDate(filterStartDate);
        const to = GetIsoDate(filterEndDate);
        getApiData({
            endpoint: ApiEndpoints.getCustomerVoyages,
            queryParam: ApiQueryParams.getCustomerVoyages(from, to, selectedCompany.companyId),
        });
    };

    useEffect(() => {
        if (state.status === 'success') {
            let customerVoyages = state.result as CustomerVoyage[];
            customerVoyages.forEach((customerVoyage) => {
                customerVoyage.portCalls = customerVoyage.portCalls.sort((a, b) => {
                    const aArrival = a.operatorEta ? a.operatorEta.etaFromDateUtc : a.portArrivalDateUTC;
                    const bArrival = b.operatorEta ? b.operatorEta.etaFromDateUtc : b.portArrivalDateUTC;

                    return aArrival > bArrival ? 1 : -1;
                });
            });

            customerVoyages = customerVoyages.reduce((items, customerVoyage) => {
                // TEMP. Fix in db when duplicates
                if (
                    !items.some((e) => e.vessel.imoNumber === customerVoyage.vessel.imoNumber && e.voyage.idVoyage === customerVoyage.voyage.idVoyage)
                ) {
                    items.push(customerVoyage);
                }
                return items;
            }, [] as CustomerVoyage[]);
            setCustomerVoyages([...customerVoyages]);
            setFilteredCustomerVoyages([...customerVoyages]);
            triggerQueryParamsUpdate([{ name: 'portCallId' }, { name: 'cargoOperationId' }]);
        }
    }, [state]);

    return { state, getCustomerVoyages };
};
