import { useEffect, useState } from 'react';
import { PortCall, CargoOperation, CustomerVoyage, CargoData } from 'types/ModelTypes';
import useApi from './useApi';
import { useEtaStore } from 'context/EtaContext';
import { useAppStateStore } from 'context/AppStateContext';
import { ApiEndpoints, ApiQueryParams } from './apiEndpointUtils';
import { useCompaniesStore } from 'context/CompaniesContext';

export default () => {
    const { state, getApiData } = useApi();
    const { setCargoPositions, setPortCallCargoOperationMaps } = useEtaStore();
    const [portCall] = useState<PortCall | null>();
    const { selectedCompany } = useCompaniesStore();
    const { setEta } = useAppStateStore();

    const getCargoData = (customerVoyage: CustomerVoyage | null, portCall: PortCall | null, cargoOperation: CargoOperation) => {
        if (!customerVoyage || !portCall || !cargoOperation) return;
        getApiData({
            endpoint: ApiEndpoints.getCargoData,
            queryParam: ApiQueryParams.getCargoData(customerVoyage.vessel.imoNumber, cargoOperation.idCargo, selectedCompany?.companyId),
        });
        setEta(customerVoyage, portCall, cargoOperation);
    };

    useEffect(() => {
        if (state.status === 'success') {
            const cargoData = state.result as CargoData;
            const maps = cargoData.portCallCargoOperationMaps.sort((a, b) => {
                const aArrival = a.portCall.operatorEta ? a.portCall.operatorEta.etaFromDateUtc : a.portCall.portArrivalDateUTC;
                const bArrival = b.portCall.operatorEta ? b.portCall.operatorEta.etaFromDateUtc : b.portCall.portArrivalDateUTC;

                return aArrival > bArrival ? 1 : -1;
            });
            setCargoPositions([...cargoData.cargoPositions]);
            setPortCallCargoOperationMaps([...maps]);
        }
    }, [state, portCall]);

    return { state, getCargoData };
};
